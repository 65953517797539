import {
    ApplicationRef,
    ComponentRef,
    Injectable,
    Type,
    ViewContainerRef,
} from '@angular/core';

export interface ChildConfig {
    inputs: object;
    outputs: object;
}

@Injectable({
    providedIn: 'root',
})
/**
 * Dynamically inject a component into the DOM
 * Adapted from https://itnext.io/angular-create-your-own-modal-boxes-20bb663084a1
 */
export class DomService {

    constructor(
        private appRef: ApplicationRef,
    ) {}
    private childComponentRef: ComponentRef<any>;

    static htmlToDocumentFragment(htmlString: string): DocumentFragment {
        const tempDiv = document.createElement('DIV');
        tempDiv.innerHTML = htmlString;
        if (tempDiv.childNodes.length === 1) {
            return tempDiv.removeChild(tempDiv.firstChild) as any;
        } else {
            const fragment = document.createDocumentFragment();
            while (tempDiv.firstChild) {
                fragment.appendChild(tempDiv.firstChild);
            }

            return fragment;
        }
    }

    public appendComponentTo<T>(child: Type<T>, manageComponent: (c: T) => void) {
        // Get the root view container ref of the application by injecting it into the root component.
        const rootViewContainerRef = this.appRef.components[0].injector.get(ViewContainerRef);

        // Create and insert the component into the root view container.
        const childComponentRef = rootViewContainerRef.createComponent(child);

        // Allow input/output management
        manageComponent(childComponentRef.instance);

        // Keep the ref for removing.
        this.childComponentRef = childComponentRef;
    }

    public removeComponent() {
        if (this.childComponentRef) {
            this.appRef.detachView(this.childComponentRef.hostView);
            this.childComponentRef.destroy();
        }
    }
}
