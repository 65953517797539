<div class="snack-bar flexRow jcFS aiC" [ngClass]="data?.containerClass">
    <div *ngIf="data.icon" class="snack-bar-icon" style="margin-left: 2px;">
        <app-icon [fontSize]="'20px'" [icon]="data.icon"></app-icon>
    </div>

    <div class="snack-bar-message">{{ data.message }}</div>

    <button
        *ngIf="data.buttonProps"
        class="snack-bar-action"
        (click)="close(true)"
    >
        <app-icon class="snack-bar-icon" [faIcon]="data.buttonProps.icon.faIcon"/>
        <div class="snack-bar-button-text">{{ data.buttonProps.label | translate }}</div>
    </button>

    <button
        [ngStyle]="{ 'margin-left': !!data.buttonProps ? '10px' : 'auto' }"
        class="snack-bar-dismiss"
        (click)="close(false)"
    > 
        <app-icon style="font-size: 20px;" [faIcon]="'far fa-xmark'"/>
    </button>
</div>
