import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class NotificationOverlayService {
    constructor( ) { }

    public overlayIsVisible: boolean = false;

    toggleOverlay(): void {
        this.overlayIsVisible = !this.overlayIsVisible;
    }

    showOverlay(): void {
        this.overlayIsVisible = true;
    }

    hideOverlay(): void {
        this.overlayIsVisible = false;
    }
}
