import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { buildAvatar } from '@weavix/domain/src/avatar/avatar';
import { Avatar, AvatarPopoverTrigger } from '@weavix/models/src/avatar/avatar';

import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { AvatarPersonComponent, Indicators } from '../avatar-person/avatar-person.component';
import { CommonModule } from '@angular/common';

@AutoUnsubscribe()
@Component({
    selector: 'app-avatar-person-cluster',
    templateUrl: './avatar-person-cluster.component.html',
    styleUrls: ['./avatar-person-cluster.component.scss'],
    standalone: true,
    imports: [
        CommonModule,

        AvatarPersonComponent,
    ],
})
export class AvatarPersonClusterComponent implements OnChanges {

    @Input() peopleIds: string[];
    @Input() size: number;
    @Input() showAvailability: boolean = false;
    @Input() avatarsAcross: number = 2;
    @Input() singleRow: boolean = false;
    @Input() fullRow: boolean = false;
    @Input() backgroundColor: string;
    @Input() hasUserInfoPopover = false;

    @Output() avatarPopoverOutput: EventEmitter<AvatarPopoverTrigger> = new EventEmitter();

    avatar: Avatar;
    numPeople: number;
    numNonClusterPeople: number;
    clusterPeople: string[];
    alteredSize: number;

    constructor() { }

    async ngOnChanges(changes: SimpleChanges) {
        this.alteredSize = Object.assign({}, this.size);
        if (changes.peopleIds) {
            this.numPeople = this.peopleIds?.length ?? 0;
            let numClusterPeople = 0;
            if (this.singleRow) {
                numClusterPeople = this.numPeople > this.avatarsAcross ? this.avatarsAcross : this.numPeople;
            } else {
                numClusterPeople = this.numPeople > 4 ? 3
                                        : this.numPeople === 4 ? 4
                                            : this.numPeople;
            }
            this.numNonClusterPeople = this.numPeople - numClusterPeople;
            this.clusterPeople = this.peopleIds?.slice(0, numClusterPeople);
        }
        if (this.fullRow) {
            this.alteredSize = this.size ?? 50;
            this.avatar = buildAvatar(this.alteredSize, this.alteredSize);
        } else {
            this.alteredSize = this.size ?? 50;
            if (this.numPeople === 1) {
                this.alteredSize *= .8; // Scale down a bit for just one
            } else {
                this.alteredSize /= 2.4;
            }
            this.avatar = buildAvatar(this.alteredSize, this.alteredSize);
        }
    }

    handleAvatarPopoverOutput(trigger: AvatarPopoverTrigger) {
        if (!this.hasUserInfoPopover) return;
        this.avatarPopoverOutput.emit(trigger);
    }

    public setIndicators(showAvailability: boolean): Indicators {
        // eslint-disable-next-line no-bitwise -- AvatarPersonComponent intentionally uses bitwise operators for Indicators enum.
        return showAvailability ? (Indicators.Availability | Indicators.Teams) : Indicators.Teams;
    }
}
