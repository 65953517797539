import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { defaultAvatar } from '@weavix/domain/src/avatar/avatar';
import { LocalDeviceUtility } from '@weavix/domain/src/utils/local-device-utility';
import { Avatar } from '@weavix/models/src/avatar/avatar';
import { DropdownItem } from '@weavix/models/src/dropdown/dropdown';
import { LoadingComponent } from 'components/loading/loading.component';
import { ComponentCanDeactivate } from 'weavix-shared/guards/deactivate.guard';
import { AlertService } from 'weavix-shared/services/alert.service';
import { AvatarComponent } from '../avatar.component';
import { IconComponent } from 'components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownComponent } from 'components/dropdown/dropdown.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
    selector: 'app-avatar-editor',
    templateUrl: './avatar-editor.component.html',
    styleUrls: ['./avatar-editor.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatTooltipModule,
        TranslateModule,

        forwardRef(() => AvatarComponent),
        DropdownComponent,
        IconComponent,
        LoadingComponent,
    ],
})
export class AvatarEditorComponent implements OnInit, AfterViewInit, OnDestroy, ComponentCanDeactivate {

    constructor(
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
    ) { }

    @Output() editOutput: EventEmitter<File> = new EventEmitter<File>();
    @ViewChild('video') videoRef: ElementRef;
    get video(): HTMLVideoElement {
        return this.videoRef?.nativeElement;
    }
    @ViewChild('canvas') canvas: ElementRef;

    stream: MediaStream;
    videoWidth: number = 320;
    videoHeight: number = 320;
    avatarInput: Avatar;
    isLoading = true;
    get hasImageInput(): boolean {
        return !!this.avatarInput;
    }
    get captureDisabled(): boolean {
        return (!this.stream && !this.avatarInput);
    }

    videoDeviceItems: DropdownItem[] = [];
    selectedDeviceId: string;

    async ngOnInit() {
        this.selectedDeviceId = (await LocalDeviceUtility.getDefaultVideoDevice()).deviceId;
        this.videoDeviceItems = (await LocalDeviceUtility.getVideoDevices()).map(d => ({
            label: d.label,
            key: d.deviceId,
        }));
    }

    async ngAfterViewInit() {
        await this.initializeVideoStream();
    }

    ngOnDestroy() {
        this.stopVideoStream();
    }

    @HostListener('window:beforeunload')
    canDeactivate() {
        this.stopVideoStream();
        return true;
    }

    async handleCaptureClick() {
        if (this.hasImageInput) return this.clearImageAndReinitializeVideo();

        this.captureImage();
    }

    handleFileSelect(event: Event) {
        this.stopVideoStream();

        const target = event.target as HTMLInputElement;
        if (target.files instanceof FileList && !!target.files.length) {
            const newAvatarFile: File = target.files[0];
            this.updateAvatarPreviewFile(newAvatarFile);
        }
    }

    handleDeviceChange(device: DropdownItem) {
        LocalDeviceUtility.setDefaultVideoInput(device.key);
        this.stopVideoStream();
        this.initializeVideoStream();
    }

    private async initializeVideoStream() {
        this.isLoading = true;

        try {
            const videoDevice: MediaDeviceInfo = await LocalDeviceUtility.getDefaultVideoDevice();
            this.stream = await LocalDeviceUtility.getDeviceStream(false, videoDevice.deviceId, { video: { width: this.videoWidth, height: this.videoHeight } });
            this.video.srcObject = this.stream;
            this.video.play();
            this.cdr.markForCheck();
        } catch (e) {
            this.alertService.sendError({ error: e, messageKey: 'configuration.user.failed-camera-init' });
        }

        this.isLoading = false;
    }

    private clearImageAndReinitializeVideo() {
        this.avatarInput = null;
        this.editOutput.emit(null);
        this.initializeVideoStream();
    }

    private async captureImage() {
        const newAvatarFile: File = await this.getImageFile();
        this.stopVideoStream();
        this.editOutput.emit(newAvatarFile);
    }

    private stopVideoStream() {
        this.stream?.getVideoTracks()?.[0]?.stop();
        this.stream = null;
        this.cdr.markForCheck();
    }

    private async getImageFile(): Promise<File> {
        const canvasElement = this.canvas.nativeElement;
        canvasElement.getContext('2d').drawImage(this.video, 0, 0);
        const dataUrl: string = canvasElement.toDataURL('image/png');
        this.updateAvatarPreviewUrl(dataUrl);
        const imageResponse: Response = await fetch(dataUrl);
        const blob: Blob = await imageResponse.blob();
        return new File([blob], 'avatar.png', { type: 'image/png' });
    }

    private updateAvatarPreviewFile(file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async (evt) => {
            const image = evt.target.result as string;

            this.avatarInput = {
                ...defaultAvatar(),
                height: 300,
                width: 300,
                img: image,
            };
            this.editOutput.emit(file);
            this.cdr.markForCheck();
        };
    }

    private updateAvatarPreviewUrl(dataUrl: string) {
        this.avatarInput = {
            ...defaultAvatar(),
            height: 300,
            width: 300,
            img: dataUrl,
        };
        this.cdr.markForCheck();
    }

}
