export interface IconSrc {
    svgIcon?: string;
    faIcon?: string;
    fntlIcon?: FontelloIcon;
    matIcon?: string;
    img?: string;
}

export enum IconType {
    faIcon = 'faIcon',
    fntlIcon = 'fntlIcon',
    matIcon = 'matIcon',
    svgFile = 'svgFile',
    img = 'img',
    iconImg = 'iconImg',
}

export interface Icon {
    type: IconType;
    src: string;
    color?: string;
}

export interface AppIcon {
    svgFile?: string;
    faIcon?: string;
    fntlIcon?: FontelloIcon;
    matIcon?: string;
    flagIcon?: string;
    img?: string;
    color?: string;
    altText?: string;
    fontSize?: string;
    width?: string | number;
    height?: string | number;
    borderRadius?: string;
    inline?: boolean;
    tooltip?: string;
    transition?: string;
    clickable?: boolean;
    bgCircle?: {
        bgColor: string;
        diameter: string;
    };
}

export enum SvgIcon {
    ClearDay = 'clear-day',
    ClearNight = 'clear-night',
    Cloudy = 'cloudy',
    Fog = 'fog',
    ItemType = 'item-type',
    ItemTypeGray = 'item-type-gray',
    PartlyCloudyDay = 'partly-cloudy-day',
    PartlyCloudyNight = 'partly-cloudy-night',
    Rain = 'rain',
    ScreenShare = 'screen-share',
    ScreenShareStop = 'screen-share-stop',
    Sleet = 'sleet',
    Snow = 'snow',
    Teams = 'teams',
    VideoCall = 'video-call',
    Wind = 'wind',
    Walt = 'walt',
}

// Fontello values correspond to class names in assets/fonts/fontello/css/fontello.scss
export enum FontelloIcon {
    Crews = 'icon-crews-dudes',
    RadioDudes = 'icon-radio-dudes',
    Radio = 'icon-radio',
    Walt = 'icon-walt',
    WaltSignal = 'icon-walt-signal',
    Console = 'icon-console',
    Disconnect = 'icon-unlink',
    SiteVision = 'icon-site-vision',

    // Connections
    PersonConnectionAdd = 'icon-connections-add',

    // Weather
    ClearDay = 'icon-clear-day',
    ClearNight = 'icon-clear-night',
    Cloudy = 'icon-cloudy',
    Fog = 'icon-fog',
    PartlyCloudyDay = 'icon-partly-cloudy-day',
    PartlyCloudyNight = 'icon-partly-cloudy-night',
    Rain = 'icon-rain',
    Sleet = 'icon-sleet',
    Snow = 'icon-snow',
    Wind = 'icon-wind',

    Wrangler = 'icon-square-bolt',
    WranglerGroup = 'icon-square-bolt-group',
    Unpinned = 'icon-unpinned',
    Wisp = 'icon-wisp',
}
