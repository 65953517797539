import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { CallAgentKind } from '@azure/communication-calling';
import { BehaviorSubject, Subject, Subscription, of, race, timer } from 'rxjs';
import { myUserId } from 'models-mobx/my-profile-store/my-profile-store';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { PushPayload, PushTopic } from '@weavix/models/src/push/push';
import { VidVoxRingbackData, VidVoxStartRingbackData } from '@weavix/models/src/vidvox/vidvox-ringback-data';
import { Channel } from 'models-mobx/channels-store/channel';
import { VidVoxStartRingbackRequest, VidVoxUpdateRingbackRequest } from '@weavix/models/src/vidvox/vidvox-ringback-request';
import { CommunicationIdentifierKind } from '@azure/communication-common';
import { AnalyticsService, StAction, StObject } from '../analytics.service';
import { CallType, VidVoxCallEnded, VidVoxIncomingCall } from '../acs.service';
import { AlertService } from '../alert.service';
import { PubSubService } from '../pub-sub.service';
import { Topic } from '@weavix/models/src/topic/topic';
import { channelsContext } from 'models-mobx/channels-store/channels-store';

interface OutgoingRingbackState {
    callId: string;
    channelId: string;
    meetingLink: string;
    joinOptions: { joinWith: CallType };
}

const ACS_CALL_ESTABLISHMENT_TIMEOUT = 59_000;

/**
 * A fake call for the Teams -> ACS workaround.
 */
export interface RingbackCall {
    kind: 'workaround';
}

/**
 * While normal video/voice calls are handled by the ACS SDK,
 * we need a workaround for unsupported Teams user -> ACS user calls.
 */
@Injectable({
    providedIn: 'root',
})
export class VidVoxRingbackService {
    private readonly ringbackOutgoingSubject = new BehaviorSubject<OutgoingRingbackState | null>(null);
    private readonly ringbackUpdatedSubject = new Subject<VidVoxRingbackData>();
    private readonly ringbackNotAcceptedSubject = new Subject<VidVoxCallEnded>();
    private readonly ringbackIncomingSubject = new Subject<VidVoxIncomingCall>();

    private joinMeeting?: (meetingLink: string, channelId: string, callType: CallType) => Promise<void>;
    private subs: Subscription[] = [];

    constructor(
        private readonly httpService: HttpService,
        private readonly pubSubService: PubSubService,
        private readonly alert: AlertService,
    ) { }

    get isActive(): boolean {
        return this.subs.length > 0;
    }

    /**
     * Start listening to events related to our workaround.
     */
    startListening(options: {
        onJoinMeeting: (meetingLink: string, channelId: string, callType: CallType) => Promise<void>,
        onOutgoingStarted: (options: { channelId?: string }) => void,
        onNotAccepted: (args: VidVoxCallEnded) => void,
        onIncomingCall: (args: VidVoxIncomingCall) => void,
    }) {
        this.stopListening();

        this.joinMeeting = options.onJoinMeeting;

        this.subs.push(this.pubSubService.subscribe<PushPayload>(null, Topic.UserNotification, [myUserId()], null, 0)
            .pipe(
                filter(x => x.payload.topic === PushTopic.VidVoxRingback),
                map(x => x.payload.data as VidVoxStartRingbackData),
            ).subscribe(data => this.handleIncoming(data)));
        // TODO: subscribe to browser push notifications too? this is only MQTT ^

        this.subs.push(this.pubSubService.subscribe<VidVoxRingbackData>(null, Topic.UserRingbacks, [myUserId()], null, 0)
            .subscribe(x => this.handleIncoming(x.payload)));

        this.subs.push(this.ringbackOutgoingSubject.pipe(filter(state => !!state)).subscribe(state => options.onOutgoingStarted({ channelId: state.channelId })));
        this.subs.push(this.ringbackNotAcceptedSubject.subscribe(options.onNotAccepted));
        this.subs.push(this.ringbackIncomingSubject.subscribe(options.onIncomingCall));
    }

    /** Shut down the listeners. */
    stopListening() {
        this.subs.forEach(s => s.unsubscribe());
        this.subs = [];
    }

    /** @returns True if the ringback workaround is needed. */
    isNeeded(callAgentKind: CallAgentKind, participants: CommunicationIdentifierKind[]) {
        return callAgentKind === CallAgentKind.TeamsCallAgent
            && participants.some(p => p.kind === 'communicationUser');
    }

    /**
     * Request a ringback from the channel.
     */
    async requestChannelRingback(channel: Channel, joinOptions: { joinWith: CallType }) {
        const isVideoEnabled = joinOptions.joinWith === 'video';
        const channelId = channel.id;

        const { callId, meetingLink } = await this.sendStartRingback({
            channelId,
            isVideoEnabled,
        });

        AnalyticsService.track(
            isVideoEnabled ? StObject.VideoCall : StObject.VoiceCall,
            StAction.Requested,
            this.constructor.name,
            {
                object: {
                    channelId,
                    channelMemberCount: channel.otherUsers.length + 1,
                },
            },
        );

        this.ringbackOutgoingSubject.next({
            callId,
            channelId,
            meetingLink,
            joinOptions,
        });

        const timeoutSubscription = timer(ACS_CALL_ESTABLISHMENT_TIMEOUT).pipe(
            filter(() => this.ringbackOutgoingSubject.value?.callId === callId),
            switchMap(() => this.sendUpdateRingback(callId, { kind: 'timeout', channelId })),
            catchError((e: unknown) => {
                console.error('Failed to send ringback timeout', e);
                return of(true);
            }),
        ).subscribe(() => {
            this.handleNotAccepted('timeout', this.ringbackOutgoingSubject.value);
        });

        return {
            cancelTimeoutTimer: () => timeoutSubscription.unsubscribe(),
        };
    }

    private async sendStartRingback(request: VidVoxStartRingbackRequest) {
        return await this.httpService.post<{ callId: string, meetingLink: string }>(null, '/acs/ringbacks', request);
    }

    private handleNotAccepted(kind: 'rejected' | 'timeout', state: OutgoingRingbackState) {
        this.clearOutgoingRingback();
        this.ringbackNotAcceptedSubject.next({
            isRejected: kind === 'rejected',
            isUnansweredTimeout: kind === 'timeout',
            isCancelled: false,
            isUnavailable: false,
            isError: false,
            errorKey: null,
        });
    }

    private clearOutgoingRingback() {
        this.ringbackOutgoingSubject.next(null);
    }

    /** Cancel the ringback. */
    async cancelChannelRingback() {
        const callId = this.ringbackOutgoingSubject.value?.callId;
        const channel = channelsContext.getDefault().getChannelById(this.ringbackOutgoingSubject.value?.channelId);
        if (!callId || !channel) return;
        this.clearOutgoingRingback();
        return this.sendUpdateRingback(callId, {
            kind: 'cancel',
            channelId: channel.id,
        });
    }

    private async sendUpdateRingback(callId: string, request: VidVoxUpdateRingbackRequest) {
        return await this.httpService.put<object>(null, `/acs/ringbacks/${callId}`, request);
    }

    private handleIncoming(data: VidVoxRingbackData) {
        if (data.kind === 'start') {
            this.emitIncomingCall(data);

        } else {

            this.ringbackUpdatedSubject.next(data);

            if (this.ringbackOutgoingSubject.value?.callId === data.callId) {
                const state = this.ringbackOutgoingSubject.value;
                if (data.kind === 'reject') {
                    this.handleNotAccepted('rejected', state);
                } else if (data.kind === 'accept') {
                    // Intentionally not awaiting.
                    this.handleAccepted(state);
                }
            }
        }
    }

    private emitIncomingCall(data: VidVoxStartRingbackData) {
        const callId = data.callId;
        const channelId = data.channelId;

        this.ringbackIncomingSubject.next({
            id: callId,
            callerUserId: data.callerId,

            callEnded$: this.createIncomingCallEndedObservable(callId),

            accept: async type => {
                await this.acceptIncomingCall(callId, channelId, data.meetingLink, type);
            },
            reject: async () => {
                await this.rejectIncomingCall(callId, channelId);
            },
        });
    }

    private createIncomingCallEndedObservable(callId: string) {
        return race(
            this.ringbackUpdatedSubject.pipe(filter(data => data.callId === callId)),
            timer(ACS_CALL_ESTABLISHMENT_TIMEOUT).pipe(map(() => ({ kind: 'timeout' as const }))),
        ).pipe(map(data => ({
            isRejected: true,
            isUnansweredTimeout: data.kind === 'timeout',
            isCancelled: data.kind === 'cancel',
            isUnavailable: false,
            isError: false,
            errorKey: null,
        })));
    }

    private async acceptIncomingCall(callId: string, channelId: string, meetingLink: string, type: CallType) {
        await this.sendUpdateRingback(callId, { kind: 'accept', channelId });
        await this.joinMeeting(meetingLink, channelId, type);
    }

    private async rejectIncomingCall(callId: string, channelId: string) {
        await this.sendUpdateRingback(callId, { kind: 'reject', channelId });
    }

    private async handleAccepted(state: OutgoingRingbackState) {
        try {
            await this.joinMeeting(state.meetingLink, null, state.joinOptions.joinWith);
        } catch (e) {
            this.alert.sendError({ error: e, messageKey: 'ERRORS.CALLS.USER_UNAVAILABLE' });
        } finally {
            this.clearOutgoingRingback();
        }
    }
}
