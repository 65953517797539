
<button class="tab-wrapper" [class.boxed]="orientation === 'vertical'" [ngClass]="{'inactive': !isActive}" (click)="onClick()" [class.teams]="teamsApp">
    <div
        class="tab fg1" 
        [ngClass]="{'inactive': !isActive, 'disabled': disabled}"  
        [matTooltip]="(disabled | translate )"
        matTooltipPosition="below">
    
        <app-icon *ngIf="icon" [icon]="icon" class="icon"></app-icon>
        <span *ngIf="text" class="text">{{ text | translate}}</span>
    </div>
    <div *ngIf="orientation === 'horizontal'" class="indicator" [ngClass]="{'inactive': !isActive}"></div>
</button>
