import { PermissionAction } from '../permission/permissions.model';

export enum FolderType {
    Channels = 'channels',
    Dashboards = 'dashboards',
    ItemTypes = 'itemTypes',
    Items = 'items',
    People = 'people',
    Rules = 'rules',
    WorkForms = 'workForms',
}

export enum SystemFolderType {
    Protect = 'system-protect',
}

export interface Folder {
    id?: string;
    name?: string;
    parentId?: string;
    type?: FolderType;
    facilityId?: string;
    children?: Folder[];
    tooltip?: string;
}

export type FolderResponse = Folder;

export interface FolderAccessResponse extends Folder {
    hasEditAccess?: boolean;
    hasViewAccess?: boolean;
}
export interface FolderPermission {
    name?: string;
    people: {
        ids?: string[];
        companies?: string[];
        groups?: string[];
        crafts?: string[];
        tags?: string[];
    };
    grantedAction: PermissionAction;
}

export interface FilterFolder extends Folder {
    selected?: boolean;
    isFacility?: boolean;
    children?: FilterFolder[];
    parent?: FilterFolder;
    hovered?: boolean;
    open?: boolean;
    type?: FolderType;
    items?: FilterFolderItem[];
    editable?: boolean;
}

export interface SelectionOptions {
    ids?: boolean;
    folders?: boolean;
    facilities?: boolean;
}

export interface FilterFolderItem {
    name: string;
    id: string;
    folderId: string;
    selected: boolean;
    editable?: boolean;
    isItem: boolean;
}

export enum PeopleTargets {
    Crafts = 'crafts',
    Tags = 'tags',
    Companies = 'companies',
    People = 'ids',
}
