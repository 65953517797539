<div class="main" [class.mouseover]="!options?.showOnClick" (click)="options?.showOnClick ? toggle() : ''">
    <app-icon
        [faIcon]="options.icon ? options.icon : 'fas fa-info'"
        class="icon tooltip-icon">
    </app-icon>

    <div class="wrapper right" [ngClass]="[ options?.vPosition ?? 'bottom' ]" [class.show]="isShowing$ | async" >
        <div class="content" [style.width.px]="options.width ? options.width : 220">
            <ng-container *ngIf="options.title">
                <div class="title"><span [innerHTML]="doTranslate ? (options.title | translate) : options.title"></span></div>
                <div class="separator"></div>
            </ng-container>
            <div class="body" [innerHTML]="doTranslate ? (options.body | translate) : options.body"></div>
        </div>
    </div>

</div>
