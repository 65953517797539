import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';
import { AppIcon } from 'weavix-shared/models/icon.model';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        IconComponent,
    ],
})
export class BadgeComponent {
    @Input() icon: AppIcon;
    @Input() label: string;
    @Input() fontSize: number = 10;
}
