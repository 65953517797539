export interface GeofenceType {
    id?: string;
    name?: string;
    color: string;
    category: GeofenceTypeCategory;
}

export interface GeofenceTypeFacilities extends GeofenceType {
    facilityIds?: string[];
}

export enum GeofenceTypeCategory {
    WorkingArea = 'working-area',
    BreakArea = 'break-area',
    TravelArea = 'travel-area',
}
