import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Company } from '@weavix/models/src/company/company';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';
import { hasModifierKey } from '@angular/cdk/keycodes';

/**
 * An item to display in the company typeahead.
 */
export type CompanyTypeaheadItem = Pick<Company, 'id' | 'name' | 'logo'> & {
    /** True if the company can only be displayed, not picked by the user. */
    invalid?: boolean,
};

@Pipe({
    name: 'appSelectedCompanyItem',
    standalone: true,
})
export class SelectedCompanyItemPipe implements PipeTransform {
    transform(companyId: string | null, companies: CompanyTypeaheadItem[]): CompanyTypeaheadItem | undefined {
        return companies.find(c => c.id === companyId);
    }
}

/**
 * Search for a company by name, but only after a minimum number of characters.
 * Optionally display an option to create a new company with the searched name.
 *
 * @example
 * ```html
 * <app-company-typeahead
 *   [companies]="companies"
 *   formControlName="companyId"
 *   [showAdd]="true"
 *   (addCompany)="addCompany($event)"
 * ></app-company-typeahead>
 * ```
 */
@Component({
    selector: 'app-company-typeahead',
    templateUrl: './company-typeahead.component.html',
    styleUrls: ['./company-typeahead.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CompanyTypeaheadComponent,
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        CommonModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        TranslateModule,

        SelectedCompanyItemPipe,

        IconComponent,
    ],
})
export class CompanyTypeaheadComponent implements ControlValueAccessor, OnDestroy {
    /** The companies to search. */
    @Input() companies: CompanyTypeaheadItem[] = [];
    /** How many search characters required before showing options. */
    @Input() minimumCharacters = 3;
    /** Id of the element that triggers the typeahead. */
    @Input() triggerId = 'company-typeahead-trigger';
    /** If true, an option to create a new company with the searched name will be shown. */
    @Input() showAdd = false;
    /** Emits the name of the company that should be created. */
    @Output() addCompany = new EventEmitter<string>();

    @ViewChild('filterInput') filterInput?: ElementRef<HTMLInputElement>;
    @ViewChild('auto') auto?: MatAutocomplete;

    private readonly destroyed = new Subject<void>();
    readonly filteredCompanies$: Observable<CompanyTypeaheadItem[]>;
    readonly isDisabled$ = new BehaviorSubject(false);
    readonly isTypeaheadOpen$ = new BehaviorSubject(false);
    readonly filterInputControl = new FormControl('');
    readonly selectedCompanyId$ = new BehaviorSubject<string | null | undefined>(null);
    readonly COMPANY_ADD_KEY = 'company-add';

    private controlValueAccessorChangeFn = (_companyId: string) => {};
    private controlValueAccessorTouchedFn = () => {};

    constructor(
        private readonly cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) private readonly document: Document,
    ) {
        this.filteredCompanies$ = this.filterInputControl.valueChanges.pipe(
            startWith(''),
            debounceTime(200),
            distinctUntilChanged(),
            map(searchValue => this.filterCompanies(searchValue)),
        );
        this.isDisabled$
            .pipe(takeUntil(this.destroyed))
            .subscribe(isDisabled => {
                if (isDisabled) {
                    this.filterInputControl.disable();
                    this.hideTypeahead();
                } else {
                    this.filterInputControl.enable();
                }
            });
    }

    ngOnDestroy(): void {
        this.destroyed.next();
    }

    private filterCompanies(searchValue: string): CompanyTypeaheadItem[] {
        if (this.minimumCharacters && !searchValue || searchValue.trim().length < this.minimumCharacters) {
            return [];
        }
        const filterLower = searchValue.toLowerCase();
        return this.companies.filter(company => company.name.toLowerCase().includes(filterLower) && !company.invalid);
    }

    showTypeahead() {
        if (this.isTypeaheadOpen$.value) {
            return;
        }

        this.filterInputControl.reset();
        this.isTypeaheadOpen$.next(true);

        // Tick to wait for element to be rendered.
        setTimeout(() => {
            this.filterInput.nativeElement.focus();
            this.cdr.markForCheck();
        });
    }

    hideTypeahead() {
        if (!this.isTypeaheadOpen$.value) {
            return;
        }

        this.isTypeaheadOpen$.next(false);

        // Tick to avoid changed-after-checked error.
        setTimeout(() => {
            this.controlValueAccessorTouchedFn();
            this.cdr.markForCheck();
        });
    }

    handleOptionSelected(event: MatAutocompleteSelectedEvent) {
        if (event.option.id === this.COMPANY_ADD_KEY) {
            this.addCompany.emit(event.option.value);
        } else {
            this.selectedCompanyId$.next(event.option.id);
            this.controlValueAccessorChangeFn(this.selectedCompanyId$.value);
        }
        this.hideTypeahead();
    }

    handleKeydown(event: KeyboardEvent) {
        if (event.key === 'Escape' && !hasModifierKey(event)) {
            event.preventDefault();
            event.stopPropagation();
            this.hideTypeahead();
        }
    }

    handleFilterInputBlur() {
        if (this.auto?.isOpen) {
            return;
        }
        this.hideTypeahead();
    }

    handleAutocompleteClosed() {
        if (this.filterInput?.nativeElement === this.document.activeElement) {
            return;
        }
        this.hideTypeahead();
    }

    //#region ControlValueAccessor
    writeValue(companyId: string | null | undefined): void {
        this.selectedCompanyId$.next(companyId);
    }
    registerOnChange(fn: (companyId: string) => void): void {
        this.controlValueAccessorChangeFn = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.controlValueAccessorTouchedFn = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled$.next(isDisabled);
    }
    //#endregion ControlValueAccessor
}
