
<app-chip-list
    [formControl]="formControl"
    [floatLabel]="true"
    inputId="personSites"
    [label]="'configuration.site-selector.' + type + '.sites'"
    [placeholder]="'configuration.site-selector.' + type + '.sites-placeholder' | translate"
    [tooltip]="{
        title: 'configuration.site-selector.' + type + '.sites-tooltip-title',
        body: 'configuration.site-selector.' + type + '.sites-tooltip-body'
    }"
    [allowUndefinedValue]="true"
    [removeSelected]="true"
    [dataSource]="siteChips"
    [required]="!isSitesDisabled"
    [isReadOnly]="isSitesDisabled"
    [isDisabled]="isSitesDisabled"
    (valueChanged)="onSitesChanged($event)"
></app-chip-list>