import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { drilldownTitleString, GeofenceTimeChartDrilldownData, GeofenceTimeChartDrilldownType, OnSiteDrilldownData, OnSiteDrilldownType } from 'weavix-shared/models/people-overview.model';
import { debounce, merge } from 'lodash';
import { Subscription } from 'rxjs';
import { GeofenceType, GeofenceTypeCategory } from 'weavix-shared/models/geofence-type.model';
import { Person } from 'weavix-shared/models/person.model';
import { Geofence, MapFilterResult } from 'weavix-shared/models/weavix-map.model';
import { ChartService, OTHER_CAT_KEY } from 'weavix-shared/services/chart.service';
import { GeofenceService } from 'weavix-shared/services/geofence.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { css } from 'weavix-shared/utils/css';
import { AutoUnsubscribe, roundedPercentLabel, Utils } from 'weavix-shared/utils/utils';
import { ChartLegendItem } from '../../../chart-legend/chart-legend.component';
import { CommonGeofenceChartOptions, PeopleOverviewChart, PeopleOverviewChartTitleKey, roundedHrsLabel, SitePeopleChartData } from '../../people-overview-chart.model';
import { PeopleOverviewDataService } from '../../people-overview-data.service';

export const HrsWorkedByAreaOptions: Partial<Highcharts.Options> = {
    plotOptions: {
        pie: {
            dataLabels: {
                enabled: false,
            },
        },
    },
    tooltip: {
        formatter: function() {
            let label = ``;
            if (this.key) label += `${this.key} </br>`;
            if (this.point['actual']) label += `${roundedHrsLabel(this.point['actual'], 100)} </br>`;
            if (this.y) label += `${roundedPercentLabel(this.y * 100, 100)}`;
            return label;
        },
    },
};

export const TimeUtilizationOptions: Partial<Highcharts.Options> = {
    plotOptions: {
        pie: {
            dataLabels: {
                enabled: false,
            },
        },
    },
    tooltip: {
        formatter: function() {
            let label = ``;
            if (this.key) label += `${this.key} </br>`;
            if (this.y) label += `${roundedPercentLabel(this.y * 100, 100)} </br>`;
            if (this.point['actual']) label += `${roundedHrsLabel(this.point['actual'], 100)}`;
            return label;
        },
    },
};

@AutoUnsubscribe()
@Component({
    selector: 'app-geofence-time-utilization-chart',
    templateUrl: './geofence-time-utilization-chart.component.html',
    styleUrls: ['../geofence-charts.scss'],
})
export class GeofenceTimeUtilizationChartComponent implements OnInit, OnDestroy, OnChanges {
    @Input() selectedSeriesKey: string;
    @Input() chartType: PeopleOverviewChart;
    @Input() peopleMap: Map<string, Person>;
    @Input() selectedGeofenceFilters: {[key: string]: MapFilterResult} = {};
    @Input() peopleFilter: (p: Person, geofenceIds?: string[]) => boolean;
    @Input() styleOptions = {
        verticalLayout: false,
        backgroundColor: css.colors.ALMOST_BLACK,
        foregroundColor: css.colors.GRAY_DK,
    };
    @Input() timeRangeDisplay: string;
    @Input() selectionKey: string;
    @Input() includeClickEvents: boolean = false;
    /*--outputting instead of poService.setActiveView() directly so peopleService can stay in people overview directory--*/
    @Output() chartSelectionEmitter: EventEmitter<GeofenceTimeChartDrilldownData | OnSiteDrilldownData> = new EventEmitter();

    private allGeofenceTypesMap: Map<string, GeofenceType> = new Map();
    private typeToColor: Map<string, string> = new Map();
    private categoryToColor: Map<string, string> = new Map();
    private chartSectionToPeopleMap: Map<string, Map<string, Person>> = new Map();
    private aggregatedChartDataMap: {[key: string]: any };
    loading: boolean = false;
    isError: boolean = false;
    chartOptions: Highcharts.Options;
    private addCharClick = {
        plotOptions: {
            pie: {
                cursor: 'pointer',
                events: {
                    click: (e) => {
                        this.emitSelectedChartData(e.point?.key);
                    },
                },
            },
        },
    };
    chartData: SitePeopleChartData[] = [];
    chartLegend: ChartLegendItem[] = [];
    chartTitleKey = PeopleOverviewChartTitleKey;
    categoryColorOptions: string[];
    typeColorOptions: string[];
    dataUpdateSub: Subscription;

    private loaded = new Promise<void>(resolve => this.loadedResolver = resolve);
    private loadedResolver: () => void;

    constructor(
        private chartService: ChartService,
        private translationService: TranslationService,
        private geofenceService: GeofenceService,
        public podService: PeopleOverviewDataService,
    ) { }

    async ngOnInit() {
        this.allGeofenceTypesMap = Utils.toMap(await this.geofenceService.getTypes(this));
        this.categoryColorOptions = this.chartService.getChartColors();
        this.typeColorOptions = this.chartService.getChartColors(this.allGeofenceTypesMap.size);
        const debounced = debounce(() => {
            this.updateChartData();
        }, 1000, { trailing: true });
        this.dataUpdateSub = this.podService.dataUpdate$.subscribe(() => debounced());
        this.setChartOptions();
        this.loadedResolver();
    }

    async ngOnChanges(changes: SimpleChanges) {
        await this.loaded;
        if (changes.peopleMap || changes.chartType || changes.selectionKey) {
            this.updateChartData();
        }
    }

    ngOnDestroy() {
        this.dataUpdateSub?.unsubscribe();
    }

    private setChartOptions(): void {
        const options = merge(
            {},
            CommonGeofenceChartOptions,
            this.chartType === PeopleOverviewChart.TimeUtilizationChart ? TimeUtilizationOptions : HrsWorkedByAreaOptions,
            this.includeClickEvents && this.addCharClick,
        );
        this.chartOptions = this.chartService.getDonutChart(options);
    }

    updateChartData(): void {
        try {
            this.isError = false;
            this.loading = true;
            this.aggregatedChartDataMap = {};
            this.chartSectionToPeopleMap.clear();

            const selectedTypeIds: Map<string, string> = Object.values(this.selectedGeofenceFilters ?? {})?.reduce((obj, v) => {
                const geofenceData = v?.data as Geofence;
                obj.set(geofenceData.geofenceTypeId, geofenceData.geofenceTypeId);
                return obj; 
            }, new Map());
            const selectedCategories: Map<string, string> = Array.from(this.allGeofenceTypesMap.values())?.reduce((obj, v) => {
                if (selectedTypeIds.has(v.id)) obj.set(v.category, v.category);
                return obj;
            }, new Map());

            const aggregateGeoCatChartData = (categories: { [id: string]: number }, person: Person) => {
                (Object.keys(categories) || []).forEach((c, index) => {
                    if (selectedCategories.size && !selectedCategories.has(c)) return;

                    const value = categories[c];
                    if (this.aggregatedChartDataMap[c]) {
                        this.aggregatedChartDataMap[c].y += value;
                        this.chartSectionToPeopleMap.get(c).set(person.id, person);
                    } else {
                        if (!this.categoryToColor.has(c)) {
                            this.categoryToColor.set(c, this.categoryColorOptions[Object.keys(this.aggregatedChartDataMap).length]);
                        }
                        const name: string = this.translationService.getImmediate(`site-overview.geofence-category.${c}`) || this.translationService.getImmediate('generics.unknown');
                        this.aggregatedChartDataMap[c] = {
                            y: value,
                            color: this.categoryToColor.get(c),
                            key: c,
                            name,
                        };
                        this.chartSectionToPeopleMap.set(c, new Map(Object.entries({ [person.id]: person })));
                    }
                });
            };

            const aggregateGeoTypeChartData = (types: { [id: string]: number }, person: Person) => {
                (Object.keys(types) || []).forEach((t, index) => {
                    if (selectedTypeIds.size && !selectedTypeIds.has(t)) return;

                    const value = types[t];
                    if (this.aggregatedChartDataMap[t]) {
                        this.aggregatedChartDataMap[t].y += value;
                        this.chartSectionToPeopleMap.get(t).set(person.id, person);
                    } else {
                        if (!this.typeToColor.has(t)) {
                            this.typeToColor.set(t, this.typeColorOptions[Object.keys(this.aggregatedChartDataMap).length]);
                        }

                        const name: string = this.allGeofenceTypesMap.get(t)?.name || this.translationService.getImmediate('generics.unknown');
                        this.aggregatedChartDataMap[t] = {
                            y: value,
                            color: this.typeToColor.get(t),
                            key: t,
                            name,
                        };
                        this.chartSectionToPeopleMap.set(t, new Map(Object.entries({ [person.id]: person })));
                    }
                });
            };

            Object.keys(this.podService.dataCacheHours || {}).forEach(x => {
                const person = this.peopleMap.get(x);
                if (person && ((!this.peopleFilter || this.peopleFilter(person)))) {
                    if (this.chartType === PeopleOverviewChart.TimeUtilizationChart) {
                        aggregateGeoCatChartData(this.podService.dataCacheHours[x]?.categories, person);
                    } else {
                        aggregateGeoTypeChartData(this.podService.dataCacheHours[x]?.types, person);
                    }
                }
            });

            this.chartData = this.chartService.filterPieData(Object.values(this.aggregatedChartDataMap) ?? []);
            this.chartLegend = this.chartData.map(c => {
                return {
                    name: c.name as string,
                    value: {
                        actual: Number(c.y),
                        display: this.chartType === PeopleOverviewChart.TimeUtilizationChart ? roundedPercentLabel(c.y * 100) : roundedHrsLabel(c['actual']),
                    },
                    color: c.color as string,
                    seriesDataKey: c.key,
                };
            });
        } catch (e) {
            this.isError = true;
            console.error(e);
        } finally {
            this.loading = false;
        }
    }

    private handleOtherCatClick(): void {
        if (this.chartType === PeopleOverviewChart.TimeUtilizationChart) return;

        const chartSection = this.chartData.find(s => OTHER_CAT_KEY === s.key);
        const otherKeys = chartSection.dataKeys;
        const drilldownType: OnSiteDrilldownType = OnSiteDrilldownType.GeofenceTypesOnSite;
        const titleString = this.translationService.getImmediate(drilldownTitleString[drilldownType]);
        this.chartSelectionEmitter.emit({
            type: drilldownType,
            titleString,
            filterKeys: Object.keys(otherKeys),
        });
    }

    private emitSelectedChartData(key: string): void {
        this.selectionKey = key;

        if (key === OTHER_CAT_KEY) {
            this.handleOtherCatClick();
        } else {
            const chartSection = this.chartData.find(s => s.key === this.selectionKey);
            const drilldownType: GeofenceTimeChartDrilldownType = this.chartType === PeopleOverviewChart.TimeUtilizationChart
                ? GeofenceTimeChartDrilldownType.TimeUtilization
                : GeofenceTimeChartDrilldownType.HrsWorkedByArea;
            const titleString = this.translationService.getImmediate(drilldownTitleString[drilldownType], { name: this.aggregatedChartDataMap[key].name });
            this.chartSelectionEmitter.emit({
                type: drilldownType,
                titleString,
                name: this.allGeofenceTypesMap.get(key)?.name || GeofenceTypeCategory[key],
                typeCategoryId: key,
                typeCategory: this.allGeofenceTypesMap.get(key) || GeofenceTypeCategory[key],
                color: chartSection.color as string,
            });
        }
    }
}
