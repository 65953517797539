import { Injectable } from '@angular/core';

import { AddWifiRouter, UpdateWifiRouter, WifiRouter } from '../models/facility.model';

import { CacheContext, HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class NetworkLocationService {
    constructor(
        private httpService: HttpService,
    ) {}

    static baseUrl = '/track/network-locations/facility';
    static cacheCollection = 'network-locations';

    private static readonly cacheContext: CacheContext = { collection: NetworkLocationService?.cacheCollection, maxAge: 1800000 };

    public static clearNetworkLocationCache = () => HttpService.clearCache(NetworkLocationService.cacheContext.collection);
    public static networkLocationUrl = (id?: string) => id ? `${NetworkLocationService.baseUrl}/${id}` : NetworkLocationService.baseUrl;

    static url = (id?: string) => id ? `${NetworkLocationService.baseUrl}/${id}` : NetworkLocationService.baseUrl;

    getFacilityWifiRouters(component: any, facilityId: string, getCached: boolean = true) {
        return this.httpService.get<WifiRouter[]>(component, NetworkLocationService.networkLocationUrl(facilityId), null, getCached ? NetworkLocationService.cacheContext : null);
    }

    updateFacilityWifiRouter(component: any, facilityId: string, input: UpdateWifiRouter[]) {
        return this.httpService.post<WifiRouter>(component, NetworkLocationService.networkLocationUrl(facilityId), input, NetworkLocationService.cacheContext);
    }

    addFacilityWifiRouter(component: any, facilityId: string, input: AddWifiRouter) {
        return this.httpService.post<WifiRouter>(component, `${ NetworkLocationService.networkLocationUrl(facilityId) }/add`, input, NetworkLocationService.cacheContext);
    }
}
