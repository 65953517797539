/**
 * A simple stopwatch class to measure elapsed time.
 * Useful for performance measurements.
 * 
 * Example:
 * ```typescript
 * const sw = new StopWatch().start();
 * // do something
 * console.log(`elapsed: ${sw.lap()}`); // prints the time since start
 * // do something else
 * console.log(`elapsed: ${sw.lap()}`); // prints the time since last lap and starts a new lap
 * // do something more
 * console.log(`elapsed: ${sw.lap()}`); // prints the time since last lap and starts a new lap
 * console.log(`total: ${sw.stop()}`); // prints the total time since start
 * ```
 */
export class StopWatch {
    private startTime = 0;
    private lapTime = 0;
    private stopTime = () => 0;
    private running = false;

    public start(): StopWatch {
        this.startTime = Date.now();
        this.stopTime = () => Date.now();
        this.lapTime = this.startTime;
        this.running = true;
        return this;
    }

    /**
     * If still running, returns the total time since start.
     * If no longer running, returns the total time from start to stop.
     * @returns the total time since start
     */
    public elapsed(): number {
        return this.stopTime() - this.startTime;
    }

    /**
     * Stops the stopwatch and returns the total time since start.
     * @returns the total time since start
     */
    public stop(): number {
        const elapsed = this.stopTime() - this.startTime;
        this.startTime = 0;
        this.lapTime = 0;
        this.stopTime = () => elapsed;
        this.running = false;
        return elapsed;
    }

    /**
     * Starts a new lap and returns the time since the last lap.
     * @returns the time since the last lap
     */
    public lap(): number {
        const now = this.stopTime();
        const elapsed = now - this.lapTime;
        this.lapTime = now;
        return elapsed;
    }

    public isRunning(): boolean {
        return this.running;
    }
}
