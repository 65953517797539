<div class="container">
    <p style="text-align: center;">Location Details</p>
    <hr>
    <div class="device flexColumn gap10">
        <ng-container *ngIf="hasLocationDevice">
            <div class="w100p" style="display:flex">
                <div *ngIf="person.badge?.locationType !== 'wisp' && person.badge?.deviceType === 'walt'" class="image-icon pad">
                    <i style="font-size: 20px;" class="icon-walt"></i>
                </div>
                <div *ngIf="person.badge?.locationType !== 'wisp' && person.badge?.deviceType !== 'walt'" class="image-icon pad">
                    <app-icon matTooltip="{{person.badge?.deviceId}}" style="font-size: 20px; padding:5px" class="fas fa-mobile-iphone"></app-icon>
                </div>
                <div *ngIf="person.badge?.locationType === 'wisp'" class="image-icon pad">
                    <app-icon matTooltip="{{person.badge?.deviceId}}" style="font-size: 20px; padding:5px" class="fas fa-tag"></app-icon>
                </div>
    
                <div class="pad">
                    <p *ngIf="person.badge?.locationDate" matTooltip="{{person.badge?.locationDate | date:'short'}}"
                        [ngStyle]="{'color': (isLocationDelayed()) ? '#ffa81d' : 'white'}">
                        <strong>{{'walt.last-updated' | translate}}:</strong> {{ person.badge?.locationDate | timeAgo:'normal':relativeDate }}
                    </p>
                    <p matTooltip="{{movingDate | date:'short'}}">
                        <strong>{{'walt.last-moving' | translate}}:</strong> {{ movingDate | timeAgo:'normal':relativeDate }}
                    </p>
                    <p *ngIf="person?.badge?.lastInVehicle" matTooltip="{{inVehicleDate | date:'short'}}">
                        <strong>{{'walt.last-in-vehicle' | translate}}:</strong> {{ inVehicleDate | timeAgo:'normal':relativeDate }}
                    </p>
                    <p *ngIf="person.badge?.locationType !== 'wisp' && person.badge?.deviceType === 'walt'">{{person.badge?.deviceId}}</p>
                    <p *ngIf="person.badge?.locationType === 'wisp' || person.badge?.deviceType !== 'walt'">{{getDisplayDeviceType()}}</p>
                </div>
            </div>
        </ng-container>
        <div *ngIf="person.badge?.locationDetails && person.badge?.locationType !== 'wisp'" class="w100p cPointer"
            (click)="showContributors(person.badge?.location, person.badge?.locationDetails?.contributors, person.badge?.locationDetails?.accuracy)">
            <p><strong>{{'walt.calculated-by' | translate}}</strong>
                {{getDisplayLocationType(person.badge?.locationDetails?.method)}}</p>
            <div *ngFor="let beacon of person.badge?.locationDetails?.contributors">
                <div style="display: flex; padding-left: 10px;" class="location-contributor">
                    <i class="{{getContributorIcon(beacon.type)}}"
                        style="font-size: 10px; padding-top: 2px; padding-right: 5px;" aria-hidden="true"></i>
                    <p *ngIf="beacon.type !== 'survey'" matTooltip="{{beacon.id}}">{{beacon.name ?? beacon.id}}</p>
                    <p *ngIf="beacon.type === 'survey'">{{ 'walt.surveyed' | translate }}</p>
                </div>
            </div>
        </div>
        <div *ngIf="this.person.badge?.locationDetails && person.badge?.locationType !== 'wisp'" class="w100p">
            <p><strong>{{'walt.accuracy' | translate}}</strong> {{getLocationAccuracy()}}</p>
        </div>
        <div *ngIf="this.person.badge?.locationDetails?.speed >= 0.5" class="w100p">
            <p><strong>{{'walt.speed' | translate}}</strong> {{getSpeed()}} {{getHeading()}}</p>
        </div>
        <div *ngIf="getLocationPermissions()" class="w100p">
            <p>{{getLocationPermissions()}}</p>
        </div>
        <div *ngFor="let other of (person.badge?.locationDetails?.others ?? [])" class="w100p cPointer"
            (click)="showContributors(other.location, other.contributors, other.accuracy)">
            <p><strong>{{'walt.unused-alternative' | translate}}</strong>
                {{getDisplayLocationType(other.type)}}</p>
            <div *ngFor="let beacon of other.contributors">
                <div style="display: flex; padding-left: 10px;" class="location-contributor">
                    <i class="{{getContributorIcon(beacon.type)}}"
                        style="font-size: 10px; padding-top: 2px; padding-right: 5px;" aria-hidden="true"></i>
                    <p *ngIf="beacon.type !== 'survey'" matTooltip="{{beacon.id}}">{{beacon.name ?? beacon.id}}</p>
                    <p *ngIf="beacon.type === 'survey'">{{ 'walt.surveyed' | translate }}</p>
                </div>
            </div>
            <div class="w100p">
                <p><strong>{{'walt.accuracy' | translate}}</strong> {{getLocationAccuracy(other.accuracy)}}</p>
            </div>
            <div *ngIf="other.speed >= 0.5" class="w100p">
                <p><strong>{{'walt.speed' | translate}}</strong> {{getSpeed(other.speed)}} {{getHeading(other.heading)}}</p>
            </div>
        </div>
    </div>
</div>