import { Subject } from 'rxjs';

export class LazySubject extends Subject<void> {
    private timeout;
    private nextFire: number;

    // Fires only once at most after wait ms
    fire(wait: number) {
        const time = new Date().getTime();
        const nextFire = time + wait;
        if (!this.nextFire || nextFire < this.nextFire) {
            clearTimeout(this.timeout);
            this.nextFire = nextFire;
            this.timeout = setTimeout(() => this.onTimeout(), wait);
        }
    }

    onTimeout() {
        this.timeout = null;
        this.nextFire = null;
        this.next();
    }

    clear() {
        clearTimeout(this.timeout);
        this.timeout = null;
        this.nextFire = null;
    }
}
