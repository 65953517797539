<div *ngIf="show" class="callout-box" [ngClass]="[options.position ? options.noArrow ? options.position + '-no-arrow' : options.position : '']">
    <div *ngIf="hasCloseBtn" class="callout-box-close cPointer" (click)="close()"><i class="fas fa-times"></i></div>
    <div class="callout-box-title taC ttu fw300">{{ calloutTranslate ? (options.title | translate) : options.title }}</div>
    <div class="callout-box-text taC mT10" [innerHTML]="calloutTranslate ? (options.text | translate) : options.text"></div>
    <div *ngIf="options.actions" class="callout-box-actions mT10">
        <button *ngFor="let action of options.actions"
            class="btn standard-width config-form-button"
            [ngClass]="[action.class ? action.class : '']"
            (click)="callAction(action)"
        >{{ actionTranslate(action) ? (action.label | translate) : action.label }}!</button>
    </div>
</div>
