<div class='cluster-container'>
    <div class='cluster-avatar-container'
        [style]="{
            'height': size + 'px',
            'width': size + 'px',
            'borderRadius': (size / 2) + 'px'
        }"
    >
        <div class='cluster-avatar-container'>
            <div class='left-avatar-container'>
                <app-avatar-person
                    [personId]="peopleIds[0]"
                    [indicators] = "Indicators.None"
                    [showCompanyRing]="false"
                    [size]="size"
                    [circular]="false"
                ></app-avatar-person>
            </div>
        </div>
        <div class='cluster-avatar-container-right'>
            <div [style]="!peopleIds[2] ? {'position': 'relative', 'right': '50%'} : {}">
                <app-avatar-person
                    [personId]="peopleIds[1]"
                    [indicators] = "Indicators.None"
                    [showCompanyRing]="false"
                    [size]="!peopleIds[2] ? size : size / 2"
                    [circular]="false"
                    [style]="{'display': 'flex'}"
                ></app-avatar-person>
            </div>
                <app-avatar-person
                    *ngIf="peopleIds[2]"
                    [personId]="peopleIds[2]"
                    [indicators] = "Indicators.None"
                    [showCompanyRing]="false"
                    [size]="size / 2"
                    [circular]="false"
                ></app-avatar-person>
        </div>
    </div>
</div>
<ng-content></ng-content>