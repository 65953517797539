import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { SnackBar } from 'weavix-shared/services/alert.service';

@Component({
    selector: 'app-snack-bar',
    styleUrls: ['./snack-bar.component.scss'],
    templateUrl: './snack-bar.component.html',
})
export class SnackBarComponent {
    public constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackBar,
        private _snackRef: MatSnackBarRef<SnackBarComponent>,
    ) { }

    public close(action: boolean) {
        this.data.response?.next(action);
        this.data.response?.complete();
        this._snackRef.dismiss();
    }
}
