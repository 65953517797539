import { Injectable } from '@angular/core';
import _ from 'lodash';
import { map } from 'rxjs/operators';

import { AggregateType, Condition, DataSourceCondition, DataSourceQuery, DataSourceTable, DataSourceValue } from '../models/data-source.model';

import { merge, Observable } from 'rxjs';
import { aggregate, evaluate, getRow, groupValue } from '../models/dashboard.model';
import { Utils } from '../utils/utils';
import { DataSourceResults } from './data-source.service';

interface DataSourceIndex {
    [alias: string]: Array<{
        values: {[value: string]: any[]; };
        source: DataSourceValue;
        reference: DataSourceValue;
    }>;
}


function evaluateWhere(variables: any, columns: any, row: any, conditions: DataSourceCondition[], type: 'AND' | 'OR'): boolean {
    // eslint-disable-next-line complexity
    return (type === 'AND' ? Array.prototype.every : Array.prototype.some).call(conditions, condition => {
        switch (condition.condition) {
            case 'OR':
            case 'AND':
                return evaluateWhere(variables, columns, row, condition.conditions, condition.condition);
        }
        let val = evaluateSelect(variables, row, condition.values[0]);
        let test: any = evaluateSelect(variables, row, condition.values[1]);
        if (typeof val === 'number') test = _.toNumber(test) || 0;
        else if (val instanceof Date) test = Utils.fromUtcDate(test, 0) || new Date();
        else if (typeof val === 'string') test = test != null ? String(test) : '';
        if (typeof val === 'string') val = val.toLowerCase();
        if (typeof test === 'string') test = test.toLowerCase();
        let valid;
        switch (condition.condition) {
            case '<': valid = val < test; break;
            case '>': valid = val > test; break;
            case '<=': valid = val <= test; break;
            case '>=': valid = val >= test; break;
            case '=': valid = val === test || !val && !test; break;
            case '<>': valid = val !== test && (val || test); break;
            default: throw new Error(condition.condition);
        }
        return valid;
    });
}
function evaluateSelect(variables: any, row: any, value: DataSourceValue, context?) {
    let index = 0;
    const groupFns = context ?
        Object.values(AggregateType).reduce((obj, v) => {
            obj[v.toUpperCase()] = (val) => {
                context[index] = context[index] || [];
                context[index].push(val);
                const agg = aggregate(context[index], v);
                index++;
                return agg;
            };
            return obj;
        }, {}) : {};

    if (value.fn && !value.eval) value.eval = evaluate(value.fn, Object.keys(row).concat(Object.keys(groupFns)));

    let x;
    if (value.column) {
        const di = value.column.indexOf('.');
        const obj = row[value.column.substring(0, di)];
        x = obj && obj[value.column.substring(di + 1)];
    } else if (value.variable) {
        x = variables[value.variable.toUpperCase()] || null;
    } else x = value.value;
    return value.eval ? value.eval(x, ...Object.values(row).concat(Object.values(groupFns))) : x;
}

function addIndex(variables: any, indexes: DataSourceIndex, alias: string, row: any) {
    (indexes[alias] || []).forEach(index => {
        const v = evaluateSelect(variables, { [alias]: row }, index.source);
        if (v) index.values[v] ? index.values[v].push(row) : index.values[v] = [row];
    });
}

function filterIndex(variables: any, current: any, indexes: DataSourceIndex, alias: string, rows: any[]) {
    const index = indexes[alias] || [];
    for (let i = 0; i < index.length; i++) {
        const ind = index[i];
        const ev = evaluateSelect(variables, current, ind.reference);
        if (ev) {
            return ind.values[ev] || [];
        }
    }
    return rows;
}

@Injectable({
    providedIn: 'root',
})
export class DataSourceBuilderService {

    constructor(
    ) {}

    evalauteResults(variables: any, query: DataSourceQuery, columns, fromColumns, obs: Observable<DataSourceResults>[]) {
        const fromTypes = fromColumns.reduce((obj, v) => (obj[v.name] = v.type, obj), {});
        const results = {};
        query.from.forEach((x) => results[x.alias] = { rows: [], meta: { columns: [] } });
        const aliases = query.from.map(x => x.alias);
        const baseIndex = this.buildIndexes(query.where);
        return merge(...obs).pipe(map(result => {
            results[result.alias] = result;

            const processed = {};
            const index: any = _.cloneDeep(baseIndex);
            aliases.forEach(a => {
                processed[a] = results[a].rows.map(row => {
                    const r = getRow(row, fromTypes);
                    addIndex(variables, index, a, r);
                    return r;
                });
            });

            let rows = [];
            aliases.forEach((a, i) => {
                if (i === 0) {
                    (processed[a] || []).forEach(v => rows.push({ [a]: v }));
                } else {
                    const current = rows;
                    rows = [];
                    current.forEach(r => {
                        const list = filterIndex(variables, r, index, a, processed[a] || []);
                        list.forEach(v => rows.push({ ...r, [a]: v }));
                    });
                }
            });

            const filtered = rows.filter(row => {
                return evaluateWhere(variables, fromTypes, row, query.where || [], 'AND');
            });

            const groups: {[key: string]: [any, any[]]} = {};
            const allRows = filtered.map((row, i) => {
                const group = [];
                const raw = {};
                let hasAggregate = false;
                (query.select || []).forEach(s => {
                    if (s.group) {
                        const val = evaluateSelect(variables, row, s);
                        const grouping = groupValue(val, s.group, null);
                        group.push(grouping.label);
                        raw[s.alias] = grouping.label;
                    }
                    if (s.aggregate) hasAggregate = true;
                    else {
                        const context = {};
                        evaluateSelect(variables, row, s, context);
                        if (Object.keys(context).length) hasAggregate = true;
                    }
                });

                const key = group.length === 0 ? hasAggregate ? 0 : i : group.join('|');
                const first = !groups[key];
                if (first) groups[key] = [raw, [row]];
                else groups[key][1].push(row);

                const allRow = _.clone(raw);

                (query.select || []).forEach(s => {
                    if (s.aggregate) {
                        const values = [evaluateSelect(variables, row, s)];
                        allRow[s.alias] = aggregate(values, s.aggregate);
                    } else if (!s.group) {
                        const context = {};
                        allRow[s.alias] = evaluateSelect(variables, row, s, context);
                    }
                });

                return allRow;
            });
            const allGroups = Object.values(groups).map(groupRows => {
                const mainRow = groupRows[0];
                (query.select || []).forEach(s => {
                    if (s.aggregate) {
                        const values = groupRows[1].map(row => evaluateSelect(variables, row, s));
                        mainRow[s.alias] = aggregate(values, s.aggregate);
                    } else if (!s.group) {
                        const context = {};
                        groupRows[1].forEach(row => {
                            mainRow[s.alias] = evaluateSelect(variables, row, s, context);
                        });
                    }
                });
                return mainRow;
            });

            const sorts: DataSourceValue[] = [];
            (query.select || []).forEach(s => {
                if (s.sort != null) {
                    sorts.push(s);
                }
            });

            const sortFn = (a, b) => {
                for (let i = 0; i < sorts.length; i++) {
                    const s = sorts[i];
                    const av = a[s.alias];
                    const bv = b[s.alias];
                    // eslint-disable-next-line eqeqeq
                    if (av != bv) {
                        if (av < bv) return s.sort ? 1 : -1;
                        else return s.sort ? -1 : 1;
                    }
                }
                return 0;
            };
            const sorted = allGroups.sort(sortFn);

            return { rows: query.limit ? sorted.slice(0, query.limit) : sorted, meta: { columns } as any, updated: result.updated, allRows };
        }));
    }

    private buildIndexes(conditions: DataSourceCondition[], index: DataSourceIndex = {}) {
        conditions.forEach(condition => {
            if (condition.condition === Condition.AND) {
                this.buildIndexes(condition.conditions, index);
            } else if (condition.condition === Condition['=']) {
                for (let i = 0; i < 2; i++) {
                    const v1 = condition.values[i];
                    const v2 = condition.values[1 - i];
                    if (v1.column && !v1.fn && !v2.fn) {
                        const a1 = v1.column.substring(0, v1.column.indexOf('.'));
                        index[a1] = index[a1] || [];
                        index[a1].push({
                            values: {},
                            source: v1,
                            reference: v2,
                        });
                    }
                }
            }
        });
        return index;
    }

    getValueText(value: DataSourceValue) {
        if (!value) return '';

        const parts = [];
        const x = String(value.column ? `${value.column}` : value.variable ? `{{${value.variable}}}` : value.value);
        if (value.aggregate) parts.push(value.aggregate.toUpperCase());
        if (value.group) parts.push(`GROUP ${value.group.toUpperCase()}`);
        parts.push(value.fn ? value.fn.replace('x', x) : x);
        if (value.alias) parts.push(`AS ${value.alias}`);
        if (value.sort) parts.push('DESC');
        return parts.join(' ');
    }

    getConditionText(condition: DataSourceCondition) {
        if (!condition) return '';
        if (['AND', 'OR'].includes(condition.condition))
            return [this.getConditionText(_.get(condition, 'conditions[0]')), condition.condition, this.getConditionText(_.get(condition, 'conditions[1]'))].join(' ');
        return [this.getValueText(_.get(condition, 'values[0]')), condition.condition, this.getValueText(_.get(condition, 'values[1]'))].join(' ');
    }

    getFromText(from: DataSourceTable) {
        let label;
        if (from.collection) {
            label = from.collection;
        } else if (from.query) {
            label = `(SELECT ${from.query.select.map(x => this.getValueText(x)).join(', ')} FROM ${from.query.from.map(f => this.getFromText(f)).join(' JOIN ')}`
                + (from.query.where.length ? ` WHERE ${from.query.where.map(x => this.getConditionText(x)).join(' AND ')}` : '') + ')';
        } else if (from.union) {
            label = from.union.map(x => this.getFromText(x)).join(' UNION ');
        } else if (from.sql) {
            label = '[SQL]';
        }
        return `${label}${from.alias ? ` AS ${from.alias}` : ''}`;
    }
}
