import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Avatar, buildAvatar } from 'components/avatar/avatar.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserProfile } from 'weavix-shared/models/user.model';
import { AccountService } from 'weavix-shared/services/account.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarPersonComponent } from 'components/avatar/avatar-person/avatar-person.component';
import { SettingsMenuComponent } from 'shared/settings-menu/settings-menu.component';
import { NotificationsComponent } from 'components/notifications/notifications.component';
import { ClickOutsideDirective } from 'weavix-shared/directives/click-outside.directive';
import { IconComponent } from 'components/icon/icon.component';
import { ReportComponent } from 'components/report/report.component';

@AutoUnsubscribe()
@Component({
    selector: 'app-right-header',
    templateUrl: './right-header.component.html',
    styleUrls: ['./right-header.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatTooltipModule,
        TranslateModule,

        ClickOutsideDirective,

        AvatarPersonComponent,
        IconComponent,
        NotificationsComponent,
        ReportComponent,
        SettingsMenuComponent,
    ],
})
export class RightHeaderComponent implements OnInit, OnDestroy {

    constructor(
        private profileService: ProfileService,
        public accountService: AccountService,
    ) { }

    private destroyed$ = new Subject<boolean>();
    userProfile: UserProfile;
    avatarInput: Avatar;
    settingsNavOpen: boolean = false;
    canaryAvailable = false;

    async ngOnInit() {
        this.getData();
        this.openAvatarSubscription();
        this.canaryAvailable = await this.accountService.checkCanary();
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    goToCanary() {
        document.location.href = document.location.href.replace('.weavix', '-canary.weavix');
    }

    async getData(): Promise<void> {
        this.userProfile = await this.profileService.getUserProfile(this);
        const userAvatar = this.userProfile.avatarFile ? this.userProfile.avatarFile : null;
        this.avatarInput = buildAvatar(46, 46, false, userAvatar);
        this.avatarInput.name = `${this.userProfile?.firstName ?? ''} ${this.userProfile?.lastName ?? ''}`;
        this.avatarInput.borderWidth = 2;
        this.avatarInput.indicatorSize = 13;
    }

    toggleSettingsMenu(e: Event, open: boolean): void {
        e.stopPropagation();
        this.settingsNavOpen = open;
    }

    openAvatarSubscription(): void {
        this.profileService.profileUpdateSubject
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                if (res) {
                    this.userProfile = res;
                    this.avatarInput = buildAvatar(46, 46, false, res.avatarFile);
                }
            });
    }
}
