import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, TrackByFunction, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger, MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { TooltipOptions } from 'weavix-shared/models/tooltip.model';
import { sleep } from 'weavix-shared/utils/sleep';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { ChipListService } from './chip-list.service';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { TooltipComponent } from 'components/tooltip/tooltip.component';
import { CommonModule } from '@angular/common';
import { ChipComponent } from './chip/chip.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChipDisplayPipe } from './chip-display.pipe';
import { ChipIsSelectedPipe } from './chip-is-selected.pipe';
import { PreventBlurDirective } from 'weavix-shared/directives/prevent-blur.directive';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

export interface Chip {
    id?: string;
    name?: string;
    hidden?: boolean;
    isDisabled?: (chip: Chip) => boolean;
    /** True if the chip can only be removed, not added. */
    invalid?: boolean;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-chip-list',
    templateUrl: './chip-list.component.html',
    styleUrls: ['./chip-list.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipListComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatFormFieldModule,
        TranslateModule,
        MatTooltipModule,

        ChipComponent,
        TooltipComponent,
        PreventBlurDirective,

        ChipDisplayPipe,
        ChipIsSelectedPipe,
    ],
})
export class ChipListComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
    @Input() inputId = '';
    @Input() placeholder = '';
    @Input() doTranslate = true;
    @Input() translateItems = false;
    @Input() label = '';
    @Input() showLabel = true;
    @Input() floatLabel = true;
    @Input() showErrors = true;
    @Input() dataSource: Chip[] = [];
    @Input() hiddenChoices: string[] = [];
    @Input() emptyOptionText = '';
    @Input() isReadOnly = false;
    @Input() displayKey: 'id' | 'name' = 'name';
    @Input() disabledText = '';
    @Input() required = false;
    @Input() tooltip: TooltipOptions;
    @Input() allowUndefinedValue: boolean = false;
    @Input() removeSelected = true;
    /** True if an add option should be visible. */
    @Input() canAdd = false;
    /** The number of other options allowed in the dropdown before the add option becomes visible. Defaults to 0 (only show if no other options). */
    @Input() canAddLimit = 0;
    /** Optional label for the add option. */
    @Input() addNewItemLabel?: string;
    /**
     * Callback that creates a new item and returns it as a chip.
     *
     * Note: This is not the typical Angular way of triggering events and should not be imitated.
     * Prefer `addNewItemClicked` instead and add any new items to `dataSource` as needed.
     */
    @Input() addNewItemFn: (value: string) => Promise<Chip>;
    /**
     * If true, the dropdown will close after a new item is added.
     */
    @Input() closeAfterAdd = false;
    @Input() dark = false;
    @Input() container: HTMLElement;
    @Input() isDisabled = false;
    /**
     * Classes to apply to the autocomplete overlay container.
     */
    @Input() autocompleteClassList: string | string[];

    @Output() valueChanged = new EventEmitter<string[]>();
    @Output() inputValueChange = new EventEmitter<string>();
    /**
     * Emits when the input element receives a `focus` event.
     */
    @Output() inputFocus = new EventEmitter<FocusEvent>();
    /**
     * Emits when add new item is clicked.
     */
    @Output() addNewItemClicked = new EventEmitter<string>();

    @ViewChild('input') textInput?: ElementRef<HTMLInputElement>;
    @ViewChild(MatAutocompleteTrigger) chipAuto?: MatAutocompleteTrigger;

    private selectedIds: string[] = [];
    readonly filteredItems$: Observable<Chip[]>;
    readonly selectedItems$ = new BehaviorSubject<Chip[]>([]);
    private validItems: Chip[] = [];

    formControl?: FormControl;
    readonly inputCtrl = new FormControl();

    readonly hasMoreOptions$ = new BehaviorSubject(false);

    private currentFilterSearchValue?: string;

    trackChipFn: TrackByFunction<Chip> = (_, item) => item.id;

    constructor(
        private chipListService: ChipListService,
        private injector: Injector,
        private cdr: ChangeDetectorRef,
    ) {
        Utils.safeSubscribe(this, this.chipListService.newItem$)
            .subscribe(() => this.validItems = Utils.sortAlphabetical(this.dataSource.slice(), this.displayKey));

        this.filteredItems$ = this.inputCtrl.valueChanges.pipe(
            startWith<string | Chip>(''),
            map(value => typeof value === 'string' ? value : (value ? value[this.displayKey] : '')),
            map(item => item ? this.filterItems(item) : this.validItems.filter(i => !this.hiddenChoices.some(c => c === i.id)).slice(0, Utils.SELECT_LIMIT)),
        );
    }

    ngOnInit() {
        this.formControl = this.injector.get(NgControl, null)?.control as FormControl;
        this.formControl?.statusChanges.pipe(debounceTime(100)).subscribe(() => {
            this.cdr.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!(Object.keys(changes).length === 1 && 'hiddenChoices' in changes)) {
            this.setValidItems();
        }
        if (changes.isDisabled) {
            if (this.isDisabled) this.inputCtrl.disable();
            else this.inputCtrl.enable();
        }
        this.setupChipControl();
    }

    ngAfterViewInit(): void {
        this.updateOptionsWhenScrolled();
    }

    private updateOptionsWhenScrolled(): void {
        window.addEventListener('scroll', () => {
            this.chipAuto?.updatePosition();
            if (this.container && this.textInput) {
                const containerBounds = this.container.getBoundingClientRect();
                const textInputBounds = this.textInput.nativeElement.getBoundingClientRect();
                const textInputIsNotVisible = (): boolean => (textInputBounds.top + textInputBounds.height) - containerBounds.top > containerBounds.height ||
                textInputBounds.bottom - (textInputBounds.height / 2) < containerBounds.top;
                if (textInputIsNotVisible()) {
                    this.chipAuto?.closePanel();
                    this.textInput.nativeElement.blur();
                }
            }
        }, true);
    }

    setValidItems() {
        this.validItems = Utils.sortAlphabetical(this.dataSource.slice(), this.displayKey);
        this.selectedItems$.next([]);

        const newSelectedItems: Chip[] = [];
        this.selectedIds.forEach(id => {
            const itemIndex = this.validItems.findIndex(i => i.id === id);
            if (itemIndex > -1) {
                newSelectedItems.push(cloneDeep(this.validItems[itemIndex]));
                if (this.removeSelected) this.validItems.splice(itemIndex, 1);
            }
        });
        this.selectedItems$.next(newSelectedItems);
    }

    private setupChipControl(): void {
        this.inputCtrl.setValue('');

        if (this.currentFilterSearchValue) this.filterItems(this.currentFilterSearchValue);

        this.hasMoreOptions$.next(this.validItems.length > Utils.SELECT_LIMIT);
    }

    private filterItems(search: string): Chip[] {
        this.inputValueChange.emit(search);
        this.currentFilterSearchValue = search ? search.toLowerCase() : '';
        return this.validItems.filter(t => t[this.displayKey]?.toLowerCase().includes(this.currentFilterSearchValue) && !this.hiddenChoices.some(c => c === t.id)).slice(0, Utils.SELECT_LIMIT);
    }

    displayFn(item?: Chip): string | undefined {
        return item ? item[this.displayKey] : undefined;
    }

    async remove(item: Chip) {
        this.selectedItems$.next(this.selectedItems$.value.filter(t => t.id !== item.id));

        if (this.removeSelected) this.validItems.push(cloneDeep(this.dataSource.find(i => i.id === item.id)));
        Utils.sortAlphabetical(this.validItems, this.displayKey);
        this.setupChipControl();
        this.onChange(this.selectedItems$.value.map(t => t.id));
        this.formControl?.markAsTouched();
        this.valueChanged.next(this.selectedItems$.value.map(t => t.id));

        // reposition chip list
        if (this.chipAuto?.panelOpen) {
            this.chipAuto.closePanel();
            this.chipAuto.openPanel();
            this.textInput?.nativeElement.focus();
        }
    }

    async addItem(event: MatAutocompleteSelectedEvent) {
        const id = event.option.value.id;
        await this.handleAddItem(id);
    }

    addNewItem(value: string) {
        this.addNewItemClicked.emit(value);
        if (this.addNewItemFn) {
            this.addNewItemFn.call(this, value).then(async (newItem: Chip | null | undefined) => {
                if (newItem) {
                    this.dataSource.push(newItem);
                    this.chipListService.onNewItem(newItem);
                }
                await this.handleAddItem(newItem?.id);
            });
        } else if (this.closeAfterAdd) {
            this.chipAuto?.closePanel();
        }
    }

    private async handleAddItem(id: string | undefined) {
        if (this.allowUndefinedValue || id) {
            const index = this.validItems.findIndex(item => item.id === id);
            this.selectedItems$.next(this.selectedItems$.value.concat(this.validItems[index]));
            if (this.removeSelected) this.validItems.splice(index, 1);
            this.setupChipControl();
            this.onChange(this.selectedItems$.value.map(t => t.id));
            this.valueChanged.next(this.selectedItems$.value.map(t => t.id));
        }
        this.inputCtrl.setValue('');
        await sleep(1);
        this.chipAuto?.openPanel();
        if (this.textInput) {
            this.textInput.nativeElement.value = '';
            this.textInput.nativeElement.focus();
        }
    }

    handleAutocompleteClosed() {
        this.inputCtrl.setValue('');
        if (this.textInput) this.textInput.nativeElement.value = '';
    }

    // Form control interface fns
    onChange = (_: string[]) => {};

    onTouched = () => {};

    async writeValue(ids: string[] = []) {
        if (!ids) return;
        this.selectedIds = ids;
        this.setValidItems();
        this.setupChipControl();
    }

    registerOnChange(fn: (ids: string[]) => void) { this.onChange = fn; }

    registerOnTouched(fn: () => void) { this.onTouched = fn; }
    // END Form control

}