import { Observable, Subject } from 'rxjs';
import { ConfinedSpaceEvent } from '@weavix/models/src/item/confined-space';
import { ItemLocationUpdate, ItemType } from '../models/item.model';
import { MapItem } from '../models/weavix-map.model';
import { PermissionAction } from '../permissions/permissions.model';
import { AlertMaydayService } from './alert-mayday.service';
import { ProfileService } from './profile.service';
import { StateServiceBase, StateUpdate } from './state-base.service';
import { FacilityAlertItem } from '@weavix/models/src/alert/facility-alerts';

export class AlertStateService extends StateServiceBase {
    alerts: Observable<FacilityAlertItem[]>;
    itemTypes: Map<string, ItemType> = new Map();

    items$: Subject<StateUpdate<MapItem>> = new Subject();
    itemTypes$: Subject<StateUpdate<ItemType>> = new Subject();
    itemLocationUpdates$: Subject<{ itemId: string, locationUpdate: ItemLocationUpdate }> = new Subject();

    confinedSpaceEvents$: Subject<ConfinedSpaceEvent> = new Subject();

    constructor(
        private alertMaydayService: AlertMaydayService,
        private profileService: ProfileService,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewSites, facilityId)) return;
        this.alerts = this.alertMaydayService.alerts;
    }

    async stop() {
        super.stop();
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string) {
        return [
            // this.profileService.hasAnyPermission(PermissionTarget.Items, Permission.Read, facilityId)
            //     && (await this.alertMaydayService.subscribeUpdates(c)).subscribe(async (result) => {
            //         if (result.payload.items) {
            //             const itemMap = result.payload.items;
            //             const filtered = {};
            //             Object.keys(itemMap).forEach(id => {
            //                 if (!itemMap[id] || this.profileService.hasPermission(PermissionTarget.Items, Permission.Read, facilityId, itemMap[id].folderId)) {
            //                     filtered[id] = itemMap[id];
            //                 }
            //             });
            //             this.updateFromMap(this.alerts, filtered, this.alerts$);
            //         } else if (result.payload.itemTypes) {
            //             const itemTypeMap = result.payload.itemTypes;
            //             this.updateFromMap(this.itemTypes, itemTypeMap, this.itemTypes$);
            //         }
            //     }),
            // this.profileService.hasPermission(RunPermissionTarget.HoleWatch, RunPermission.View, facilityId)
            //     && (await this.confinedSpaceService.subscribeConfinedSpace(c)).subscribe(async (result) => {
            //         if (result.payload.wilmaEvent && (this.profileService.hasPermission(
            // RunPermissionTarget.HoleWatch, RunPermission.View, facilityId) ||
            //  this.profileService.hasPermission(RunPermissionTarget.Wilma, RunPermission.View, facilityId))) {
            //             this.confinedSpaceEvents$.next(result.payload);
            //         }
            //     }),
            // this.profileService.hasPermission(PermissionTarget.Items, Permission.Read, facilityId) &&
            //     (await this.alertMaydayService.subscribeFacilityLocationUpdates(c, facilityId)).subscribe(async (result) => {
            //         if (!this.alerts?.has(result.replacements[1])) return;
            //         this.itemLocationUpdates$.next({ itemId: result.replacements[1], locationUpdate: result.payload });
            //     })
        ];
    }
}
