<ng-template [ngIf]="isOpen">
    <!-- Absolute Left Icon -->
    <app-icon
        *ngIf="icon"
        [icon]="icon"
        class="icon"
    ></app-icon>

    <!-- Projected Content -->
    <ng-content></ng-content>

    <!-- Absolute Right Dismiss -->
    <button
        *ngIf="dismissible"
        type="button"
        class="dismiss-button"
        (click)="close()"
    >
        <app-icon faIcon="fas fa-xmark"></app-icon>
    </button>
</ng-template>
