import { Injectable } from '@angular/core';

import { AlertService } from '../services/alert.service';
import { HttpService } from '../services/http.service';

import { AnyBadgeEvent, EventType, EventUpdateResponse } from '@weavix/models/src/badges/event';
import { Person } from '../models/person.model';
import { TranslationService } from './translation.service';
import { GeofenceService } from './geofence.service';
import { BadgeUpdate } from '@weavix/models/src/badges/badge-update';

export interface RegistrationResponse {
    qr: string;
    token: string;
    pin: string;
}

export interface BadgeCountUpdate {
    messages: number,
    alerts: number,
}

export type EventNameLookUpOverrides = {[type in EventType]? : <T extends AnyBadgeEvent>(row: T) => string};

@Injectable({
    providedIn: 'root',
})
export class BadgeService {
    constructor(
        private httpService: HttpService,
        private alertsService: AlertService,
        private translationService: TranslationService,
        private geofenceService: GeofenceService,
    ) { }

    static updatePersonBadgeDate<T extends Person>(person: T, badgeEvent: BadgeUpdate): void {
        person.badge = {
            ...person.badge,
            ...badgeEvent,
        };
    }

    async getPeopleEvents(component: any, facilityId: string, from?: Date, to?: Date) {
        return this.httpService.get<EventUpdateResponse[]>(component, '/track/events/people', { facilityId, from, to }, null, null);
    }

    async getPersonBadges(component:any, personId:string, facilityId:string, from: Date, to:Date){
        return this.httpService.get<EventUpdateResponse[]>(component, `/track/events/${personId}/badges`, { facilityId, from, to }, null, null);
    }

    async getAllItemEvents(component: any, facilityId: string, from?: Date, to?: Date) {
        return this.httpService.get<EventUpdateResponse[]>(component, '/track/events/items', { facilityId, from, to }, null, null);
    }

    async getItemEvents(component: any, itemId: string, facilityId: string, from?: Date, to?: Date) {
        return this.httpService.get<AnyBadgeEvent[]>(component, `/track/events/item/${itemId}`, { facilityId, from, to }, null, null);
    }

    async getEvents(component: any, personId: string, from?: Date, to?: Date): Promise<AnyBadgeEvent[]> {
        try {
            return await this.httpService.get(component, `/track/events/${personId}`, { from, to }, null, null);
        } catch (e) {
            this.alertsService.sendError({ error: e, messageKey: 'ERRORS.BADGE.GET-EVENTS' });
        }
    }

    async getEventsByGeofence(component: any, geofenceID: string, from?: Date, to?: Date): Promise<AnyBadgeEvent[]> {
        try {
            return await this.httpService.get(component, `/track/events/geofence/${geofenceID}`, { from, to }, null, null);
        } catch (e) {
            this.alertsService.sendError({ error: e, messageKey: 'ERRORS.BADGE.GET-EVENTS' });
        }
    }

    async updateMyBadge(t: any, badges: BadgeCountUpdate) {
        try {
            await this.httpService.put(t, '/core/users/profile/set-badge', { badges });
        } catch (e) {
            console.error('failed to update badge', e);
        }
    }

    eventNameLookUp(lookUpOverrides?: EventNameLookUpOverrides) {
        return <T extends AnyBadgeEvent>(row: T) => {
            const lookupOverride = lookUpOverrides?.[row.type];
            if (lookupOverride) {
                return lookupOverride(row);
            } else {
                if ([EventType.EntryEnter, EventType.EntryExit, EventType.EntryAttendantIn, EventType.EntryAttendantOut].includes(row.type)) {
                    return row.itemName;
                } else if (row.type === EventType.FormSubmission) {
                    return row.formName;
                } else if (row.type === EventType.AirReadingSubmission) {
                    return this.translationService.getImmediate('items.entry.airReading');
                } else if (row.type === EventType.BeaconEnter || row.type === EventType.BeaconExit || row.type === EventType.BeaconDisconnect) {
                    return `L${row['beaconLevel']}: ${row['beaconName']}`;
                } else if (row.type === EventType.GeofenceEnter) {
                    return this.geofenceService.getGeofenceEventString(EventType.GeofenceEnter, row['geofenceName']);
                } else if (row.type === EventType.GeofenceExit) {
                    return this.geofenceService.getGeofenceEventString(EventType.GeofenceExit, row['geofenceName']);
                } else if (row.type === EventType.GeofenceDisconnect) {
                    return this.geofenceService.getGeofenceEventString(EventType.GeofenceDisconnect, row['geofenceName']);
                } else if (row.type === EventType.MobileLogout) {
                    return this.translationService.getImmediate('sign-out');
                } else if (row.type === EventType.Moving || row.type === EventType.Stationary) {
                    return this.translationService.getImmediate(`geofence.${row.type}`);
                } else {
                    return this.translationService.getImmediate('generics.unknown');
                }
            }
        };
    }
}
