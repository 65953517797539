import { Subject } from 'rxjs';
import { Utils } from '../utils/utils';

import { BadgeUpdate } from '@weavix/models/src/badges/badge-update';
import { MapPerson } from '../models/weavix-map.model';

import { PermissionAction } from '../permissions/permissions.model';
import { PersonService } from './person.service';
import { ProfileService } from './profile.service';
import { StateServiceBase, StateUpdate } from './state-base.service';

export class PersonStateService extends StateServiceBase {
    people: Map<string, MapPerson> = new Map();
    people$: Subject<StateUpdate<MapPerson>> = new Subject();

    badgeUpdate$: Subject<{ personId: string, badgeUpdate: BadgeUpdate }> = new Subject();

    constructor(
        private personService: PersonService,
        private profileService: ProfileService,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewWorkerLiveLook, facilityId)) return;

        const all = await this.personService.getPeople(c, tags);
        this.people = Utils.toMap(all.filter(p => this.profileService.hasPersonPermission(PermissionAction.ViewWorkerLiveLook, accountId, p, undefined, facilityId)));
    }

    async stop() {
        super.stop();
        this.people.clear();
    }

    getPeopleIds() {
        return Array.from(this.people?.values()).map(x => x.id);
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId: string, tags?: string[]) {
        const subs = this.profileService.hasPermission(PermissionAction.ViewWorkerLiveLook, facilityId)
            ? [
                (await this.personService.subscribePersonUpdates(c)).subscribe(async (result) => {
                    const personId = result.replacements[1];
                    if (!this.people?.has(personId)) return;

                    const person = result.payload;
                    this.updateFromMap(this.people, { [personId]: person }, this.people$);
                }),
                (await (facilityId ? this.personService.subscribeFacilityBadgeUpdates(c, facilityId) : this.personService.subscribeBadgeUpdates(c))).subscribe(async (result) => {
                    if (!this.people?.has(result.replacements[1])) return;
                    this.badgeUpdate$.next({ personId: result.replacements[1], badgeUpdate: result.payload });
                }),
            ]
            : [];
        // TBD: this is pretty expensive so should figure out a way to dynamically update otherwise
        // if (facilityId) {
        //     subs.push((await this.facilityService.subscribeFacilityUpdates(c, accountId, facilityId)).subscribe(async () => {
        //         // Could have added / removed people from facility
        //         this.loadData(c, accountId, facilityId, tags);
        //     }));
        // }
        return subs;
    }
}
