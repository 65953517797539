import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';
import { environment } from 'environments/environment';
import { AppIcon } from 'weavix-shared/models/icon.model';

@Component({
    selector: 'app-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss', './tab-teams.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatTooltipModule,
        TranslateModule,

        IconComponent,
    ],
})
export class TabComponent {
    @Input() text: string;
    @Input() icon: AppIcon;
    @Input() isActive: boolean;
    @Input() disabled: string;
    @Input() orientation?: 'vertical' | 'horizontal' = 'horizontal';

    @Output() clickTab = new EventEmitter<void>();

    teamsApp = environment.teamsApp;

    public onClick(): void {
        if (this.disabled) return;
        this.clickTab.emit();
    }
}
