import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { css } from '@weavix/domain/src/utils/css';
import { Avatar, AvatarPopoverTrigger } from '@weavix/models/src/avatar/avatar';
import { Modal, ModalActionType } from 'components/modal/modal.model';
import { ModalComponent } from 'components/modal/modal.component';
import { TeamsService } from 'weavix-shared/services/teams.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { AvatarEditorComponent } from './avatar-editor/avatar-editor.component';

@AutoUnsubscribe()
@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        ModalComponent,
        forwardRef(() => AvatarEditorComponent),
    ],
})
export class AvatarComponent implements OnInit, OnChanges {
    imageSrcError: boolean = false;
    @Input()avatarInput: Avatar;
    public avatarProperties: Avatar;
    public borderWidth: number;
    @Input() readonly: boolean = false;
    @Input() backgroundColor: string;
    @Input() hasUserInfoPopover = false;
    @Output() avatarEditOutput: EventEmitter<File> = new EventEmitter();
    @Output() avatarPopoverOutput: EventEmitter<AvatarPopoverTrigger> = new EventEmitter();
    newAvatarFile: File;

    editModal: Modal = {
        isOpen: false,
        width: 600,
        header: {
            textKey: 'avatar.user-photo',
            showSeparator: true,
        },
        actions: {
            [ModalActionType.cancel]:  { show: true, textKey: 'generics.cancel', buttonClass: 'cancel' },
            [ModalActionType.submit]:  { show: true, textKey: 'generics.submit', buttonClass: 'blue-lt', disabled: () => !this.newAvatarFile },
        },
        fullScreen: false,
        content: true,
    };

    constructor(
        public teamsService: TeamsService,
    ) { }

    ngOnInit(): void {
        this.avatarProperties = this.avatarInput;
        if (this.avatarInput?.hasBorders ? this.avatarInput?.hasBorders : true) {
            if (this.avatarInput?.borderWidth) {
                this.borderWidth = this.avatarInput?.borderWidth;
            } else if (this.avatarInput?.height < 15) {
                this.borderWidth = 1;
            } else if (this.avatarInput?.height < 30) {
                this.borderWidth = 2;
            } else if (this.avatarInput?.height < 60) {
                this.borderWidth = 3;
            } else {
                this.borderWidth = 4;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.avatarInput) {
            this.ngOnInit();
        }
    }

    getBackgroundColor() {
        return this.avatarProperties?.placeholder?.backgroundColor ?? css.colors.GRAY;
    }

    getIconColor() {
        return this.avatarProperties?.placeholder?.iconColor ?? css.colors.WHITE;
    }

    getFontSize() {
        return this.getSize() * .4;
    }

    getSize() {
        return this.avatarProperties?.width ?? this.avatarProperties?.height ?? 30;
    }

    getCircular() {
        return this.avatarProperties?.circular ?? true;
    }

    getBorderRadius() {
        return this.getCircular() ? '50%' : undefined;
    }

    getOutlineColor() {
        return this.avatarProperties?.outlineColor ?? css.colors.WHITE;
    }

    showEditModal() {
        this.editModal.isOpen = true;
    }

    handleAvatarEditOutput(newAvatarFile: File) {
        this.imageSrcError = false;
        this.newAvatarFile = newAvatarFile;
    }

    handleEditModalClose(action: ModalActionType) {
        if (action === ModalActionType.submit) {
            this.avatarEditOutput.emit(this.newAvatarFile);
        }
        this.closeEditModal();
    }

    closeEditModal() {
        this.newAvatarFile = null;
        this.editModal.isOpen = false;
    }

    getInitials() {
        if (!this.avatarInput?.name) return '';
        const names = (this.avatarInput.name).split(' ');
        let initials = names[0].charAt(0);
        if (names.length > 1) {
            initials += names[1].charAt(0);
        } else if (names[0].length > 1) {
            initials += names[0].charAt(1);
        }
        return initials.toUpperCase();
    }

    handleAvatarMouseEnter(container: any) {
        if (!this.hasUserInfoPopover) return;
        const rect = container?.getBoundingClientRect?.();
        this.avatarPopoverOutput.emit({ containerBounds: rect });
    }

    handleAvatarMouseLeave() {
        if (!this.hasUserInfoPopover) return;
        this.avatarPopoverOutput.emit(null);
    }
}
