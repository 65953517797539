<ng-container [ngSwitch]="orientation">
    <div *ngSwitchCase="'horizontal'" class="flex-row" [style.height]="heightPx ? heightPx + 'px' : 'auto'" [class.teams]="teamsApp">
        <span *ngIf="title" class="title">{{ title | translate }}</span>
        <div *ngIf="teamsApp && tabs?.length > 1 || !teamsApp" class="tabs">
            <app-tab
                *ngFor="let tab of tabs" 
                [icon]="tab.icon"
                [text]="tab.text"
                [isActive]="tab.id === activeTab"
                [disabled]="tab.disabled && tab.disabled()"
                (clickTab)="onTabClick(tab.id)">
            </app-tab>
        </div>
        <app-tab *ngIf="!title" class="empty-tab"></app-tab>
    </div>
    <div class="hide-empty">
        <ng-content select="[rightHeader]"></ng-content>
    </div>
    <div *ngSwitchCase="'vertical'" class="flex-column" [style.height]="heightPx ? heightPx + 'px' : 'auto'" [style.minWidth]="minWidthPx ? minWidthPx + 'px' : 'auto'" [class.teams]="teamsApp">
        <div *ngIf="teamsApp && tabs?.length > 1 || !teamsApp" class="tabs">
            <app-tab
                *ngFor="let tab of tabs" 
                [icon]="tab.icon"
                [text]="tab.text"
                [isActive]="tab.id === activeTab"
                [disabled]="tab.disabled && tab.disabled()"
                orientation="vertical"
                (clickTab)="onTabClick(tab.id)">
            </app-tab>
        </div>
    </div>
</ng-container>
