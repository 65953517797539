import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { TooltipOptions } from 'weavix-shared/models/tooltip.model';
import { ToolTipService } from './tooltip.service';
import { IconComponent } from 'components/icon/icon.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        IconComponent,
    ],
})
export class TooltipComponent {
    @Input() options: TooltipOptions;
    @Input() doTranslate = true;

    lightTheme: boolean;

    public readonly isShowing$ = new BehaviorSubject(false);

    constructor(
        private readonly elRef: ElementRef,
        private readonly toolTipService: ToolTipService,
    ) {
        this.lightTheme = ThemeService.getLightTheme();
    }

    show() {
        this.isShowing$.next(true);
    }

    hide() {
        this.isShowing$.next(false);
    }

    toggle() {
        this.isShowing$.next(!this.isShowing$.value);
    }

    @HostListener('document:click', ['$event'])
    clickout(event: Event): void {
        if (this.options?.showOnClick) {
            if (!this.elRef.nativeElement.contains(event.target)) {
                this.hide();
            }
        }
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.toolTipService.setIsShowing(true);
    }
  
    @HostListener('mouseleave')
    onMouseLeave() {
        this.toolTipService.setIsShowing(false);
    }
}
