import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

export interface BatchRequest {
    path: string;
    method: string;
    query?: {[key: string]: string};
    post?: any;
}

export interface BatchResponse {
    status: number;
    data: any;
}

export enum BatchMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

@Injectable({
    providedIn: 'root',
})
export class BatchService {

    private url = '/batch';

    constructor(
        private httpService: HttpService,
    ) { }

    invoke(component: any, requests: BatchRequest[]) {
        return this.httpService.post<BatchResponse[]>(component, this.url, requests);
    }

    async bulkMove(component: any, ids: string[], folders: BatchRequest[], parentId: string, path: (id: string) => string, cache: string) {
        const requests: BatchRequest[] = [];
        ids.forEach(id => {
            requests.push({
                path: path(id),
                method: BatchMethod.PUT,
                post: { folderId: parentId },
            });
        });

        requests.push(...folders);

        const results: {ids: string[], folders: string[], errors: any[]} = { ids: [], folders: [], errors: [] };
        const folderResponse: BatchResponse[] = await this.invoke(component, requests);
        HttpService.clearCache(cache);
        folderResponse.forEach(res => {
            if (res.status >= 200 && res.status <= 299) {
                if (Object.prototype.hasOwnProperty.call(res.data, 'parentId')) {
                    results.folders.push(res.data.id);
                } else {
                    results.ids.push(res.data.id);
                }
            } else {
                results.errors.push(res);
            }
        });

        return results;
    }

    async bulkDelete(component: any, ids: string[], folders: BatchRequest[], path: (id: string) => string, cache: string) {
        const requests: BatchRequest[] = [];
        ids.forEach(id => {
            requests.push({
                path: path(id),
                method: BatchMethod.DELETE,
            });
        });

        requests.push(...folders);

        const results: {ids: string[], folders: string[], errors: any[]} = { ids: [], folders: [], errors: [] };
        const responses: BatchResponse[] = await this.invoke(component, requests);
        HttpService.clearCache(cache);

        responses.forEach((res, index) => {

            if (res.status >= 200 && res.status <= 299) {

                // deletes of collections don't return anything so need add the list of ids
                // that were sent in back to the component
                if (index < ids.length) {
                    results.ids.push(ids[index]);
                } else if (res.data) {
                    res.data.ids.forEach(id => {
                        results.ids.push(id);
                    });
                    res.data.folders.forEach(folder => {
                        results.folders.push(folder);
                    });
                }
            } else {
                results.errors.push(res);
            }
        });


        return results;
    }
}
