import { bootstrap } from '../bootstrap';
bootstrap();

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from 'environments/environment';
import { AppModule } from './app/app.module';

import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/sql-hint.js';
import 'codemirror/mode/sql/sql.js';

if (environment.production) {
  enableProdMode();
}

import { AnalyticsService } from 'weavix-shared/services/analytics.service';
import { initializeNewRelic } from 'weavix-shared/utils/initialize-new-relic';

initializeNewRelic();

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/custom-service-worker.js');
    }
}).catch(err => console.error(err));

AnalyticsService.start();
