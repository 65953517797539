import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DayTimePickerComponent } from 'components/day-time-picker/day-time-picker.component';

@Component({
    selector: 'app-day-time-range-picker',
    templateUrl: './day-time-range-picker.component.html',
    styleUrls: ['./day-time-range-picker.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,

        DayTimePickerComponent,
    ],
})
export class DayTimeRangePickerComponent {
    get from() { return this.group.controls.from.value; }
    get to() { return this.group.controls.to.value; }

    @Input() group: FormGroup; // { to: FormContol, from: FormControl }
    @Input() emitOnValueChange: boolean = false;

    handleFromTimeChange(value: number): void {
        this.group.controls.from.setValue(value, { emitEvent: this.emitOnValueChange });
    }

    handleToTimeChange(value: number): void {
        this.group.controls.to.setValue(value, { emitEvent: this.emitOnValueChange });
    }
}
