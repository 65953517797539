import { Injectable } from '@angular/core';

import * as Highcharts from 'highcharts';
import * as HighchartsMore from 'highcharts/highcharts-more';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

(HighchartsMore as any)(Highcharts);
(HighchartsSolidGauge as any)(Highcharts);
import _, { merge } from 'lodash';

import { aggregate, AxisChartWidgetSettings, DashboardWidgetType, evaluate, formatWidget, getRow, groupWidget, PieChartWidgetSettings, ScatterPlotWidgetSettings } from '../models/dashboard.model';
import { AggregateType, ColumnTypeMap, GroupType } from '../models/data-source.model';
import { css } from '../utils/css';
import { Utils } from '../utils/utils';
import { TranslationService } from './translation.service';

// function hsl(h, s, l) {
//     const hp = h / 60.0;
//     const c = (1 - Math.abs(2 * l - 1)) * s;
//     const x = c * (1 - Math.abs((hp % 2) - 1));
//     const r = (0 <= hp && hp < 1 || 5 <= hp && hp < 6) ? c : (1 <= hp && hp < 2 || 4 <= hp && hp < 5) ? x : 0;
//     const g = (1 <= hp && hp < 2 || 2 <= hp && hp < 3) ? c : (0 <= hp && hp < 1 || 3 <= hp && hp < 4) ? x : 0;
//     const b = (3 <= hp && hp < 4 || 4 <= hp && hp < 5) ? c : (2 <= hp && hp < 3 || 5 <= hp && hp < 6) ? x : 0;
//     const m = l - 0.5 * c;
//     return `rgb(${Math.floor((r + m) * 255.0)},${Math.floor((g + m) * 255.0)},${Math.floor((b + m) * 255.0)})`;
// }

export const OTHER_CAT_KEY: string = 'other';

const colors = [
    '#4197CB',
    '#FFFFFF',
    '#97F7F6',
    '#B113EB',
    '#ED8A8A',
    '#8CEDA4',
    '#BE1E2D',
    '#AA71FF',
    '#37A862',
    '#A4C61A',
    '#FDAE33',
    '#E8384F',
];

const fontSize = '14px';
const DEFAULT_DONUT_CHART: Highcharts.Options = {
    chart: { type: 'pie', backgroundColor: 'rgba(0,0,0,0)' },
    title: { text: null, style: { fontSize } },
    plotOptions: {
        pie: {
            slicedOffset: 0,
            innerSize: 105,
            size: 140,
            dataLabels: {
                enabled: false,
            },
            showInLegend: true,
        },
        series: {
            states: {
                select: {
                    enabled: true,
                    animation: false,
                    borderColor: css.colors.WHITE,
                    borderWidth: 2,
                },
            },
        },
    },
    tooltip: {
        formatter: function () {
            let label = ``;
            if (this.key) label += `${this.key} </br>`;
            if (this.y) label += `${(this.y * 100).toFixed(2)}%`;
            return label;
        },
    },
    credits: { enabled: false },
    legend: {
        enabled: true,
        verticalAlign: 'middle',
        align: 'right',
        itemStyle: { color: css.colors.WHITE, fontWeight: 'normal' },
        squareSymbol: true,
        symbolRadius: 0,
        layout: 'vertical',
    },
    series: [{
        colorByPoint: true,
        type: null,
        data: [],
    }],
};

const DEFAULT_LINE_CHART: Highcharts.Options = {
    chart: { type: 'line', backgroundColor: 'rgba(0,0,0,0)' },
    credits: { enabled: false },
    title: { text: null, style: { color: css.colors.WHITE, fontSize } },
    legend: { enabled: false },
    tooltip: {},
    xAxis: [{
        title: {
            text: null,
        },
    }] as Highcharts.XAxisOptions,
    yAxis: [{
        title: {
            text: null,
        },
        allowDecimals: false,
        min: 0,
    }],
    plotOptions: {
        series: {
            marker: {
                enabled: false,
            },
        },
    },
    series: [{
        type: null,
        data: [],
        cursor: 'pointer',
        events: {},
        turboThreshold: 100000,
    }],
};

const DEFAULT_GAUGE_CHART: Highcharts.Options = {
    chart: { type: 'solidgauge', backgroundColor: 'rgba(0,0,0,0)' },
    credits: { enabled: false },
    title: { text: null, style: { color: css.colors.WHITE, fontSize } },
    legend: { enabled: false },
    pane: {
        center: ['50%', '85%'],
        size: '150%',
        startAngle: -90,
        endAngle: 90,
        background: {
            color: '#7b868c',
            backgroundColor: '#7b868c',
            innerRadius: '80%',
            outerRadius: '100%',
            shape: 'arc',
        } as any,
    },
    exporting: { enabled: false },

    tooltip: { enabled: false },

    // the value axis
    yAxis: {
        stops: [
            [0, '#DF5353'],
            [0.5, '#55BF3B'],
        ],
        min: 0,
        showFirstLabel: false,
        showLastLabel: false,
        max: 1,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 0.25,
        title: {
            y: -70,
        },
        labels: {
            format: '',
        },
    },

    plotOptions: {
        solidgauge: {
            dataLabels: {
                format: '',
                borderWidth: 0,
            },
        },
    },
};

const DEFAULT_SCATTER_PLOT: Highcharts.Options = {
    chart: { type: 'scatter', backgroundColor: 'rgba(0,0,0,0)', animation: false, spacing: [20, 5, 5, 5] },
    credits: { enabled: false },
    title: { text: null, style: { color: css.colors.WHITE, fontSize } },
    legend: { enabled: false },
    tooltip: { enabled: false },
    xAxis: {
        title: {
            text: null,
        },
        offset: 10,
        startOnTick: true,
        endOnTick: true,
    } as Highcharts.XAxisOptions,
    yAxis: {
        offset: 10,
        title: { text: null },
    },
    plotOptions: {
        series: {
            animation: false,
            dataLabels: {
                enabled: true,
                format: '',
            },
            stickyTracking: false,
            turboThreshold: 5000,
        },
        scatter: {
            cluster: {
                enabled: true,
                minimumClusterSize: 3,
                animation: false,
                allowOverlap: true,
                drillToCluster: false,
                dataLabels: {
                    enabled: false,
                },
                marker: {
                    symbol: 'circle',
                },
                zones: [
                    {
                        from: 0,
                        to: 5,
                        marker: { radius: 10 },
                    },
                    {
                        from: 5,
                        to: 10,
                        marker: { radius: 16 },
                    },
                ],
            },
        },
    },
};


@Injectable({
    providedIn: 'root',
})
export class ChartService {
    constructor(private translationService: TranslationService) { }

    getChartColors(count: number = colors.length) {
        return colors.slice(0, count);
    }

    getDonutChart(options?: any | Partial<Highcharts.Options>) {
        return merge({}, DEFAULT_DONUT_CHART, options);
    }

    getGaugeChart(options: any | Partial<Highcharts.Options>) {
        return merge({}, DEFAULT_GAUGE_CHART, options);
    }

    getLineChart(options: any | Partial<Highcharts.Options>) {
        return merge({}, DEFAULT_LINE_CHART, options);
    }

    getScatterPlotChart(options: any | Partial<Highcharts.Options>) {
        return merge({}, DEFAULT_SCATTER_PLOT, options);
    }

    filterPieData(data: any[]) {
        const sum = data.reduce((o, v) => o + v.y, 0);
        const percentAndActual = data.map(x => ({ ...x, y: x.y / sum, actual: x.y, showInLegend: true }));
        const filled = percentAndActual.filter(x => x.y >= 0.005);
        const small = filled.filter(x => x.y < 0.055);
        if (small.length > 1) {
            return filled.filter(x => x.y >= 0.055)
                .concat([{
                    ...small[0],
                    y: small.reduce((o, v) => o + v.y, 0),
                    actual: small[0].actual ? small.reduce((o, v) => o + v.actual, 0) : null,
                    key: OTHER_CAT_KEY,
                    name: this.translationService.getImmediate('other'),
                    x: this.translationService.getImmediate('other'),
                    dataKeys: small[0]?.key ? small.reduce((o, v) => { o[v.key] = v.key; return o; }, {}) : null,
                }]);
        }
        return filled;
    }

    getChartOptions(
        type: DashboardWidgetType,
        rows: Array<{ [key: string]: any }> | [Array<{ [key: string]: any }>],
        settings?: AxisChartWidgetSettings | PieChartWidgetSettings,
    ): Highcharts.Options {
        switch (type) {
            case DashboardWidgetType.BarChart : return this.getBarChartOptions(rows, settings as AxisChartWidgetSettings);
            case DashboardWidgetType.LineChart : return this.getLineChartOptions(rows, settings as AxisChartWidgetSettings);
            case DashboardWidgetType.PieChart :
            case DashboardWidgetType.DonutChart : return this.getDonutChartOptions(rows, settings as PieChartWidgetSettings);
            case DashboardWidgetType.ScatterPlotChart : return this.getScatterPlotChartOptions(rows, settings as ScatterPlotWidgetSettings);
        }
    }

    // mapping points to x axis is differnt depending on whether or not we use a range or categories as x-axis
    // if no xRange is set in chart settings we assume categories are being used and series data will not set x property
    public getAxisChartSeriesData(useCategories: boolean, sourceRows): { [key: string]: string } {
        return useCategories
            ? sourceRows.map(item => ({ y: !isNaN(item.y) ? _.toNumber(item.y) : null, color: item.color, id: item.x, data: item.data }))
            : sourceRows.map(item => ({ y: !isNaN(item.y) ? _.toNumber(item.y) : null, color: item.color, id: item.x, x: item.x, data: item.data }));
    }

    private getBarChartOptions(sourceRows: Array<{ [key: string]: any }>, settings: AxisChartWidgetSettings): Highcharts.Options {
        if (!settings) return;
        const useXaxisCategories: boolean = !settings.xRange;
        if (useXaxisCategories) sourceRows = Utils.sortAlphabetical(sourceRows, x => x.label);

        return {
            chart: { type: 'column', backgroundColor: 'rgba(0,0,0,0)' },
            credits: { enabled: false },
            title: { text: null }, // chart title comes from widget component,
            legend: { enabled: false },
            xAxis: [{
                title: {
                    text: settings.xlabel,
                    style: { color: css.colors.WHITE },
                },
                labels: {
                    style: { color: css.colors.WHITE },
                },
                categories: sourceRows.map(item => item.label),
                tickInterval: settings.xInterval,
                min: settings.xRange?.min,
                max: settings.xRange?.max,
            }],
            yAxis: [{
                title: {
                    text: settings.ylabel,
                    style: { color: css.colors.WHITE },
                },
                labels: {
                    style: { color: css.colors.WHITE },
                },
            }],
            series: [{
                type: null,
                data: this.getAxisChartSeriesData(useXaxisCategories, sourceRows),
            }],
        };
    }

    private getLineChartOptions(sourceRows: Array<{ [key: string]: any }> | [Array<{ [key: string]: any }>], settings: AxisChartWidgetSettings): Highcharts.Options {
        if (!settings) return;

        const initialRows = Array.isArray(sourceRows[0]) ? sourceRows[0] : sourceRows;
        const useXaxisCategories: boolean = !settings.xRange;
        if (useXaxisCategories) sourceRows = Utils.sortAlphabetical(sourceRows, x => x.label);

        const options = {
            chart: {
                type: 'line',
                backgroundColor: 'rgba(0,0,0,0)',
                plotBorderColor: css.colors.GRAY,
                plotBorderWidth: 2,
            },
            credits: { enabled: false },
            title: {
                text: settings.title,
                style: { color: css.colors.WHITE },
            }, // chart title comes from parent component
            legend: { enabled: false },
            tooltip: {
                formatter: settings.tooltipFormatter,
            },
            xAxis: [{
                title: {
                    text: settings.xlabel,
                    style: { color: css.colors.WHITE },
                },
                categories: initialRows.map(item => item.label),
                tickInterval: settings.xInterval,
                min: settings.xRange?.min,
                max: settings.xRange?.max,
                labels: {
                    formatter: settings.xLabelFormatter,
                    style: { color: css.colors.WHITE },
                },
            }] as Highcharts.XAxisOptions,
            yAxis: [{
                title: {
                    text: settings.ylabel,
                    style: {
                        color: css.colors.WHITE,
                    },
                },
                tickInterval: settings.yInterval,
                min: settings.yRange?.min,
                max: settings.yRange?.max,
                gridLineDashStyle: 'Dash',
                gridLineColor: css.colors.GRAY,
                gridLineWidth: 2,
                labels: {
                    style: {
                        color: css.colors.WHITE,
                    },
                },
            }] as Highcharts.YAxisOptions,
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                    },
                },
            },
            series: [],
        };

        if (Array.isArray(sourceRows[0])) {
            sourceRows.forEach((rows: Array<{ [key: string]: any }>) => {
                if (useXaxisCategories) rows = Utils.sortAlphabetical(rows, x => x.label);
                options.series.push(
                    {
                        type: null,
                        data: this.getAxisChartSeriesData(useXaxisCategories, rows),
                        events: settings.events,
                        cursor: settings.cursor,
                    },
                );
            });
        } else {
            if (useXaxisCategories) sourceRows = Utils.sortAlphabetical(sourceRows, x => x.label);
            options.series.push(
                {
                    type: null,
                    data: this.getAxisChartSeriesData(useXaxisCategories, sourceRows),
                    events: settings.events,
                    cursor: settings.cursor,
                },
            );
        }

        return options;
    }

    private getDonutChartOptions(sourceRows: Array<{ [key: string]: any }>, settings: PieChartWidgetSettings): Highcharts.Options {
        const options = {
            chart: {
                type: 'pie',
                backgroundColor: 'rgba(0,0,0,0)',
            },
            title: {
                text: null,
            },
            plotOptions: {
                pie: {
                    innerSize: 80,
                    size: 100,
                },
            },
            tooltip: {
                formatter: settings?.tooltipFormatter,
            },
            credits: { enabled: false },
            legend: {
                enabled: false,
            },
            series: [{
                colorByPoint: true,
                type: null,
                data: sourceRows.map(item => ({
                    name: item.label,
                    y: _.toNumber(item.y) || null,
                    color: item.color,
                    showInLegend: true,
                    id: item.x,
                })),
            }],
        };

        return this.getDonutChart(options);
    }

    private getScatterPlotChartOptions(sourceRows: Array<{ [key: string]: any }>, settings: ScatterPlotWidgetSettings): Highcharts.Options {
        return {
            chart: {
                type: 'scatter',
                backgroundColor: 'rgba(0,0,0,0)',
            },
            title: {
                text: null,
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)',
                            },
                        },
                    },
                },
            },
            tooltip: {},
            credits: { enabled: false },
            series: [{
                type: null,
                data: sourceRows.map(item => item),
            }],
        };
    }

    updateData(type: DashboardWidgetType, rows: Array<{ [key: string]: any }>, chart, settings: AxisChartWidgetSettings | PieChartWidgetSettings): void {
        switch (type) {
            case DashboardWidgetType.BarChart :
            case DashboardWidgetType.LineChart :
            case DashboardWidgetType.ScatterPlotChart :
                if (!settings['xRange']) rows = Utils.sortAlphabetical(rows, x => x.label);
                if (chart.xAxis[0]) chart.xAxis[0].setCategories(rows.map(i => i.label));
                if (chart.series[0]) chart.series[0].setData(rows.map(i => {
                    const obj: any = { y: !isNaN(i.y) ? _.toNumber(i.y) : null, id: i.x };
                    if (settings['xRange']) obj.x = i.x;
                    if (i.color) obj.color = i.color;
                    return obj;
                }));
                break;

            case DashboardWidgetType.DonutChart :
            case DashboardWidgetType.PieChart :
                if (chart.series[0]) chart.series[0].setData(rows.map(item => {
                    const obj: any = { name: item.label, y: _.toNumber(item.y) || null, id: item.x };
                    if (item.color) obj.color = item.color;
                    return obj;
                }));

                break;
        }
    }

    getChartDataFromSource(sourceRows: Array<{ [key: string]: any }>, settings, columnTypeMap: ColumnTypeMap, widgetType: DashboardWidgetType) {
        const fn = settings.group === GroupType.Function && settings.groupFn ? evaluate(settings.groupFn) : null;
        const parsed = sourceRows.map(row => {
            return groupWidget(getRow(row, columnTypeMap), settings, columnTypeMap, fn);
        });
        let aggregated = parsed;
        if (settings.aggregate !== AggregateType.Value) {
            const values = parsed.reduce((obj, v) => (obj[v.x] ? (obj[v.x].values.push(v.y), obj[v.x].rows.push(v.rows[0])) : obj[v.x] = { values: [v.y], rows: [v.rows[0]], v }, obj), {});
            aggregated = Object.keys(values).map(x => ({ x, y: aggregate(values[x].values, settings.aggregate), label: values[x].v.label, rows: values[x].rows }));
        }

        const formatted = aggregated.map((a, i) => {
            const color = widgetType === DashboardWidgetType.PieChart ? colors[i % colors.length] : css.colors.LT_BLUE;
            const format = formatWidget(settings, a.rows);
            return {
                x: a.x,
                y: a.y,
                label: a.label,
                color: format.length ? format[0].color : color,
            };
        });
        return formatted;
    }
}
