import { Injectable, OnDestroy } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { UploadResponse } from '@weavix/models/src/upload/upload';


@Injectable({
    providedIn: 'root',
})
export class UploadService {

    constructor(
        private httpService: HttpService,
    ) { }

    upload(component: OnDestroy, file: File): Observable<UploadResponse> & Promise<UploadResponse>;
    upload(component: OnDestroy, file: Blob, filename?: string): Observable<UploadResponse> & Promise<UploadResponse>;
    upload(component: OnDestroy, file: Blob, filename?: string) {
        const formData = new FormData();
        formData.append('file', file, filename || (file as File).name);
        return this.httpService.upload<UploadResponse>(component, '/core/uploads', formData);
    }

    uploadVideo(component: OnDestroy, file: File): Observable<UploadResponse> & Promise<UploadResponse>;
    uploadVideo(component: OnDestroy, file: Blob, filename?: string): Observable<UploadResponse> & Promise<UploadResponse>;
    uploadVideo(component: OnDestroy, file: Blob, filename?: string) {
        const formData = new FormData();
        formData.append('file', file, filename || (file as File).name);
        return this.httpService.upload<UploadResponse>(component, '/core/uploads/video', formData);
    }

    delete(component: OnDestroy, id: string) {
        return this.httpService.delete(component, `/core/uploads/${id}`);
    }
}
