import { Pipe, PipeTransform } from '@angular/core';
import { Chip } from './chip-list.component';

@Pipe({
    name: 'appChipDisplay',
    standalone: true,
})
export class ChipDisplayPipe implements PipeTransform {
    transform(value: Chip | string, displayKey: 'id' | 'name' = 'name'): string {
        return typeof value === 'string' ? value : value[displayKey];
    }
}
