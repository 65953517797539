import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PendingChangesGuard } from 'weavix-shared/guards/pending-changes.guard';
import { AnalyticsService, StAction, StObject } from 'weavix-shared/services/analytics.service';
import { defaultItemExistsModal, Modal, ModalActionType } from 'weavix-shared/services/modal.service';
import { Folder, FolderType } from '@weavix/models/src/folder/folder';
import { AlertService, ServiceError } from 'weavix-shared/services/alert.service';
import { FolderService } from 'weavix-shared/services/folder.service';
import { TagService } from 'weavix-shared/services/tag.service';
import { css } from 'weavix-shared/utils/css';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { Chip, ChipListComponent } from '../../chip-list/chip-list.component';
import { ModalComponent } from 'components/modal/modal.component';
import { CommonModule } from '@angular/common';
import { InputComponent } from 'components/input/input.component';
import { DropdownComponent } from 'components/dropdown/dropdown.component';
import { FormButtonsComponent } from 'components/form-buttons/form-buttons.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { NameExistsComponent } from 'components/modal/name-exists/name-exists.component';

interface FolderEditorModalForm {
    id: FormControl<string>;
    name: FormControl<string>;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-folder-editor-modal',
    templateUrl: './folder-editor-modal.component.html',
    styleUrls: ['./folder-editor-modal.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,

        ChipListComponent,
        DropdownComponent,
        FormButtonsComponent,
        IconComponent,
        InputComponent,
        ModalComponent,
        NameExistsComponent,
    ],
})
export class FolderEditorModalComponent implements OnChanges {
    @ViewChild('nameInput') nameInput: InputComponent;

    @Input() open = false;
    @Input() folderId: string = null;
    @Input() parentFolderId: string = null;
    @Input() folderType: FolderType;
    @Output() closed = new EventEmitter<ModalActionType>();

    folderEditorModal: Modal;
    folderExistsModal: Modal;

    form: FormGroup<FolderEditorModalForm>;

    crafts: Chip[] = [];
    tags: Chip[] = [];
    companies: Chip[] = [];
    people: Chip[] = [];

    css = css;

    addTagFn = async (tag: string): Promise<Chip> => {
        const newTag = await this.tagService.add(this, { name: tag });
        return { id: newTag.id, name: newTag.name };
    };

    constructor(
        private cdr: ChangeDetectorRef,
        private pendingChangesGuard: PendingChangesGuard,
        private alertsService: AlertService,
        private tagService: TagService,
        private folderService: FolderService,
        private translationService: TranslationService,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.open?.currentValue) {
            this.modalOpen();
        } else {
            this.modalClose();
        }
    }

    canDeactivate() { return this.form.pristine; }

    get nameValue(): string { return this.form?.get('name').value; }
    get suggestedFolderName(): string { return `${this.nameValue}${this.translationService.getImmediate('rField.site.site-name')}`; }

    public markFormAsDirty(): void {
        this.form.updateValueAndValidity();
        this.form.markAsDirty();
    }

    async submit() {
        const formValues = this.form.getRawValue();
        const data: Partial<Folder> = {
            id: this.folderId,
            parentId: this.parentFolderId,
            name: formValues?.name ?? '',
        };
        try {
            this.alertsService.setAppLoading(true);

            data.id ? await this.folderService.update(this, this.folderType, data)
                : await this.folderService.create(this, this.folderType, data);

            AnalyticsService.track(StObject.Folder, data.id ? StAction.Updated : StAction.Created, this.constructor.name);

            this.alertsService.sendSuccess({ messageKey: `folders.message.success.${data.id ? 'updated' : 'added'}` });
            this.closed.next(ModalActionType.submit);
        } catch (err) {
            this.handleFormSubmissionErrors(err, !!data.id);
        } finally {
            this.alertsService.setAppLoading(false);
        }
    }

    private handleFormSubmissionErrors(err: any, update: boolean) {
        if (err?.error?.details?.field === 'name' && err?.error?.details?.reason === 'duplicate') {
            this.folderExistsModal = defaultItemExistsModal;
            this.folderExistsModal.actions[ModalActionType.cancel].show = false;
            this.folderExistsModal.header.textKey = `folders.error.folder-exists.title`;
        } else {
            this.alertsService.sendServiceError(err, update ? ServiceError.Update : ServiceError.Add, 'folders.folder');
        }
        this.cdr.detectChanges();
    }

    handleFolderExistsModalClose(action: ModalActionType) {
        if (action === ModalActionType.submit) {
            const sitePlaceholder = this.translationService.getImmediate('rField.site.site-name');
            const folderName = this.suggestedFolderName
                .replace(sitePlaceholder, '');
            this.form.get('name').setValue(folderName);
            this.nameInput?.focus();
        }
        this.folderExistsModal = null;
        this.cdr.detectChanges();
    }

    async handleModalClose(action: ModalActionType) {
        if (action === ModalActionType.cancel) {
            this.cancel();
        } else {
            this.closed.next(action);
        }
    }

    async cancel() {
        if (await this.pendingChangesGuard.canDeactivate(this)) {
            this.closed.next(ModalActionType.cancel);
        }
    }

    async modalOpen() {
        this.folderEditorModal = {
            isOpen: true,
            actions: {},
            header: {
                textKey: 'folders.add-folder.title',
                showSeparator: true,
                textAlignment: 'left',
            },
            fullScreen: false,
            content: true,
        };

        this.form = new FormGroup({
            id: new FormControl(this.folderId),
            name: new FormControl('', [Validators.required]),
        });

        await this.loadFolder();
    }

    modalClose() {
        this.folderEditorModal = null;
        this.closed.emit();
    }

    private async loadFolder() {
        this.alertsService.setAppLoading(true);
        const folder = this.folderId ? await this.folderService.getFolder(this, this.folderType, this.folderId) : null;

        this.form.patchValue({
            name: folder?.name ?? '',
        });

        this.form.markAsPristine();
        this.alertsService.setAppLoading(false);
    }
}
