import { Component, EventEmitter, Input, Output, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { translateHeading } from '@weavix/domain/src/geometry/polygon';
import { AnyBadgeEvent } from '@weavix/models/src/badges/event';
import { LocationContributor } from '@weavix/models/src/location/location-update';
import { RowItemType, TableOptions } from 'weavix-shared/models/table.model';
import { BadgeLocation } from 'weavix-shared/models/weavix-map.model';
import { AccountService } from 'weavix-shared/services/account.service';
import { GeofenceService } from 'weavix-shared/services/geofence.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-badge-event-detail',
    templateUrl: './badge-event-detail.component.html',
    styleUrls: ['./badge-event-detail.component.scss', '../map-detail-view.scss'],
})
export class BadgeEventDetailComponent implements OnInit, OnChanges {
    @Input() badgeEvent: BadgeLocation;
    @Output() closeOutput = new EventEmitter<void>();
    @Output() detailViewEvent = new EventEmitter<any>();

    selectedSources: { [key: string]: any } = {};
    tableOptions: TableOptions = {
        title: {
            string: '',
        },
        rowEdits: [],
        keyCol: '',
        columns: [{
            title: 'Source',
            colKey: 'source',
            type: RowItemType.text,
        }],
    };

    measurementUnits: 'm' | 'ft' = 'ft';
    events: string[] = [];

    constructor(
        private accountService: AccountService,
        private translationService: TranslationService,
        private geofenceService: GeofenceService,
    ) { }
    async ngOnInit() {
        if (this.accountService.metric) this.measurementUnits = 'm';
    }
    async ngOnChanges(changes: SimpleChanges) {
        if (changes.badgeEvent?.previousValue?.id !== changes.badgeEvent?.currentValue?.id) {
            this.selectedSources = {};
            this.detailViewEvent.emit(this.selectedSources);
            this.events = [];
            this.badgeEvent?.events?.forEach(async (event: AnyBadgeEvent) => {
                this.events.push(await this.getEventDisplay(event));
            });
        }
    }

    handleClose() {
        this.closeOutput.emit();
    }

    emitDetailViewEvent(event: BadgeLocation) {
        if (this.selectedSources[event.id]) delete this.selectedSources[event.id];
        else this.selectedSources[event.id] = event;

        this.detailViewEvent.emit(this.selectedSources);
    }

    getColor(id: string) {
        if (this.selectedSources[id]) return '#4197cb';
        return null;
    }

    getContributorDisplay(contributor: LocationContributor) {
        switch (contributor.type) {
            case ('survey'): return this.translationService.getImmediate('shared.map.fingerprint');
            case ('peer'): return `${this.translationService.getImmediate('shared.map.peer')} ${contributor.name}`;
            case ('network'): return `${this.translationService.getImmediate('shared.map.network-ap')} (${contributor.id})`;
            case ('beacon'): return contributor.id;
            default: return contributor.type;
        }
    }

    async getEventDisplay(event: AnyBadgeEvent) {
        if (event.type === 'geofence-enter' || event.type === 'geofence-exit') {
            return `${this.getEventPrefix(event)} ${(await this.geofenceService.get(this, event.geofenceId)).name} ${this.translationService.getImmediate('shared.geofence.geofence')}`;
        }
        if (event.type === 'facility-enter' || event.type === 'facility-exit') {
            return `${this.getEventPrefix(event)} ${this.translationService.getImmediate('shared.facility.facility')}`;
        }
        return event.type;
    }

    getEventPrefix(event: AnyBadgeEvent) {
        return event.type.includes('entered') ? this.translationService.getImmediate('shared.entered') : this.translationService.getImmediate('shared.exited');
    }

    getHeading(heading: number) {
        if (!heading) return;
        return translateHeading(heading);
    }

    getSpeed(speed?: number) {
        if (!speed) return;
        const multiplier = this.measurementUnits === 'm' ? 3.6 : 2.23694;
        return `${Math.round(speed * 10 * multiplier) / 10}${this.measurementUnits === 'm' ? 'kph' : 'mph'}`;
    }
}
