interface FeatureBackendResult {
    apiUrl: string;
    mqttUrl: string;
}

export function getFeatureBackend(version: string): FeatureBackendResult | null {
    // only on a feature branch pointed at dev
    if (/^[0-9]/.exec(version) || /^(demo|stage|BUILD)/.exec(version)) {
        return null;
    }

    const feature = /[a-z0-9-]+/i.exec(version)?.[0];
    if (!feature || feature === 'local') return null;

    return {
        apiUrl: `https://api-${feature}.weavixdev.com`,
        mqttUrl: `mqtt-${feature}.weavixdev.com`,
    };
}
