import { coerceToDate } from './coerce-to-date';

// Dates and strings are considered dates. Numbers are not dates, just numbers.
type MaxLike = number | Date | string;

/**
 * A function like Math.max() that emulates the $max capability of MongoDB.
 * https://www.mongodb.com/docs/manual/reference/operator/update/max/
 * It can compare numbers and dates, versus Math.max() can only do numbers.
 * 
 * It can also compare ISO-8601 strings because dates are serialized to strings in
 * JSON, which we don't consistently marshal back to dates on the client side.
 * Usually our date types are actually strings.
 * 
 * @param first First value to compare
 * @param second Second value to compare
 * @returns The higher value
 */
export function mongoDbMax(first: MaxLike, second: MaxLike): MaxLike | null {
    if (nullish(first) && nullish(second)) return null;
    if (nullish(first) && validMaxLike(second)) return second;
    if (nullish(second) && validMaxLike(first)) return first;

    if (!validMaxLike(first) || !validMaxLike(second)) {
        throw new Error(`Invalid input.`
            + ` Params must be number, date, or ISO-8601 string.`
            + ` Got: ${first} and ${second}`);
    }

    /*
        taking a small chance here - if one param is a date or date-string, assume the return type should be a date.
        we have not used $max for non-date strings so this should be fine.
        
        this allows any of the following combinations to return a date:
        - date + date
        - date + date string
        - date + number (epoch)
        - date string + date string
        - date string + number (epoch)
    */
    const hasADate = first instanceof Date || second instanceof Date;
    if (hasADate || typeof first === 'string' || typeof second === 'string') {
        const firstDate = coerceToDate(first)!;
        const secondDate = coerceToDate(second)!;
        const maxDate = firstDate > secondDate ? firstDate : secondDate;
        // if we had string inputs, return as a string to be consistent
        return hasADate ? maxDate : maxDate.toISOString();
    }

    // if we got this far, both params are numbers.
    return Math.max(first as number, second as number);
}

function validMaxLike(value: MaxLike): boolean {
    return value instanceof Date
        || typeof value === 'number'
        || (typeof value === 'string' && isDateString(value));
}

function isDateString(value: string): boolean {
    return !isNaN(Date.parse(value));
}

function nullish(value: unknown): boolean {
    return value === null || value === undefined;
}

