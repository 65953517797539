import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DropdownItem } from 'components/dropdown/dropdown.model';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CustomValidators } from 'weavix-shared/custom-validators';
import { Company } from '@weavix/models/src/company/company';
import { AnyBadgeEvent, EventType } from '@weavix/models/src/badges/event';
import { AnyItem, DropDownField, FieldType, Item, ItemField, ItemType } from 'weavix-shared/models/item.model';
import { Person } from 'weavix-shared/models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { PermissionAction } from 'weavix-shared/permissions/permissions.model';
import { AccountService } from 'weavix-shared/services/account.service';
import { AlertService, ServiceError } from 'weavix-shared/services/alert.service';
import { BadgeService } from 'weavix-shared/services/badge.service';
import { CompanyService } from 'weavix-shared/services/company.service';
import { GeofenceService } from 'weavix-shared/services/geofence.service';
import { ItemService } from 'weavix-shared/services/item.service';
import { MapService } from 'weavix-shared/services/map.service';
import { PersonService } from 'weavix-shared/services/person.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { PubSubService } from 'weavix-shared/services/pub-sub.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { UploadService } from 'weavix-shared/services/upload.service';
import { css } from 'weavix-shared/utils/css';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { GEOFENCE_EVENT_TYPES, RowEventType } from '../../../weavix-map/event-log/event.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-item-detail',
    templateUrl: './item-detail.component.html',
    styleUrls: ['./item-detail.component.scss', '../map-detail-view.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemDetailComponent implements OnInit, OnDestroy, OnChanges {
    @Input() item: Item;
    @Output() closeOutput: EventEmitter<void> = new EventEmitter();

    form: FormGroup<{
        owner: FormControl<string> | null;
        assignedToId: FormControl<string>;
        properties: FormGroup<{
            properties: FormArray<FormGroup<{
                key: FormControl<string>;
                value: FormControl<any>;
                fieldId: FormControl<string>;
            }>>;
        }> | null;
    }> | null;
    fieldType = FieldType;
    companiesInput: DropdownItem[] = [];
    peopleInput: DropdownItem[] = [];
    hasUpdatePermission: boolean = false;
    rowEventTypes: RowEventType[] = GEOFENCE_EVENT_TYPES;
    badgeEventsHistory: AnyBadgeEvent[];
    badgeEvents: Subject<AnyBadgeEvent> = new Subject<AnyBadgeEvent>();
    css = css;
    private fieldDropdownOptionsMap = {};
    private fieldTypeMap = {};
    private formSubscription: Subscription;
    private badgeEventsSub: Subscription;
    submittingChanges: boolean = false;
    isLoading: boolean = false;
    itemType: ItemType;
    get itemProperties() {
        return this.form.controls.properties.controls.properties;
    }

    constructor(
        private itemService: ItemService,
        private profileService: ProfileService,
        private accountService: AccountService,
        private companyService: CompanyService,
        private geofenceService: GeofenceService,
        private peopleService: PersonService,
        private alertService: AlertService,
        private mapService: MapService,
        private badgeService: BadgeService,
        private uploadService: UploadService,
        private pubSubService: PubSubService,
        private translationService: TranslationService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.hasUpdatePermission = this.profileService.hasPermission(PermissionAction.EditSites, this.item.facilityId);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes?.item?.currentValue?.id !== changes?.item?.previousValue?.id) {
            this.isLoading = true;
            await this.setItemType(this.item);
            await this.getFormDataAndBuildForm();
            await this.subscribeToBadgeEvents();
            await this.setBadgeEventHistory();
            this.isLoading = false;
            this.cdr.markForCheck();
        }
    }

    ngOnDestroy() {
        this.formSubscription?.unsubscribe();
    }

    private async setItemType(item: Item) {
        this.itemType = await this.itemService.getType(this, item.typeId);
    }

    private async getFormDataAndBuildForm(): Promise<void> {
        if (!this.companiesInput.length) await this.loadCompanies();
        if (!this.peopleInput.length) await this.loadPeople();
        this.buildForm();
    }

    private async submitFormChanges(): Promise<void> {
        if (this.form.invalid) return;
        this.submittingChanges = true;
        const formValues = this.form.value;
        try {
            let request: AnyItem = {
                name: this.item.name,
                typeId: this.item.typeId,
                itemProperties: (formValues.properties.properties || []).map(p => ({ value: p.value, fieldId: p.fieldId })),
                image: this.item?.image ?? null,
            };

            if (this.itemType?.dynamicMapItem) {
                request = {
                    ...request,
                    assignedToId: formValues?.assignedToId,
                    owner: formValues?.owner,
                };
            }
            await this.itemService.updateItem(this, this.item.id, request);
        } catch (e) {
            this.alertService.sendServiceError(e, ServiceError.Update, 'shared.items.items');
        } finally {
            this.submittingChanges = false;
            this.cdr.markForCheck();
        }
    }

    async handleImageChange(value?: { src: string, file: File }): Promise<void> {
        try {
            if (value?.file) {
                const image = await this.uploadService.upload(this, value.file);
                this.item.image = image.uri;
            } else {
                this.item.image = null;
            }
            await this.submitFormChanges();
        } catch (e) {
            this.alertService.sendServiceError(e, ServiceError.Update, 'shared.items.items');
        } finally {
            this.cdr.markForCheck();
        }
    }

    private async loadCompanies() {
        try {
            const account = await this.accountService.getAccount();
            const companies: Company[] = await this.companyService.getAllOnAccount(this);
            this.companiesInput = companies
                .map(company => ({ label: company.name, key: company.id, imgUrl: company?.logo }))
                .concat([{ label: account.name, key: account.id, imgUrl: account?.image }]);
        } catch (e) {
            console.error(e);
            this.alertService.sendServiceError(e, ServiceError.Get, 'shared.company.companies');
        }
    }

    private async loadPeople() {
        try {
            const people: Person[] = await this.peopleService.getPeople(this);
            this.peopleInput = people.map(p => ({ label: p.fullName, key: p.id, imgUrl: p.avatarFile }));
        } catch (e) {
            console.error(e);
            this.alertService.sendServiceError(e, ServiceError.Get, 'shared.person.people');
        }
    }

    private buildForm() {
        if (this.formSubscription) {
            this.formSubscription?.unsubscribe();
            this.formSubscription = null;
        }

        this.form = null;

        let propertiesSection: FormGroup = null;

        if (this.itemType?.fields) {
            const properties = this.itemType.fields.map(field => this.getAndSetupItemPropertyFields(field, this.item));
            propertiesSection = this.fb.group({ properties: this.fb.array(properties) });
        }

        this.form = this.fb.group({
            owner: this.itemType.dynamicMapItem ? this.fb.control({ value: this.item?.owner ?? '', disabled: !this.hasUpdatePermission }, [Validators.required]) : null,
            assignedToId: this.fb.control({ value: this.item.assignedToId ?? '', disabled: !this.hasUpdatePermission }),
            properties: propertiesSection,
        });

        this.formSubscription = this.form.valueChanges
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(() => this.submitFormChanges());
    }

    private getAndSetupItemPropertyFields(field: ItemField, itemData: Item): FormGroup {
        const getItemPropValidator = () => {
            const validators: ValidatorFn[] = [];
            if (field.type === FieldType.Number) validators.push(CustomValidators.anyNumber);
            return validators;
        };

        const getDropdownOptions = (option: string): DropdownItem => ({
            key: option,
            label: option,
        });

        this.fieldTypeMap[field.fieldId] = field.type;
        if (field.type === FieldType.Dropdown) {
            const dropdownField = field as DropDownField;
            const options = dropdownField.options || [];
            this.fieldDropdownOptionsMap[field.fieldId] = options.map(o => getDropdownOptions(o));
        }

        const itemProperty = (itemData?.itemProperties ?? []).find(p => p.fieldId === field.fieldId);
        return this.fb.group({
            key: this.fb.control(field.display),
            value: this.fb.control({ value: itemProperty?.value ?? '', disabled: !this.hasUpdatePermission }, getItemPropValidator()),
            fieldId: this.fb.control(field.fieldId),
        });
    }

    getFieldOptions(fieldId) {
        return this.fieldDropdownOptionsMap[fieldId];
    }

    getFieldType(fieldId) {
        return this.fieldTypeMap[fieldId];
    }

    private async setBadgeEventHistory() {
        const toDate = this.mapService.dvrPlaybackState.inPlaybackMode ? new Date(this.mapService.dvrTimestamp) : null;
        this.badgeEventsHistory = await this.badgeService.getItemEvents(this, this.item.id, null, toDate);
    }

    private async subscribeToBadgeEvents() {
        if (this.badgeEventsSub) this.badgeEventsSub.unsubscribe();

        this.badgeEventsSub = (await this.pubSubService.subscribe<AnyBadgeEvent>(this, Topic.AccountPersonBadgeEvent, [this.accountService.getAccountId(), this.item.id]))
            .subscribe(async (message) => {
                if (this.mapService.dvrPlaybackMode) return;
                this.badgeEvents.next(message.payload);
            });
    }

    eventNameLookUp() {
        return <T extends AnyBadgeEvent>(row: T) => {
            if (row.type === EventType.GeofenceEnter) {
                return this.geofenceService.getGeofenceEventString(EventType.GeofenceEnter, row['geofenceName']);
            } else if (row.type === EventType.GeofenceExit) {
                return this.geofenceService.getGeofenceEventString(EventType.GeofenceExit, row['geofenceName']);
            } else {
                return this.translationService.getImmediate('generics.unknown');
            }
        };
    }

    handleClose() {
        this.closeOutput.emit();
    }
}
