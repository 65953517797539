<app-loading [loading]="loading" [theme]="'transparent'"></app-loading> 

<div *ngIf="!loading" class="wrapper flex1 flexColumn jcFS">
    <div class="flexColumn jcC h100p w100p" *ngIf="!geofence || isError">
        <p class="no-data">{{'ERRORS.GENERIC.LOAD-DATA' | translate}}</p>
    </div>

    <ng-container *ngIf="geofence && !isError">
        <div (click)="handleClose()" class="intro">
            <app-icon
                class="intro-close"
                [faIcon]="'fa fa-chevron-left'"
            ></app-icon>

            <p *ngIf="geofence.name" class="intro-name">{{geofence.name}}</p>
        </div>

        <div class="flexColumn jcFS">
            <ng-container *ngTemplateOutlet="stat; context: {text: 'map.geofence.people-in-area', value: people.length, id: 'people-in-area' }"></ng-container>
            <ng-container *ngTemplateOutlet="stat; context: {text: 'map.geofence.companies-in-area', value: companiesInArea.size, id: 'companies-in-area' }"></ng-container>
            <ng-container *ngTemplateOutlet="stat; context: {text: 'map.geofence.crafts-in-area', value: craftsInArea.size, id: 'crafts-in-area' }"></ng-container>
        </div>

        <div *ngIf="badgeEventsHistory" class="flex1 flexColumn jcFS">
            <app-event-log
                [badgeEvents]="badgeEvents.asObservable()" 
                [badgeEventsHistory]="badgeEventsHistory"
                [rowEventTypes]="rowEventTypes"
                [nameLookUp]="eventPersonNameLookUp()"
            ></app-event-log>
        </div>
    </ng-container>
</div>

<ng-template #stat let-text="text" let-value="value" let-id="id">
    <div class="stat" id="{{id}}">
        <ng-container>
            <p class="stat-text">
                {{text | translate}}
            </p>
            <p class="stat-value">
                {{value | number}}
            </p>
        </ng-container>
    </div>
</ng-template>
