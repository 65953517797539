
<div class="wrapper flex1 flexColumn jcFS">
    <div class="content-wrapper">
        <app-filter-header
            [titleKey]="title"
        ></app-filter-header>
        <div class="scroll-wrapper">
            <div *ngIf="surveyCount === 0">
                <em>{{'configuration.facility-network-coverage.no-data' | translate}}</em>
            </div>
            <table *ngIf="surveyCount > 0">
                <tr *ngIf="averages['rssi'] && metricsToDisplay.includes('rssi')">
                    <td class="label">
                        <strong>{{'configuration.facility-network-coverage.metric-rssi' | translate}}</strong>
                    </td>
                    <td>
                        {{averages['rssi'].toFixed(1)}} {{'configuration.facility-network-coverage.dbm' | translate}}
                    </td>
                </tr>
                <tr *ngIf="averages['rsrq'] && metricsToDisplay.includes('rsrq')">
                    <td class="label">
                        <strong>{{'configuration.facility-network-coverage.metric-rsrq' | translate}}</strong>
                    </td>
                    <td>
                        {{averages['rsrq'].toFixed(1)}}
                    </td>
                </tr>
                <tr *ngIf="averages['pingMs'] && metricsToDisplay.includes('ping')">
                    <td class="label">
                        <strong>{{'configuration.facility-network-coverage.metric-ping' | translate}}</strong>
                    </td>
                    <td>
                        {{averages['pingMs'].toFixed(1)}} {{'configuration.facility-network-coverage.ms' | translate}}
                    </td>
                </tr>
                <tr *ngIf="averages['uploadMbps'] && metricsToDisplay.includes('download-upload-speed')">
                    <td class="label">
                        <strong>{{'configuration.facility-network-coverage.metric-upload' | translate}}</strong>
                    </td>
                    <td>
                        {{averages['uploadMbps'].toFixed(1)}} {{'configuration.facility-network-coverage.mbps' | translate}}
                    </td>
                </tr>
                <tr *ngIf="averages['downloadMbps'] && metricsToDisplay.includes('download-upload-speed')">
                    <td class="label">
                        <strong>{{'configuration.facility-network-coverage.metric-download' | translate}}</strong>
                    </td>
                    <td>
                        {{averages['downloadMbps'].toFixed(1)}} {{'configuration.facility-network-coverage.mbps' | translate}}
                    </td>
                </tr>
                
            </table>
        </div>
    </div>


</div>
