import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { buildAvatar } from '@weavix/domain/src/avatar/avatar';
import { css } from '@weavix/domain/src/utils/css';
import { Avatar } from '@weavix/models/src/avatar/avatar';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { environment } from 'environments/environment';
import { AppIcon, FontelloIcon } from 'weavix-shared/models/icon.model';
import { NotificationCenterEntry, NotificationCenterAcknowledgement, NotificationCenterType } from '@weavix/models/src/notification/notification-center';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { NotificationCenterService, closeNotification$, openNotification$ } from 'weavix-shared/services/notification-center.service';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'components/icon/icon.component';
import { AvatarPersonComponent } from 'components/avatar/avatar-person/avatar-person.component';
import { TimeAgoPipe } from 'weavix-shared/pipes/time-ago.pipe';
import { TranslateModule } from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss', './notification-card-teams.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        TimeAgoPipe,

        IconComponent,
        AvatarPersonComponent,
    ],
})
export class NotificationCardComponent implements OnInit {

    constructor(
        private notificationCenterService: NotificationCenterService,
        private profileService: ProfileService,
        private translationService: TranslationService,
    ) {
    }

    public readonly NotificationCenterType = NotificationCenterType;

    @Input() notification: NotificationCenterEntry;

    @Output() updateUnreadCount = new EventEmitter<void>();
    @Output() removeNotification = new EventEmitter<string>();
    @Output() reloadChannels = new EventEmitter<string>();

    teamsApp = environment.teamsApp;

    avatar: Avatar;
    requestedById: string;
    selected: boolean = false;
    language: string;

    async ngOnInit() {
        this.language = this.translationService.getLocale();
        const myPersonId = (await this.profileService.getUserProfile(this)).id;
        this.avatar = buildAvatar(60, 60);
        this.requestedById = this.notification.dataId.people?.find(p => p !== myPersonId);
        Utils.safeSubscribe(this, closeNotification$).subscribe(() => {
            this.selected = false;
        });
    }

    get icon(): AppIcon {
        const faIcon = this.notification?.icon?.value ? `fas fa-${this.notification?.icon?.value}` : null;
        return { faIcon, fntlIcon: FontelloIcon.PersonConnectionAdd, fontSize: this.teamsApp ? '18px' : '30px' };
    }

    get iconBackgroundColor() {
        if (this.notification.type !== NotificationCenterType.FacilityAlert) return null;
        return this.notification.read ? { 'background-color': css.colors.GRAY_LT } : { 'background-color': this.notification.icon?.color };
    }

    public async removeNotificationClick(e: Event, id: string) {
        e.stopPropagation();
        this.removeNotification.emit(id);
    }

    public async openNotificationModal(e: Event) {
        e.stopPropagation();
        this.selected = true;
        openNotification$.next(this.notification);
    }

    public async acknowledgeClick(e: Event) {
        e.stopPropagation();
        this.notification.acknowledgement = NotificationCenterAcknowledgement.Acknowledged;
        this.notification.read = new Date();
        if (this.notification.type === NotificationCenterType.FacilityAlert) this.notificationCenterService.updateFacilityAlert(this, this.notification.dataId, this.notification.accountId, { read: true });
        await this.notificationCenterService.update(this, this.notification.id, { read: true, acknowledgement: NotificationCenterAcknowledgement.Acknowledged });
        this.updateUnreadCount.emit();
    }
}
