import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItemGroup } from './dropdown.model';

@Pipe({
    name: 'appDropdownIsEmpty',
    standalone: true,
})
export class DropdownIsEmptyPipe implements PipeTransform {
    transform(value: DropdownItemGroup[]): boolean {
        return value.every(group => group.items.length === 0);
    }
}