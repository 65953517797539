import { Injectable } from '@angular/core';
import { AnyBadgeEvent, EventType } from '@weavix/models/src/badges/event';
import { AppIcon, FontelloIcon } from 'weavix-shared/models/icon.model';
import { FEATURE_ICONS } from 'weavix-shared/utils/feature.icons';

export const mapExitToDisconnectEvent = (x: AnyBadgeEvent[]) => x.map(e => {
    if (e.disconnect) {
        switch (e.type) {
            case EventType.GeofenceExit:
                e.type = EventType.GeofenceDisconnect;
                break;
            case EventType.BeaconExit:
                e.type = EventType.BeaconDisconnect;
                break;
        }
    }
    return e;
});

type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

export type RowEventMatch = EventType | RecursivePartial<AnyBadgeEvent>;

export enum RowEventType {
    FormSubmission,

    BeaconDisconnect,
    BeaconEnter,
    BeaconExit,

    GeofenceDisconnect,
    GeofenceEnter,
    GeofenceExit,

    LevelEnter,
    LevelExit,

    ConfinedSpaceEnter,
    ConfinedSpaceExit,
    ConfinedSpaceAttendantIn,
    ConfinedSpaceAttendantOut,
    ConfinedSpaceAirReading,

    MobileLogout,
    Moving,
    Stationary,
    PeerEnter,
    PeerExit,
}

export interface RowEventDefinition {
    eventType: RowEventType;
    eventTitle: string;
    icon: AppIcon;
    subEvents?: RowEventDefinition[];
}

export const EVENT_MATCHES: { [key in RowEventType]: RowEventMatch } = {
    [RowEventType.FormSubmission]: EventType.FormSubmission,

    [RowEventType.BeaconDisconnect]: EventType.BeaconDisconnect,
    [RowEventType.BeaconEnter]: EventType.BeaconEnter,
    [RowEventType.BeaconExit]: EventType.BeaconExit,
    [RowEventType.PeerEnter]: EventType.PeerEnter,
    [RowEventType.PeerExit]: EventType.PeerExit,

    [RowEventType.GeofenceDisconnect]: EventType.GeofenceDisconnect,
    [RowEventType.GeofenceEnter]: EventType.GeofenceEnter,
    [RowEventType.GeofenceExit]: EventType.GeofenceExit,

    [RowEventType.LevelEnter]: EventType.LevelEnter,
    [RowEventType.LevelExit]: EventType.LevelExit,

    [RowEventType.ConfinedSpaceEnter]: EventType.EntryEnter,
    [RowEventType.ConfinedSpaceExit]: EventType.EntryExit,
    [RowEventType.ConfinedSpaceAttendantIn]: EventType.EntryAttendantIn,
    [RowEventType.ConfinedSpaceAttendantOut]: EventType.EntryAttendantOut,
    [RowEventType.ConfinedSpaceAirReading]: EventType.AirReadingSubmission,

    [RowEventType.MobileLogout]: EventType.MobileLogout,
    [RowEventType.Stationary]: EventType.Stationary,
    [RowEventType.Moving]: EventType.Moving,
};

export const GEOFENCE_EVENT_TYPES = [
    RowEventType.GeofenceDisconnect,
    RowEventType.GeofenceEnter,
    RowEventType.GeofenceExit,
];

export const BEACON_EVENT_TYPES = [
    RowEventType.BeaconDisconnect,
    RowEventType.BeaconEnter,
    RowEventType.BeaconExit,
];

export const LEVEL_EVENT_TYPES = [
    RowEventType.LevelEnter,
    RowEventType.LevelExit,
];

export const CONFINED_SPACE_EVENT_TYPES = [
    RowEventType.ConfinedSpaceEnter,
    RowEventType.ConfinedSpaceExit,
    RowEventType.ConfinedSpaceAttendantIn,
    RowEventType.ConfinedSpaceAttendantOut,
    RowEventType.ConfinedSpaceAirReading,
];

@Injectable()
export class EventService {
    static rowEvents: RowEventDefinition[] = [
        { eventType: RowEventType.FormSubmission, eventTitle: 'forms.formSub', icon: { faIcon: 'far fa-file-alt' } },

        { eventType: RowEventType.BeaconDisconnect, eventTitle: 'events.beacon-disconnect', icon: { faIcon: 'fas fa-unlink not-boxed' } },
        { eventType: RowEventType.BeaconEnter, eventTitle: 'events.beacon-enter', icon: FEATURE_ICONS.beacons.icon },
        { eventType: RowEventType.BeaconExit, eventTitle: 'events.beacon-exit', icon: FEATURE_ICONS.beacons.icon },

        { eventType: RowEventType.GeofenceDisconnect, eventTitle: 'events.geofence-disconnect', icon: { fntlIcon: FontelloIcon.Disconnect } },
        { eventType: RowEventType.GeofenceEnter, eventTitle: 'events.geofence-enter', icon: { faIcon: 'fas fa-arrow-right not-boxed' } },
        { eventType: RowEventType.GeofenceExit, eventTitle: 'events.geofence-exit', icon: { faIcon: 'fas fa-arrow-left not-boxed' } },

        { eventType: RowEventType.LevelEnter, eventTitle: 'events.level-enter', icon: { faIcon: 'fas fa-arrow-right not-boxed' } },
        { eventType: RowEventType.LevelExit, eventTitle: 'events.level-exit', icon: { faIcon: 'fas fa-arrow-left not-boxed' } },

        { eventType: RowEventType.ConfinedSpaceEnter, eventTitle: 'events.item-entry', icon: { faIcon: 'fas fa-user' } },
        { eventType: RowEventType.ConfinedSpaceExit, eventTitle: 'events.item-exit', icon: { faIcon: 'fas fa-user not-boxed' } },
        { eventType: RowEventType.ConfinedSpaceAttendantIn, eventTitle: 'events.entry-attendant-in', icon: { faIcon: 'far fa-clipboard' } },
        { eventType: RowEventType.ConfinedSpaceAttendantOut, eventTitle: 'events.entry-attendant-out', icon: { faIcon: 'far fa-clipboard' } },
        { eventType: RowEventType.ConfinedSpaceAirReading, eventTitle: 'events.air-reading-submission', icon: { faIcon: 'fab fa-wpforms' } },

        { eventType: RowEventType.MobileLogout, eventTitle: 'events.sign-out', icon: { faIcon: 'fa fa-sign-out-alt' } },
        { eventType: RowEventType.Stationary, eventTitle: 'events.geofence-stationary', icon: { faIcon: 'fa fa-octagon' } },
        { eventType: RowEventType.Moving, eventTitle: 'events.geofence-moving', icon: { faIcon: 'fa fa-running' } },
    ];

    private static readonly rowEventsMap: Map<RowEventType, RowEventDefinition> = EventService?.rowEvents.reduce((map, e) => { map.set(e.eventType, e); return map; }, new Map());

    static isGeofenceBadgeEvent(event: AnyBadgeEvent) {
        return event && [
            EventType.GeofenceEnter, EventType.GeofenceExit, EventType.GeofenceDisconnect,
        ].includes(event.type);
    }

    static getAll() {
        return EventService.rowEvents;
    }

    static getByTypes(types: RowEventType[] = []) {
        return types.map(t => this.rowEventsMap.get(t));
    }

    static eventMatchesDefinition(event: AnyBadgeEvent, definition: RowEventDefinition): boolean {
        const defMatch = EVENT_MATCHES[definition.eventType];
        const match = typeof defMatch === 'string' ? { type: defMatch } : defMatch;
        return this.isMatchEqual(event, match);
    }

    static isValidEvent(event: AnyBadgeEvent, types: RowEventType[]): any {
        const isValid = event.type !== EventType.MessageDelivered
            && this.findEventDefinitionFromList(event, types.map(t => this.rowEventsMap.get(t)));
        return isValid;
    }

    static findDefinition(event: AnyBadgeEvent) {
        return this.findEventDefinitionFromList(event, this.rowEvents);
    }

    private static findEventDefinitionFromList(event: AnyBadgeEvent, eventDefinitions: RowEventDefinition[]) {
        const found = eventDefinitions.find(d => this.eventMatchesDefinition(event, d));
        return found && found.subEvents ? this.findEventDefinitionFromList(event, found.subEvents) : found;
    }

    private static isMatchEqual(event: any, match: RecursivePartial<AnyBadgeEvent>) {
        const isEqual = Object.keys(match).every(mk => typeof match[mk] === 'object' ? this.isMatchEqual(event[mk], match[mk]) : match[mk] === event[mk]);
        return isEqual;
    }
}
