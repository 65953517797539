<div [ngClass]="[fullRow || singleRow ? 'single-row-container' : 'container']" [style.maxWidth.px]="(alteredSize * avatarsAcross) + 1">
    <app-avatar-person *ngFor="let id of clusterPeople; let i = index;"
        [personId]="id"
        [avatarInput]="avatar"
        [indicators]="setIndicators(showAvailability || clusterPeople.length === 1)"
        [backgroundColor]="clusterPeople.length > 1 ? backgroundColor : 'transparent'"
        [ngStyle]="{'z-index': clusterPeople.length - i, 'margin-left': fullRow ? '-5px' : '', 'margin-top': i > 1 && !fullRow && !singleRow ? '-3px' : '0px'}"
        [hasUserInfoPopover]="hasUserInfoPopover"
        (avatarPopoverOutput)="handleAvatarPopoverOutput($event)"
    ></app-avatar-person>

    <div *ngIf="numNonClusterPeople > 0" 
        class="non-cluster-num"
        [style.width.px]="alteredSize"
        [style.margin-left.px]="1"
    >
        +{{ numNonClusterPeople }}
    </div>
</div>
<ng-content></ng-content>
