import { css } from '../utils/css';

export enum WaltStatus {
    PoweredOnLoggedIn = 'powered-on-and-logged-in', // Power on & user logged in
    PoweredOnLoggedOut = 'powered-on-and-logged-out', // Power on & not docked & no user logged in
    Docked = 'docked', // Walt docked & charging
    NoConnectionAssigned = 'no-connection-assigned', // Not reported in ACTIVE_WALT_TIME_MINUTES and still assigned
                                                         // (reporting last known location) - (currently just comparing locationUpdated to ACTIVE_WALT_TIME_MINUTES)
    NoConnectionUnassigned = 'no-connection-unassigned', // Not reported in ACTIVE_WALT_TIME_MINUTES and unassigned
    Unassigned = 'unassigned', // Power On and unassigned
}

export const WaltStatusTranslation: { [key in WaltStatus]: string } = {
    [WaltStatus.PoweredOnLoggedIn]: 'walt.status.powered-on-logged-in',
    [WaltStatus.PoweredOnLoggedOut]: 'walt.status.powered-on-logged-out',
    [WaltStatus.Docked]: 'walt.status.docked',
    [WaltStatus.NoConnectionAssigned]: 'walt.status.no-connection-assigned',
    [WaltStatus.NoConnectionUnassigned]: 'walt.status.no-connection-unassigned',
    [WaltStatus.Unassigned]: 'walt.status.unassigned',
};

export const WaltStatusToColor: { [key in WaltStatus]: string } = {
    [WaltStatus.PoweredOnLoggedIn]: css.brandingColors.W_GREEN,
    [WaltStatus.PoweredOnLoggedOut]: css.brandingColors.W_PURPLE,
    [WaltStatus.Docked]: css.brandingColors.W_DK_BLUE,
    [WaltStatus.NoConnectionAssigned]: css.brandingColors.W_ORANGE,
    [WaltStatus.NoConnectionUnassigned]: css.brandingColors.W_RED,
    [WaltStatus.Unassigned]: css.brandingColors.W_LT_GRAY,
};
