import { Injectable } from '@angular/core';
import { ImportedPerson, ImportedPersonUpdateRequest } from '../models/person-imported.model';
import { AlertService } from './alert.service';
import { HttpService } from './http.service';
import { BatchRequest, BatchResponse } from './batch.service';

@Injectable({
    providedIn: 'root',
})
export class PersonImportService {
    constructor(
        private httpService: HttpService,
        private alertsService: AlertService,
    ) { }

    static baseUrl = '/core/people';

    static url = (id?: string) => id ? `${PersonImportService.baseUrl}/${id}` : PersonImportService.baseUrl;

    async getImportedPerson(component, id: string): Promise<ImportedPerson> {
        return await this.httpService.get<ImportedPerson>(component, `${PersonImportService.url()}/imported/${id}`);
    }

    async getImportedPeople(component, batchId?: string): Promise<ImportedPerson[]> {
        try {
            return await this.httpService.get<ImportedPerson[]>(
                component,
                `${PersonImportService.url()}/imported-batches`,
                { batchId },
                null,
                (person: ImportedPerson) => `${person.firstName} ${person.lastName}`,
            );
        } catch (e) {
            this.alertsService.sendError({ error: e, messageKey: 'ERRORS.PEOPLE.GET' });
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    async updateImported(component, importData: ImportedPersonUpdateRequest) {
        return await this.httpService.put<ImportedPerson>(component, `${PersonImportService.url()}/imported`, importData);
    }

    async updateImportedCredentials(component, userUpdate: { id: string, email?: string, phone?: string, folderId: string }) {
        return await this.httpService.put<ImportedPerson>(component, `${PersonImportService.url()}/credentials`, userUpdate);
    }

    async bulkUpdate(component, users: ImportedPersonUpdateRequest[]) {
        const requests: BatchRequest[] = users.map((user) => {
            return {
                method: 'PUT',
                path: `${PersonImportService.url()}/imported`,
                post: user,
            };
        });
        const results = await this.httpService.post<BatchResponse[]>(component, '/batch', requests);
        let throwErr = false;
        const failedUsers = users;
        results.forEach(result => {
            if (result.status < 200 && result.status > 299) {
                console.error('Error updating users', result);
                throwErr = true;
            } else {
                const index = failedUsers.findIndex(user => user.id === result.data.id);
                failedUsers.splice(index, 1);
            }
        });
        if (throwErr) {
            this.alertsService.sendError({ messageKey: 'ERRORS.PEOPLE.UPDATE' });
        }
        return failedUsers;
    }

    async bulkInvite(component, users: ImportedPerson[]) {
        const requests: BatchRequest[] = users.map((user) => {
            const userToInvite = {
                ...user,
                facilities: user.facilities ? Object.keys(user.facilities).map(key => ({ facilityId: key, facilityGroup: user.facilities[key] }) ) : [],
            };
            return {
                method: 'POST',
                path: '/core/people/invite-new-user',
                post: userToInvite,
            };
        });
        const results = await this.httpService.post<BatchResponse[]>(component, '/batch', requests);
        let throwErr = false;
        const failedUsers = users;
        const sentUsers = [];
        results.forEach(result => {
            if (result.status < 200 && result.status > 299) {
                console.error('Error inviting users', result);
                throwErr = true;
            } else {
                const index = failedUsers.findIndex(user => user.id === result.data.id);
                failedUsers.splice(index, 1);
                sentUsers.push(result.data);
            }
        });
        if (throwErr) {
            this.alertsService.sendError({ messageKey: 'ERRORS.PEOPLE.UPDATE' });
        }
        return { sentUsers, failedUsers };
    }
}
