import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent } from 'components/alert/alert.component';

@Pipe({
    name: 'appErrorOrOk',
    standalone: true,
})
export class BannerErrorOrOk implements PipeTransform {
    transform(totalErrors: number, error?: string, ok?: string): string | undefined {
        return totalErrors > 0 ? error : ok;
    }
}

/**
 * Wrapper around the standard AlertComponent
 * that shows arrows for navigating between errors.
 */
@Component({
    selector: 'app-errors-banner',
    templateUrl: './errors-banner.component.html',
    styleUrls: ['./errors-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        AlertComponent,
        IconComponent,
        BannerErrorOrOk,
    ],
})
export class ErrorsBannerComponent {
    /** The total number of errors. */
    @Input() totalErrors = 0;
    /** The number of the current error. */
    @Input() currentError = 0;
    /** The title text when totalErrors > 0. */
    @Input() errorTitle?: string;
    /** The subtitle text when totalErrors > 0. */
    @Input() errorSubtitle?: string;
    /** The title text when totalErrors = 0. */
    @Input() okTitle?: string;
    /** The subtitle text when totalErrors = 0. */
    @Input() okSubtitle?: string;

    @Output() nextError = new EventEmitter<void>();
    @Output() previousError = new EventEmitter<void>();
}
