import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { mapValues, merge, pickBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AppIcon, SvgIcon } from 'weavix-shared/models/icon.model';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
    ],
})
export class IconComponent implements OnChanges {
    @Input() svgFile: string | SvgIcon;
    @Input() faIcon: string;
    @Input() matIcon: string;
    @Input() img: string;
    @Input() flagIcon: string;
    @Input() color: string;
    @Input() altText: string;
    @Input() fontSize: string;
    @Input() width: string | number;
    @Input() height: string | number;
    @Input() borderRadius: string;
    @Input() inline: boolean;
    @Input() tooltip: string;
    @Input() centered: boolean = false;

    @Input() icon: AppIcon = {};

    mergedOptions$ = new BehaviorSubject<AppIcon>({});
    svgFilePath$ = new BehaviorSubject<string | null>(null);

    private readonly defaultIcon: Partial<AppIcon> = { inline: false };
    private readonly svgIconPath: string = '/assets/img/svg-icons';

    ngOnChanges(changes: SimpleChanges) {
        const icon = merge(
            { ...this.defaultIcon },
            this.icon,
            mapValues(pickBy(changes, (_value, key) => key !== 'icon'), x => x.currentValue || null),
        );
        this.mergedOptions$.next(icon);
        this.icon = icon;

        if (icon.svgFile) {
            this.svgFilePath$.next(Object.values(SvgIcon).includes(icon.svgFile as SvgIcon) ? `${this.svgIconPath}/${icon.svgFile}.svg` : icon.svgFile);
        }
    }
}
