import { BehaviorSubject, Observable } from 'rxjs';

type DestroyerFn = () => any;

// Allows multiple observers to subscribe to a single source and debounces the unsubscribe to avoid churn
export class LazyObservable<T> extends Observable<T> {
    constructor(
        private readonly unsubscribeTimeout: number,
        private readonly subscriber: () => DestroyerFn,
    ) {
        super(obs => {
            if (this._timeout) {
                clearTimeout(this._timeout);
                this._timeout = null;
            } else if (!this._subscribed) {
                this._destroyer = this.subscriber();
            }
            this._subscribed++;
            const sub = this._value$.subscribe(val => obs.next(val));
            return () => {
                sub.unsubscribe();
                this._subscribed--;
                if (!this._subscribed) {
                    this._timeout = setTimeout(() => {
                        this._timeout = null;
                        this._destroyer?.();
                    }, this.unsubscribeTimeout);
                }
            };
        });
    }

    private _destroyer?: DestroyerFn;
    private _subscribed = 0;
    private readonly _value$ = new BehaviorSubject<T | undefined>(undefined);
    private _timeout: any;

    next(val: T) {
        if (val !== this._value$.value) this._value$.next(val);
    }

    toJSON() {
        return JSON.stringify(this._value$.value);
    }
}
