<div class="wrapper" [ngClass]="{'light': lightTheme }">
    <div class="content-wrapper">
        <ng-container *ngIf="loading">
            <app-loading></app-loading>
        </ng-container>

        <ng-container *ngIf="!loading">
            <app-filter-header
                [titleKey]="title"
            ></app-filter-header>

            <div class="scroll-wrapper filter-results">
                <!-- <app-date-range-picker
                        *ngIf="!hideDateRange"
                        [(ngModel)]="dateRange"
                        [disabled]="isDateRangeDisabled"
                        (ngModelChange)="onDateRangeChanged($event)"
                ></app-date-range-picker> -->

                <ng-container *ngFor="let res of filterResultsSorted">
                    <ng-container *ngTemplateOutlet="resItem; context: {parent: {children: filterResults}, res, resIndex: 0}"></ng-container>
                </ng-container>
            </div>
        </ng-container>

        <div class="actions">
            <button class="clear-filters" (click)="handleClearFilters()">
                {{ clearText | translate }}
            </button>
        </div>
    </div>
</div>

<ng-template #resItem let-res="res" let-resIndex="resIndex" let-parent="parent">
    <app-filter-row-display [result]="res" [index]="resIndex" (selectEmit)="handleRowClick(parent, $event, false, resIndex)" (clickEmit)="handleRowClick(parent, $event, true, resIndex)"></app-filter-row-display>

    <ng-container *ngIf="res?.children && res.selected">
        <ng-container *ngFor="let child of getSortedChildren(res)">
            <div class="children">
                <ng-container *ngTemplateOutlet="resItem; context: {parent: res, res: child, resIndex: resIndex + (res.depth ? 2 : 1)}"></ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
