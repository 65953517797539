<div class="table-row-wrapper" 
    [class.teams]="teamsApp"
    [matTooltip]="row.disabledRowText ? row.disabledRowText : ''"
    matTooltipPositionAtOrigin="true">
    <div class="table-row"
        [ngClass]="[row.clickable ? 'table-row-clickable' : '', row.highlighted ? 'table-row-highlighted' : '', lightTheme ? 'light' : '', row.class ? row.class : '']"
        [ngStyle]="{'backgroundColor': backgroundColor}"
        [class.can-expand]="canExpand"
        [class.has-expanded]="hasExpanded"
        [class.disabled]="row.disabledRowText"
    >
        <div *ngIf="row.rowPrefix" class='marker' [ngClass]="[row.marked ? 'marker-orange' : '']">&nbsp;</div>
        <div *ngIf="row.subTable" class="table-col checkbox" (click)="handleShowSubRowItems($event); false">
            <app-icon *ngIf="row.subTable.show" faIcon="fas fa-chevron-up"></app-icon>
            <app-icon *ngIf="!row.subTable.show"  faIcon="fas fa-chevron-down"></app-icon>
        </div>

        <div *ngIf="row.checkbox && !row.disabledRowText" class="table-col checkbox">
            <mat-checkbox (click)="select($event); false" [checked]="row.selected"></mat-checkbox>
        </div>

        <div *ngIf="row.locked || row.disabledRowText" class="table-col checkbox">
            <app-icon faIcon="fas fa-lock-alt"></app-icon>
        </div>

        <div *ngIf="iconFixedWidth || icon" class="table-col checkbox icon">
            <app-icon *ngIf="icon" [icon]="icon"></app-icon>
        </div>

        <div class="table-col"
            *ngFor="let item of row.items"
            [ngClass]="[item.clickable ? 'clickable' : '', item.class ? item.class : '']"
            (click)="item.clickable && column($event, item)"
            [ngStyle]="{'min-width.px': item.minWidth, 'max-width.px': item.maxWidth}"
        >
            <app-loading *ngIf="item.pending" class="transparent mR5" [loading]="item.pending" [diameter]="20" [inline]="true"></app-loading>

            <app-icon *ngIf="item.prefix" [color]="item.prefix.color" [icon]="item.prefix" class="prefix" (click)="item.prefix.clickable && prefix($event, item)"></app-icon>

            <ng-container>
                <div class="col-val"
                    [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)"
                    *ngIf="item.value === undefined || item.value === null || item.value === ''"
                >
                    -
                </div>
                <ng-container [ngSwitch]="colKey[item.colKey].type" *ngIf="item.value !== undefined && item.value !== null">
                    <div class="col-val"
                        [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)"
                        *ngSwitchCase="rowItemType.text"
                        [matTooltip]="item.tooltip"
                        [style]="!!row.parentId ? 'padding-left: 2em;' : null"
                    >
                        <ng-container *ngIf="!!item.maxLines">
                            <ng-container *ngIf="!rowItemExpanded[item.colKey]">
                                <span [ngClass]="['clamp-'+item.maxLines]" [innerHTML]="colKey[item.colKey]?.translate ? (item.value | translate) : item.value"></span>
                                <span *ngIf="!!item.value" class="col-val-expander mL5 blue-lt" [class.loading-dots]="rowItemExpanding[item.colKey]" (click)="handleExpandRowItem(item, true)">{{ (rowItemExpanding[item.colKey] ? 'generics.loading' : 'generics.read-more') | translate }}</span>
                            </ng-container>
                            <ng-container *ngIf="rowItemExpanded[item.colKey]">
                                <span>
                                    <span [innerHTML]="colKey[item.colKey]?.translate ? (item.value | translate) : item.value"></span>
                                    <span *ngIf="!!item.value" class="col-val-expander mL5 blue-lt" (click)="handleExpandRowItem(item, false)">{{ 'generics.read-less' | translate }}</span>
                                </span>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!item.maxLines">
                            <span [innerHTML]="colKey[item.colKey]?.translate ? (item.value | translate) : item.value"></span>
                        </ng-container>
                    </div>
    
                    <div class="col-val"
                        [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)"
                        *ngSwitchCase="rowItemType.number"
                    >
                        {{item.value}}
                    </div>
    
                    <div class="col-val"
                    [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)"
                        *ngSwitchCase="rowItemType.date"
                    >
                        {{item.value ? (item.value | date:'short') : '-'}}
                    </div>
    
                    <div class="col-val"
                        [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)"
                        *ngSwitchCase="rowItemType.time"
                    >
                        {{item.value ? (item.value | date:'shortTime') : '-'}}
                    </div>
    
                    <div class="col-val" [ngClass]="{'jcC': item.centered}" *ngSwitchCase="rowItemType.toggle">
                        <mat-slide-toggle [color]="'accent'" [checked]="item.value" (change)="column(null, item)"></mat-slide-toggle>
                    </div>
    
                    <div class="col-val"
                        [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)"
                        matTooltip="{{item.value | date:'short'}}"
                        matTooltipPosition="after"
                        *ngSwitchCase="rowItemType.timeAgo"
                    >
                        {{item.value | timeAgo}}
                    </div>
    
                    <div class="col-val checkbox" [ngClass]="{'jcC': item.centered}" *ngSwitchCase="rowItemType.checkbox">
                        <mat-checkbox [checked]="item.value" *ngIf="item.value !== undefined"></mat-checkbox>
                    </div>
    
                    <div class="col-val person-col"
                        [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)"
                        *ngSwitchCase="rowItemType.avatarPerson"
                    >
                        <ng-container *ngTemplateOutlet="avatarPersonColType; context: {value: item.value}"></ng-container>
                    </div>
    
                    <div class="col-val person-col"
                        [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)"
                        *ngSwitchCase="rowItemType.image"
                    >
                        <ng-container *ngIf="item.value.image">
                            <ng-container *ngTemplateOutlet="imageColType; context: {value: item.value.image}"></ng-container>
                        </ng-container>
    
                        <ng-container *ngIf="!item.value.image && item.value.fallbackText">
                            <ng-container *ngTemplateOutlet="textColType; context: {value: item.value.fallbackText}"></ng-container>
                        </ng-container>
                    </div>
    
                    <div class="icon" [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)" *ngSwitchCase="rowItemType.icon">
                        <app-icon *ngIf="item.value" [icon]="item.value"></app-icon>
                    </div>
    
                    <div class="col-val" [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)" *ngSwitchCase="rowItemType.color">
                        <div class="color" [ngStyle]="{'background-color': item.value}"></div>
                    </div>
    
                    <div class="col-val" [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)" *ngSwitchCase="rowItemType.duration">
                        {{ item.value.label }}
                    </div>
    
                    <div class="col-val" [ngStyle]="item.rowItemNgStyle ?? item.rowItemNgStyleCb?.(row)" *ngSwitchCase="rowItemType.percent">
                        {{ item.value | percent:'1.2' }}
                    </div>
    
                    <div class="col-val folder-back" *ngSwitchCase="rowItemType.folderBack">
                        <span>{{ "table.folders.back" | translate }}</span>
                        <span class="separator"> | </span>
                        <app-icon faIcon="fas fa-folder" class="icon"></app-icon>
                        {{ item.value }}
                    </div>
                </ng-container>
    
                <app-icon *ngIf="item.postfix" [icon]="item.postfix" class="postfix" (click)="item.postfix.clickable && postfix($event, item)"></app-icon>
            </ng-container>
        </div>

        <div class="table-col edit-col" *ngIf="dummyEdits.length || row.edits.length">
            <div *ngIf="!teamsApp" class="table-col-icons">
                <button class="invisible" *ngFor="let d of dummyEdits"></button>
                <div *ngIf="!canMove && inTableThatIsMoveable"><button class="invisible"></button></div>
                <ng-container *ngFor="let e of row.edits">
                    <button *ngIf="e.invisible" class="invisible"></button>
                    <div *ngIf="!e.invisible" [matTooltip]="e.title | translate">
                        <button (click)="edit($event, e)" [ngClass]="{disabled: e.disabled}">
                            <i [ngClass]="e.icon"></i>
                        </button>
                    </div>
                </ng-container>
                <div *ngIf="canMove" matTooltip="{{moveTooltip}}">
                    <button
                        (click)="chooseFolder($event)">
                        <app-icon faIcon="fas fa-grip-horizontal"></app-icon>
                    </button>
                </div>
                <div *ngIf="row.isFolder" class="flexRow jcC aiC">
                    <button *ngIf="row.rowSuffix" class="static flexRow table-col-suffix"><app-icon [faIcon]="row.rowSuffix.icon(row).faIcon"></app-icon> {{ row.rowSuffix.text(row) }}</button>
                    <button *ngIf="!row.rowSuffix" class="invisible"></button>
                </div>
            </div>
            <div *ngIf="teamsApp" class="table-col edit-col flexRow jcC aiC" [matMenuTriggerFor]="tableEdits" (click)="column($event, null, true)">
                <button class="p0"><app-icon faIcon="fas fa-ellipsis-h"></app-icon></button>
                <mat-menu #tableEdits="matMenu">
                    <div *ngFor="let e of row.edits" mat-menu-item class="dropdown-item" [class.hidden]="e.show && e.show(row) === false" (click)="edit($event, e)" [ngClass]="{disabled: e.disabled}">
                        <i class="icon" [ngClass]="e.icon"></i><div>{{ e.title | translate }}</div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>

    <div [ngStyle]="{'paddingLeft': subTableLeftOffset ? subTableLeftOffset : 0}"
        class="table-row-wrapper sub-table"
        *ngIf="row?.subTable?.show && row?.subTable?.rows && row?.subTable?.options"
    >
        <app-table [rows]="row?.subTable?.rows" [options]="row?.subTable?.options"></app-table>
    </div>

    <div class="total-row-count" *ngIf="totalRowConfig?.show">
        <p>{{totalRowConfig.count}} {{'table.total' | translate}}</p>
    </div>
</div>

<ng-template #textColType let-value="value" let-translate="translate">
    <div class="text-row-item">
        <span *ngIf="translate" [innerHTML]="value | translate"></span>
        <span *ngIf="!translate" [innerHTML]="value"></span>
    </div>
</ng-template>

<ng-template #avatarPersonColType let-value="value">
    <app-avatar-person
        [personId]="value.id"
        [showAvailability]="false"
        [showCompanyRing]="false"
        [avatarInput]="{
            img: value.avatarFile,
            height: value.avatar?.height ?? 30,
            width: value.avatar?.width ?? 30,
            hasBorders: value.avatar?.hasBorders ?? true,
            placeholder: {
                'icon': 'fas fa-user',
                'iconColor': 'white'
            }
        }"
    ></app-avatar-person>

    <div class="person-info">
        <p class="name" *ngIf="value?.name">{{value.name}}</p>
        <p class="name" *ngIf="!value?.name && value?.fullName">{{value.fullName}}</p>
    </div>
</ng-template>

<ng-template #imageColType let-value="value">
    <div class="image-container" *ngIf="value">
        <img [src]="value">
    </div>
</ng-template>