import { Subject } from 'rxjs';

import { Utils } from '../utils/utils';

import { ConfinedSpaceEvent } from '@weavix/models/src/item/confined-space';
import { ItemLocationUpdate, ItemType } from '../models/item.model';
import { MapItem } from '../models/weavix-map.model';

import { PermissionAction } from '../permissions/permissions.model';
import { ConfinedSpaceService } from './confined-space.service';
import { ItemService } from './item.service';
import { ProfileService } from './profile.service';
import { StateServiceBase, StateUpdate } from './state-base.service';

export class ItemStateService extends StateServiceBase {
    items: Map<string, MapItem> = new Map();
    itemTypes: Map<string, ItemType> = new Map();

    items$: Subject<StateUpdate<MapItem>> = new Subject();
    itemTypes$: Subject<StateUpdate<ItemType>> = new Subject();
    itemLocationUpdates$: Subject<{ itemId: string, locationUpdate: ItemLocationUpdate }> = new Subject();

    confinedSpaceEvents$: Subject<ConfinedSpaceEvent> = new Subject();

    constructor(
        private itemService: ItemService,
        private confinedSpaceService: ConfinedSpaceService,
        private profileService: ProfileService,
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewSites, facilityId)) return;

        const [itemTypes, items] = await Promise.all([
            this.itemService.getAllTypes(c, tags, false),
            this.itemService.getItems(c, facilityId, true, undefined, tags, false),
        ]);
        this.items = Utils.toMap(items.filter(x => this.profileService.hasPermission(PermissionAction.ViewSites, facilityId, x.folderId)));
        this.itemTypes = Utils.toMap(itemTypes);
    }

    async stop() {
        super.stop();
        this.items.clear();
        this.itemTypes.clear();
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string) {
        return [
            this.profileService.hasPermission(PermissionAction.ViewSites, facilityId)
                && (await this.itemService.subscribeUpdates(c)).subscribe(async (result) => {
                    if (result.payload.items) {
                        const itemMap = result.payload.items;
                        const filtered = {};
                        Object.keys(itemMap).forEach(id => {
                            if (!itemMap[id] || this.profileService.hasPermission(PermissionAction.ViewSites, facilityId, itemMap[id].folderId)) {
                                filtered[id] = itemMap[id];
                            }
                        });
                        this.updateFromMap(this.items, filtered, this.items$);
                    } else if (result.payload.itemTypes) {
                        const itemTypeMap = result.payload.itemTypes;
                        this.updateFromMap(this.itemTypes, itemTypeMap, this.itemTypes$);
                    }
                }),
            this.profileService.hasPermission(PermissionAction.ViewConfinedSpaces, facilityId)
                && (await this.confinedSpaceService.subscribeConfinedSpace(c)).subscribe(async (result) => {
                    if (result.payload.wilmaEvent && (this.profileService.hasPermission(PermissionAction.ViewConfinedSpaces, facilityId))) {
                        this.confinedSpaceEvents$.next(result.payload);
                    }
                }),
            this.profileService.hasPermission(PermissionAction.ViewSites, facilityId) &&
                (await this.itemService.subscribeFacilityLocationUpdates(c, facilityId)).subscribe(async (result) => {
                    if (!this.items?.has(result.replacements[1])) return;
                    this.itemLocationUpdates$.next({ itemId: result.replacements[1], locationUpdate: result.payload });
                }),
        ];
    }
}
