import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appPreventBlur]',
    standalone: true,
})
export class PreventBlurDirective {
    @HostListener('mousedown', ['$event'])
    public onMouseDown(): boolean {
        return false;
    }
}
