import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AuthService } from 'console/app/core/services/auth.service';
import { TranslationService } from 'console/app/core/services/translation/translation.service';
import { AppRoute } from 'weavix-shared/models/navigation.model';

import { UserProfile } from 'weavix-shared/models/user.model';

import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-settings-menu',
    templateUrl: './settings-menu.component.html',
    styleUrls: ['./settings-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
    ],
})
export class SettingsMenuComponent {
    @Input() profileInput: UserProfile;

    notificationDisplay: boolean;
    displayShowMoreLink: boolean = false;

    constructor(
        private translationService: TranslationService,
        private authService: AuthService,
        private router: Router,
    ) { }

    toggleLanguage(): void {
        if (this.translationService.getLanguage() === 'en') this.translationService.setLanguage('es');
        else this.translationService.setLanguage('en');
    }

    getCurrentLanguage(): string {
        return this.translationService.getLanguageString();
    }

    async logout() {
        await this.authService.logout(this);
        this.router.navigateByUrl('/login/authorize');
    }

    public navigateToProfile(): void {
        const routeSegments: string[] = [
            AppRoute.Profile,
        ];

        this.router.navigate(routeSegments);
    }

}
