import { sleep } from '@weavix/utils/src/sleep';

export class PriorityQueue {
    private timeout = 30000;
    private running: Array<Promise<any>> = [];

    async run<T>(fn: () => Promise<T>): Promise<T> {
        const promise = fn();
        const withTimeout = Promise.race([promise, sleep(this.timeout)]);
        this.running.push(withTimeout);
        try {
            await withTimeout;
        } finally {
            this.running.splice(this.running.indexOf(withTimeout), 1);
        }
        return await promise;
    }

    async queue<T>(fn: () => Promise<T>): Promise<T> {
        while (true) {
            await sleep(50);
            if (!this.running.length) break;
            await Promise.all(this.running);
        }
        return await fn();
    }
}