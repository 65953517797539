<ng-container *ngIf="bars$ | async as bars">
    <div
        #loadDiv
        *ngIf="bars.length < 1"
        class="loading-overlay"
        [style.backgroundColor]="backgroundColor"
        [ngClass]="{ 'loading': loading, 'hide': !loading, 'inline': inline, 'light': theme === 'light', 'dark': theme === 'dark', 'transparent': theme === 'transparent' }"
    >
        <app-logo-spinner class="spinner" [size]="spinnerDiameter$ | async" [slow]="false" *ngIf="loading"></app-logo-spinner>
    </div>

    <div
        #loadBarsDiv
        class="loading-overlay bars"
        [ngClass]="loading ? 'bar-loading' : 'hide'"
        *ngIf="bars.length > 0"
    >
        <div class="bar-container" *ngIf="loading">
            <div class="bar" *ngFor="let b of bars">
                <span class="bar-progress"></span>
            </div>
        </div>
    </div>
</ng-container>
