import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appPreventInput]',
    standalone: true,
})
export class PreventInputDirective {
    @HostListener('keydown', ['$event'])
    onInputChange(event: Event) {
        event.preventDefault();
    }
}
