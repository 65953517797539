<app-modal *ngIf="folderEditorModal" [modalInput]="folderEditorModal" (modalCloseOutput)="handleModalClose($event)" class="folder-editor">
    <form class="form flexGrow1" [formGroup]="form">
        <div class="fields-container flexGrow1">
            <div>
                <app-input
                    #nameInput
                    label="folders.add-folder.name"
                    formControlName="name"
                    [value]="form.controls.name.value"
                    [required]="true"
                    [autofocus]="true"
                    errorClass="block"
                ></app-input>
            </div>
        </div>

        <div class="actions">
            <app-form-buttons
                [submitDisabled]="form.invalid || form.pristine"
                (submitOutput)="submit()"
                (cancelOutput)="cancel()"
            ></app-form-buttons>
        </div>
    </form>
</app-modal>

<app-modal *ngIf="folderExistsModal" [modalInput]="folderExistsModal" (modalCloseOutput)="handleFolderExistsModalClose($event)">
    <app-name-exists
        [existingName]="nameValue"
        [suggestedPrefix]="suggestedFolderName?.replace(nameValue, '')"
    />
</app-modal>