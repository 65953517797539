import { memoize } from 'lodash';
import { IndexedStorage, NativeStorage, QueryableStorage, SyncModule } from '@weavix/sync';
import { PartitionEventStorage } from '@weavix/sync/src/storage/partition-event-storage';
import { of } from 'rxjs';

function indexMessage(cm: any) {
    return `${cm.channelId}|${cm.date instanceof Date ? cm.date.toISOString() : cm.date}`; // <channel id>|<date>
}

function indexMessagePartition(index: string) {
    const pipe = index.indexOf('|');
    return index.substring(0, pipe + 11); // Just by day
}

function indexId(value: any) {
    return value.id;
}

function indexIdPartition(index: string) {
    return index.substring(index.length - 1);
}

type CreateStorage = new (name: string) => NativeStorage;
let storage: CreateStorage = function() { throw new Error('Call configureStorage first'); } as any;
export function configureStorage(creator: CreateStorage) {
    storage = creator;
}

export interface StorageOptions {
    name: string;
    topics?: string | string[];
    readonly?: boolean;
}

// Forcing storage to refresh on version change
// when adding/changing the mobx model, add the storage name to the object and increment with -v#
const STORAGE_VERSIONS = {
    'users': 'users-v2',
};

const getStorageVersionName = (name: string) => {
    if (STORAGE_VERSIONS[name]) return STORAGE_VERSIONS[name];
    else return name;
};

export const getAccountStorage = memoize(<T extends { id: string; }>(options: string | StorageOptions) => {
    if (typeof options === 'string') options = { name: options };
    const { name, topics, readonly } = options;

    let userTopic = false;
    let accountTopic = false;
    let staticTopic = false;
    let sync = false;
    let priority = false;
    let simulcast = false;

    switch (name) {
        case 'statuses':
        case 'notifications':
        case 'settings':
        case 'meetings':
            userTopic = true;
            break;
        case 'channels':
        case 'channel-preferences':
        case 'channel-messages':
            userTopic = true;
            simulcast = true;
            break;
        case 'companies':
            accountTopic = true;
            staticTopic = true;
            break;
        case 'users':
            userTopic = true;
            accountTopic = true;
            break;
        case 'connections':
            userTopic = true;
            break;
        default:
            sync = true;
            accountTopic = true;
            break;
    }
    let indexFn = undefined;
    let indexPart = undefined;
    switch (name) {
        case 'channel-messages':
            indexFn = indexMessage;
            indexPart = indexMessagePartition;
            break;
        case 'people':
        case 'users':
            priority = true;
        /** FALLTHROUGH */
        case 'facility-people':
            indexFn = indexId;
            indexPart = indexIdPartition;
            break;
    }

    const lazy = !priority && accountTopic;
    const newStorage = new storage(getStorageVersionName(name));
    const indexedStorage = new IndexedStorage<T>(newStorage, indexFn, indexPart, readonly);
    class Module extends SyncModule<T> {
        storage = indexedStorage;
        // specific topics do no store events since the sync is incomplete
        eventStorage = !!topics || readonly ? null : new PartitionEventStorage(newStorage, indexedStorage);
        userId: string;

        constructor() {
            super(lazy, simulcast);
        }
    
        watchPartitions() {
            if (topics) return of(Array.isArray(topics) ? topics : [topics]);
            return this.watchUser(user => {
                if (!user) return null;
                this.userId = user.id;
                return (staticTopic ? ['root'] : [])
                    .concat(userTopic ? [user.id] : [])
                    .concat(accountTopic ? user.accounts.map(x => x.id) : []);
            });
        }
    
        getTopic(topic: string) {
            if (topics) return topic;
            return topic === 'root' ? `account/${name}`
                : topic === this.userId ? `user/${topic}/${name}`
                : `account/${topic}${sync ? '/sync' : ''}/${name}`;
        }
    }
    class Storage extends QueryableStorage<T> {
        static inst: Storage;
        static Instance = () => {
            if (!this.inst) this.inst = new Storage(new Module());
            this.inst.start();
            return this.inst;
        };
        private started = false;

        constructor(private module: Module) {
            super(module.storage);
        }

        start() {
            if (!this.started) {
                this.started = true;
                this.module.start();
            }
        }

        close() {
            if (this.started) {
                this.started = false;
                this.module.close(false);
                this.module.stop();
            }
        }
    }
    return Storage;
}, (...args) => JSON.stringify(args));
