import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'components/icon/icon.component';
import { AppIcon } from 'weavix-shared/models/icon.model';

/**
 * The themes for the alert component.
 * From the weavix Design System 1.0.
 */
export const ALERT_TYPES = [
    'error',
    'warning',
    'ok',
    'info',
] as const;
/**
 * A theme for the alert component.
 * From the weavix Design System 1.0.
 */
export type AlertType = typeof ALERT_TYPES[number];

/**
 * Provides themed messages with an icon and optional close button.
 * From the weavix Design System 1.0.
 * 
 * @example
 * Wrap any content in the alert component to display a themed message.
 * ```html
 * <app-alert type="info">
 *   {{ message }}
 * </app-alert>
 * ```
 * By default, the alert is a block element,
 * but it can be changed to flex with normal CSS.
 * ```html
 * <app-alert style="display: flex; flex-direction: row;">
 *   <strong>{{ title }}</strong>
 *   <div>{{ message }}</div>
 * </app-alert>
 * ```
 * Manage the visibility of the alert:
 * ```html
 * <!-- event handler -->
 * <app-alert [dismissible]="true" (closed)="handleClosed()">
 *   {{ message }}
 * </app-alert>
 * <!-- two-way binding -->
 * <app-alert [dismissible]="true" [(isOpen)]="isVisible">
 *   {{ message }}
 * </app-alert>
 * ```
 */
@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,

        IconComponent,
    ],
    host: {
        'role': 'alert',
        '[class]': '"alert alert-" + type',
        '[class.alert-has-icon]': '!!icon',
        '[class.alert-has-dismiss]': 'dismissible',
    },
})
export class AlertComponent {
    /**
     * The theme of the alert.
     * Defaults to `error`.
     */
    @Input() type: AlertType = 'error';
    /**
     * The icon to display in the alert.
     * Defaults to an exclamation mark in a circle.
     * Set to `null` to hide the icon.
     */
    @Input() icon: AppIcon | null = { 'faIcon': 'fa-light fa-circle-exclamation' };
    /**
     * Whether to show a close button.
     * Defaults to `false`.
     */
    @Input() dismissible = false;
    /**
     * Whether the alert is visible.
     * Defaults to `true`.
     */
    @Input() isOpen = true;

    /**
     * Emits when the close button is clicked.
     * Equivalent to `(isOpenChange)="if ($event === false) { ... }"`.
     */
    @Output() closed = new EventEmitter<void>();
    /**
     * Two-way binding for `isOpen`.
     * For only close events, use `(closed)`.
     */
    @Output() isOpenChange = new EventEmitter<boolean>();

    constructor(
        private readonly cdr: ChangeDetectorRef,
    ) {}

    close() {
        if (!this.isOpen) return;

        this.isOpen = false;
        this.cdr.markForCheck();
        this.isOpenChange.emit(false);
        this.closed.emit();
    }
}
