
<div
    class="dropdown-container"
    [ngStyle]="{'width.px': customWidth, 'margin-top.px': customMarginTop, 'margin-bottom.px': customMarginBottom}"
    [class.teams]="teamsApp"
>
    <div *ngIf="showLabel && floatedLabel" class="label-container">
        <span class="input-label" [class.required]="required || showAsterisk">{{floatedLabel | translate}}</span>
        <app-tooltip *ngIf="tooltip" [options]="tooltip" [doTranslate]="doTranslate"></app-tooltip>
    </div>
    <div [matTooltip]="disabledText" matTooltipPositionAtOrigin="true">
        <mat-form-field
            class="dropdown"
            *ngIf="dropdownItems"
            [ngStyle]="{'width.px': customWidth}"
            [ngClass]="{
                'disabled': readonly || formControl?.disabled,
                'clickable': isClickable,
                'white': white,
                'dkGray': dkGray,
            }"
        >
            <mat-label *ngIf="dropdownLabel">{{dropdownLabel | translate}}</mat-label>

            <mat-select
                disableOptionCentering 
                #matSelect
                [id]="selectId"
                [(value)]="selectedItem"
                (selectionChange)="onSelectionChange()"
                (openedChange)="focusFilter($event)"
                (blur)="onTouched()"
                [required]="required"
                [ngClass]="{
                    'disabled': readonly || formControl?.disabled,
                    'clickable': isClickable,
                    'light': lightTheme
                }"
                style="overflow: hidden;"
                [panelClass]="panelClass"
            >
                <mat-select-trigger>
                    <div class="selected-item" [class.placeholder]="selectedItem?.placeholder" [ngStyle]="{'width.px': customWidth * .8}">
                        <div matPrefix
                            class="avatar selected-image" 
                            *ngIf="selectedItem?.imgUrl || selectedItem?.faIcon || selectedItem?.matIcon || selectedItem?.flagIcon"
                            >
                            <app-icon
                                [img]="selectedItem.imgUrl"
                                [faIcon]="selectedItem.faIcon"
                                [matIcon]="selectedItem.matIcon"
                                [altText]="selectedItem.imgAltText"
                                [flagIcon]="selectedItem.flagIcon"
                                [ngClass]="{'flag-icon': selectedItem?.flagIcon}"
                            ></app-icon>
                        </div>

                        <span class="selected-label" *ngIf="doTranslate">
                            {{ selectedItem?.label | translate }}
                        </span>
                        <span class="selected-label" *ngIf="!doTranslate">
                            {{ selectedItem?.label || '&nbsp;' }}
                        </span>
                    </div>
                </mat-select-trigger>

                <div class="dropdown-item text-input" [ngStyle]="{'width.px': customWidth}" [class.teams]="teamsApp">
                    <div class="inline-loading" *ngIf="isLoading && showLoading">
                        <app-loading [loading]="isLoading" [diameter]="25" [inline]="true" [theme]="'transparent'"></app-loading>
                    </div>

                    <input
                        matInput
                        #filterInput
                        type="text"
                        id="dropdown-filter"
                        [ngClass]="{'white': white, 'disabled': searchDisabled, 'light': lightTheme, 'dkGray': dkGray}"
                        [disabled]="searchDisabled"
                        [(ngModel)]="filterValue"
                        (ngModelChange)="filterList($event)"
                        (keydown)="preventSpaceBar($event)"
                        (blur)="onFilterBlur($event)"
                        [placeholder]="placeHolderText"
                        autocomplete="off"
                    >
                </div>

                <mat-option *ngIf="filteredInput | appDropdownIsEmpty" disabled>
                    {{ 'generics.empty-options' | translate }}
                </mat-option>
        
                <mat-option
                    *ngIf="showAdd && hasAdd && isInputFilterValueValid && (filterValue || dropdownItems.length === 0)"
                    class="dropdown-mat-option"
                    [class.teams]="teamsApp"
                    [ngClass]="{ 'white': white, 'dkGray': dkGray }"
                    [value]="hasAdd"
                    [ngStyle]="{
                        'width.px': customWidth,
                        'light': lightTheme
                    }"
                >
                    <div id="add-dropdown-item" class="dropdown-item" [class.teams]="teamsApp">
                        <div class="avatar dropdown-item-img" *ngIf="hasAdd.imgUrl || hasAdd.faIcon || hasAdd.matIcon">
                            <app-icon
                                [img]="hasAdd.imgUrl"
                                [faIcon]="hasAdd.faIcon"
                                [matIcon]="hasAdd.matIcon"
                                [altText]="hasAdd.imgAltText"
                            ></app-icon>
                        </div>

                        <p class="dropdown-item-label add">{{filterValue}} ({{hasAdd.label | translate}})</p>
                    </div>
                </mat-option>
                
                <div
                    *ngFor="let group of filteredInput"
                    class="group-container"
                    [ngClass]="{ 
                        'padding': group.label,
                        'hidden': isLoading || !isInputFilterValueValid
                    }"
                >
                    <div class="group-label" *ngIf="group.label">
                        {{group.label | translate}}
                    </div>
                    
                    <mat-option
                        *ngFor="let item of group.items; trackBy: trackDropdownItemFn"
                        class="dropdown-mat-option"
                        [ngClass]="{
                            'placeholder': item.placeholder,
                            'white': white,
                            'bottom-spacing': item.subLabel,
                            'dkGray': dkGray
                        }"
                        [class.teams]="teamsApp"
                        [value]="item"
                        [disabled]="!!item.disabled"
                        [ngStyle]="{
                            'width.px': customWidth,
                            'light': lightTheme
                        }"
                    >
                        <div class="dropdown-item" [class.teams]="teamsApp">
                            <div class="main">
                                <div class="avatar dropdown-item-img" *ngIf="item.imgUrl || item.faIcon || item.matIcon || item.flagIcon">
                                    <app-icon
                                        [img]="item.imgUrl"
                                        [faIcon]="item.faIcon"
                                        [matIcon]="item.matIcon"
                                        [altText]="item.imgAltText"
                                        [flagIcon]="item.flagIcon"
                                    ></app-icon>
                                </div>

                                <div class="dropdown-label-container">
                                    <div class="dropdown-item-label" *ngIf="doTranslate">{{ item.label | translate}}</div>
                                    <div class="dropdown-item-label" *ngIf="!doTranslate">{{ item.label }}</div>
                                    <div class="dropdown-sub-label" *ngIf="item.subLabel && !doTranslate">{{ item.subLabel }}</div>
                                    <div class="dropdown-sub-label" *ngIf="item.subLabel && doTranslate">{{ item.subLabel | translate }}</div>
                                </div>

                                <div class="rightLabels" *ngIf="item.rightKey || item.rightImgUrl || item.rightFaIcon || item.rightMatIcon">
                                    <div class="dropdown-sub-label pR10" *ngIf="item.rightKey">{{ item.rightKey | translate }}</div>
                                    <app-icon
                                        [img]="item.rightImgUrl"
                                        [faIcon]="item.rightFaIcon"
                                        [matIcon]="item.rightMatIcon"
                                        [altText]="item.rightImgAltText"
                                    ></app-icon>
                                </div>
                            </div>
                        </div>
                    </mat-option>
                </div>

                <mat-option
                    *ngIf="showHasMoreIndicator"
                    class="dropdown-mat-option"
                    [class.teams]="teamsApp"
                    [disabled]="true"
                    [ngClass]="{
                        'hidden': !isInputFilterValueValid
                    }"
                >
                    <div class="dropdown-item" [class.teams]="teamsApp">
                        <div class="main">
                            <p class="dropdown-item-label">{{ 'shared.generics.has-more' | translate}}...</p>
                        </div>
                    </div>
                </mat-option>

            </mat-select>
            <ng-content></ng-content>
        </mat-form-field>
    </div>
    <div *ngIf="showErrors" class="error-container" [ngClass]="[errorClass ? errorClass : '']">
        <mat-error *ngIf="formControl?.hasError('required') && (formControl?.touched || !requireTouchedForError)" [innerHTML]="'formValidation.required' | translate:{ label: ((errorLabel ? errorLabel : floatedLabel ? floatedLabel : dropdownLabel) | translate) }"></mat-error>
        <mat-error *ngIf="formControl?.hasError('custom') && (formControl?.touched || !requireTouchedForError)" [innerHTML]="formControl.errors['custom'] | translate"></mat-error>
    </div>
</div>