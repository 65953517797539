<div class="wrapper flex1 flexColumn jcFS">
    <ng-container>
        <div (click)="handleClose()" class="intro">
            <app-icon class="intro-close" [faIcon]="'fa fa-chevron-left'"></app-icon>
            <p class="intro-name">{{badgeEvent?.date | date: 'medium'}}</p>
        </div>

        <div *ngIf="badgeEvent" class="flex1 flexColumn jcFS">
            <p class="location-label">{{'map.used-source' | translate}}</p>
            <div class="location" [style.background-color]="getColor(badgeEvent.id)"
                (click)="emitDetailViewEvent(badgeEvent)">
                <p>{{'map.source' | translate}}: {{badgeEvent.type}}</p>
                <br>
                <p>{{'map.device' | translate}}: {{badgeEvent.deviceId}}</p>
                <p>{{'map.accuracy' | translate}}: {{badgeEvent?.accuracyDisplay}} {{('generics.units.' + measurementUnits | translate)}}</p>
                <p *ngIf="badgeEvent?.speed">{{'map.speed' | translate}}: {{getSpeed(badgeEvent?.speed)}}</p>
                <p *ngIf="badgeEvent?.heading">{{'map.heading' | translate}}: {{getHeading(badgeEvent?.heading)}}</p>
                <br>
                <div *ngIf="badgeEvent.events?.length">
                    <p>{{'map.geofences' | translate}}:</p>
                    <li *ngFor="let event of events" style="list-style-type: none;">
                        {{event}}
                    </li>
                    <br>
                </div>
                <p>{{'map.coordinates' | translate}}:</p>
                <li style="list-style-type: none;">{{badgeEvent.location[1]}}</li>
                <li style="list-style-type: none;">{{badgeEvent.location[0]}}</li>
                <br>
                <div *ngIf="badgeEvent.contributors?.length">
                    <p>{{'map.contributors' | translate}}:</p>
                    <li *ngFor="let contributor of badgeEvent.contributors" style="list-style-type: none;">
                        {{getContributorDisplay(contributor)}}
                    </li>
                </div>
            </div>

            <p *ngIf="badgeEvent?.others?.length > 0" class="location-label">{{'map.other-sources' | translate}}</p>
            <div class="location" *ngIf="badgeEvent?.others?.length > 0">
                <table>
                    <tr class="location">
                        <th>{{'map.source' | translate}}</th>
                        <th>{{'map.accuracy' | translate}}</th>
                        <th>{{'map.contributors' | translate}}</th>
                    </tr>
                    <tr *ngFor="let other of badgeEvent?.others" class="table-row location"
                        [style.background-color]="getColor(other.id)" (click)="emitDetailViewEvent(other)">
                        <td>{{other.type}}</td>
                        <td>{{other.accuracyDisplay}} {{('generics.units.' + measurementUnits | translate)}}</td>
                        <td>{{other.contributors.length}}</td>
                    </tr>
                </table>
            </div>

        </div>
    </ng-container>
</div>