import { debounce } from 'lodash';
import { NativeStorage } from './native-storage';
import { Subscription } from 'rxjs';
import { syncLogVerbose } from '../log';
import { LazySubject } from './lazy-subject';

type LazyUpdate<T> = (val: T) => T;

const WRITE_IMMEDIATE_MS = 1000;
const WRITE_EVERY_MS = 60_000;
export const MAX_STORAGE = 120_000;

export class LazyStorage<T> {
    private static write$ = new LazySubject();
    private writeSubscription?: Subscription;
    private cache: { [key: string]: T };
    private cachePromise: Promise<{ [key: string]: T }>;
    private queue: { [key: string]: T | LazyUpdate<T> } = {};
    private holding = 0;
    private wasHolding = false;
    private needsWrite = false;

    constructor(
        private storage: NativeStorage,
        public key: string,
        private readonly = false,
    ) {}

    get cached() {
        return !!this.cache;
    }

    destroy() {
        this.clear.cancel();
        LazyStorage.write$.clear();
    }

    getMap() {
        this.clear();
        if (this.cache) return this.cache;
        this.cachePromise = this.cachePromise || this.storage.getMap(this.key).then(cache => {
            this.cache = cache as any ?? {};
            console.log(`Read ${this.storage.name} key ${this.key} ${Object.keys(this.cache).length}`);
            let write = false;
            Object.keys(this.queue).forEach(key => {
                const value = this.queue[key];
                if (value) {
                    if (typeof value === 'function') {
                        if (this.cache[key]) this.cache[key] = (value as LazyUpdate<T>)(this.cache[key]);
                    } else {
                        this.cache[key] = value;
                    }
                } else delete this.cache[key];
                write = true;
            });
            if (write) this.write();
            this.queue = {};
            return this.cache;
        });
        return this.cachePromise;
    }

    get(key: string): T | Promise<T> {
        this.getMap();
        if (this.cache) return this.cache[key];
        return this.cachePromise.then(val => val[key]);
    }

    set(key: string, value: T | ((val: T) => T)) {
        if (this.cache) {
            if (typeof value === 'function') {
                if (this.cache[key]) this.cache[key] = (value as LazyUpdate<T>)(this.cache[key]);
            } else {
                this.cache[key] = value;
            }
            this.write();
        } else {
            this.queue[key] = value;
            this.getMap();
        }
    }

    remove(key: string) {
        if (this.cache) {
            delete this.cache[key];
            this.write();
        } else {
            this.queue[key] = null;
            this.getMap();
        }
    }

    hold() {
        this.holding++;
        this.wasHolding = true;
        syncLogVerbose(() => `holding incremented to ${this.holding} for ${this.storage.name}.${this.key}`);
        if (this.writeSubscription) {
            this.writeSubscription.unsubscribe();
            this.writeSubscription = undefined;
        }
    }

    unhold() {
        this.holding--;
        syncLogVerbose(() => `holding decremented to ${this.holding} for ${this.storage.name}.${this.key}`);
        this.write();
    }

    // Ensures all lazy storage gets flushed in the order they were written to avoid data inconsistencies
    write() {
        if (this.holding > 0) return;

        this.needsWrite = true;
        if (!this.writeSubscription) {
            this.writeSubscription = LazyStorage.write$.subscribe(async () => this.flush());
            LazyStorage.write$.fire(this.wasHolding ? WRITE_IMMEDIATE_MS : WRITE_EVERY_MS);
            this.wasHolding = false;
        }
    }

    async flush() {
        if (!this.writeSubscription || this.readonly) return;
        this.writeSubscription.unsubscribe();
        this.writeSubscription = undefined;

        if (!this.cache) {
            console.warn(`${this.storage.name}.${this.key} cache is not defined`);
            return;
        }
        const cache = this.cache;
        this.cache = { ...cache };
        const totalThings = Object.keys(cache).length;
        console.log(`Writing ${totalThings} things to ${this.storage.name}.${this.key}`);
        if (totalThings >= MAX_STORAGE) {
            // react native has a 196607 limit on number of 'things' in an array / object
            // log this error ... the given storage probably needs pruned or split up some way
            // could convert to a Map type, but usually at that point you will bump into the issue in other ways
            console.error(`Too many things in ${this.storage.name}.${this.key} to write: ${totalThings} send help`);
        }

        await this.storage.setMap(this.key, cache);
        if (!this.writeSubscription) {
            this.needsWrite = false;
            this.clear();
        }
    }

    private clear = debounce(() => {
        // if we need a write still or were holding, don't clear the cache
        if (this.needsWrite || this.wasHolding || this.readonly) return;
        this.storage.clearMemory(this.key);
        this.cache = null;
        this.cachePromise = null;
    }, WRITE_EVERY_MS * 2);
}
