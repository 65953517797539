import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DateRangePickerHeaderComponent } from 'components/date-range-picker/date-range-picker-header/date-range-picker-header.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@Component({
    selector: 'app-date-range-picker-header-no-arrow',
    templateUrl: './date-range-picker-header.component.html',
    styleUrls: ['./date-range-picker-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
    ],
})
export class DateRangePickerHeaderNoArrowComponent<D> extends DateRangePickerHeaderComponent<D> {
    showArrowUp = false;
}
