import { Company } from '@weavix/models/src/company/company';
import { Craft } from '@weavix/models/src/craft/craft';
import { GeofenceType, GeofenceTypeCategory } from 'weavix-shared/models/geofence-type.model';
import { Person } from 'weavix-shared/models/person.model';

export interface ShiftBuffers {
    startBuffer: number;
    endBuffer: number;
}
export interface WorkHourStats {
    workingAreaTime: number;
    totalTime: number;
}

export interface PersonWithWorkHours extends Person, WorkHourStats {}
export interface CompanyWithWorkHours extends Company, WorkHourStats {}
export interface CraftWithWorkHours extends Craft, WorkHourStats {}
export interface GeofenceTypeWithHours extends GeofenceType { totalTime: number; }

export interface PeopleMapAndCountOnSite {
    onSiteCount: number;
    people: Map<string, PersonWithWorkHours>;
}

export enum EntityDrilldownType {
    Company = 'company',
    Craft = 'craft',
    Person = 'person',
}

export enum OnSiteDrilldownType {
    ManHrsOnSite = 'manHrsOnSite',
    CompaniesOnSite = 'companiesOnSite',
    CraftsOnSite = 'craftsOnSite',
    GeofenceTypesOnSite = 'geofenceTypesOnSite',
    Personnel = 'peopleOnSite',
}

export enum GeofenceTimeChartDrilldownType {
    HrsWorkedByArea = 'hrsWorkedByArea',
    TimeUtilization = 'timeUtilization',
}

enum MainDashboardType {
    Main = 'main',
}

/*--a way to combine enums--*/
export type PeopleOverviewDashboardType = MainDashboardType | OnSiteDrilldownType | EntityDrilldownType | GeofenceTimeChartDrilldownType;
export const PeopleOverviewDashboardType = { ...MainDashboardType, ...OnSiteDrilldownType, ...EntityDrilldownType, ...GeofenceTimeChartDrilldownType };
/*--*/

export interface MainDashboardData {
    type: MainDashboardType;
}

export interface EntityDrilldownData {
    type: EntityDrilldownType;
    entity: Craft | Company | Person;
    titleString: string;
    people?: Map<string, Person>;
}

export interface OnSiteDrilldownData {
    type: OnSiteDrilldownType;
    titleString: string;
    filterKeys?: string[];
}

export interface GeofenceOnSiteChartDrilldownData {
    type: GeofenceTimeChartDrilldownType;
    titleString: string;
    data?: Map<string, Person> | Map<string, Company> | Map<string, Craft>;
}
export interface GeofenceTimeChartDrilldownData {
    type: GeofenceTimeChartDrilldownType;
    titleString: string;
    name: string;
    typeCategory: GeofenceType | GeofenceTypeCategory;
    typeCategoryId: string;
    color: string;
}

export type ActiveDashboardData = MainDashboardData | EntityDrilldownData | OnSiteDrilldownData | GeofenceTimeChartDrilldownData;

export const drilldownTitleString: {[key in PeopleOverviewDashboardType]: string} = {
    [PeopleOverviewDashboardType.Person]: 'people-overview.person-name',
    [PeopleOverviewDashboardType.ManHrsOnSite]: 'people-overview.man-hrs-on-site',
    [PeopleOverviewDashboardType.Company]: 'people-overview.people-in-company',
    [PeopleOverviewDashboardType.Craft]: 'people-overview.people-with-craft',
    [PeopleOverviewDashboardType.HrsWorkedByArea]: 'people-overview.hours-worked-by-area-category',
    [PeopleOverviewDashboardType.TimeUtilization]: 'people-overview.time-utilization-category',
    [PeopleOverviewDashboardType.CompaniesOnSite]: 'people-overview.unique-companies-on-site',
    [PeopleOverviewDashboardType.CraftsOnSite]: 'people-overview.unique-crafts-on-site',
    [PeopleOverviewDashboardType.Main]: 'people-overview.worker-efficiency-dashord',
    [PeopleOverviewDashboardType.GeofenceTypesOnSite]: 'people-overview.geofence-types-on-site',
    [PeopleOverviewDashboardType.Personnel]: 'people-overview.people-on-site',
};

