import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@Component({
    selector: 'app-date-range-picker-header',
    templateUrl: './date-range-picker-header.component.html',
    styleUrls: ['./date-range-picker-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
    ],
})
export class DateRangePickerHeaderComponent<D> implements OnDestroy {
    public showArrowUp: boolean = true;
    private _destroyed = new Subject<void>();

    constructor(
        // Since this component is only used as a custom header on MatDatepicker, these dependencies are available to be injected without declaring them in the imports array.
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef,
    ) {
        _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    get periodLabel() {
        return this._dateAdapter
            .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
            .toLocaleUpperCase();
    }

    previousClicked(mode: 'month' | 'year') {
        this._calendar.activeDate = mode === 'month'
            ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
            : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }

    nextClicked(mode: 'month' | 'year') {
        this._calendar.activeDate = mode === 'month'
            ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
            : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }
}
