import { Injectable } from '@angular/core';
import { ReportTemplate } from '../models/report-template.model';
import { Report, ReportRequest, ReportResponse } from '../models/report.model';
import { AlertService, ServiceError } from './alert.service';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    static reportHistoryCacheCollection = 'ReportHistory';
    static templatesCollectionCache = 'ReportTemplates';
    static templatesUrl = (id?: string) => `/core/report-templates/${id ?? ''}`;
    static reportsUrl = (id?: string) => `/core/reports/${id ?? ''}`;

    constructor(
        private httpService: HttpService,
        private alertsService: AlertService,
    ) { }

    async getReport(component: any, id: string) {
        try {
            return await this.httpService.get<Report>(component, ReportService.reportsUrl(id));
        } catch (e) {
            this.alertsService.sendServiceError(e, ServiceError.Get, 'generics.report');
        }
    }

    async createReport(component: any, request: ReportRequest) {
        try {
            const response = await this.httpService.post<ReportResponse>(component, ReportService.reportsUrl(), request);
            return response;
        } catch (e) {
            this.alertsService.sendServiceError(e, ServiceError.Get, 'generics.report');
        }
    }

    async getReportTemplate(component: any, templateId: string) {
        try {
            const template = await this.httpService.get<ReportTemplate>(component, ReportService.templatesUrl(templateId), null, null);
            return template;
        } catch (e) {
            this.alertsService.sendServiceError(e, ServiceError.Get, 'reports.template');
        }
    }

    async getReportTemplates(component: any, tags?: string[]) {
        try {
            const templates = await this.httpService.get<ReportTemplate[]>(component, ReportService.templatesUrl(), { tags }, null);
            return templates;
        } catch (e) {
            this.alertsService.sendServiceError(e, ServiceError.Get, 'reports.templates');
        }
    }

}
