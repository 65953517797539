import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TemplatedTooltipTemplateContext } from './templated-tooltip.directive';

/**
 * The display component for [appTemplatedTooltip].
 * Shouldn't be used directly.
 * 
 * @see {@link TemplatedTooltipDirective}
 */
@Component({
    selector: 'app-templated-tooltip',
    templateUrl: './templated-tooltip.component.html',
    styleUrls: ['./templated-tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
    ],
})
export class TemplatedTooltipComponent<T> implements OnDestroy {
    isVisible = false;
    triggerElement?: HTMLElement | null;
    template: TemplateRef<TemplatedTooltipTemplateContext<T>> | null = null;
    data?: T;

    private readonly onHideSubject = new Subject<void>();
    public readonly onHide$: Observable<void> = this.onHideSubject;

    private closeOnInteraction = false;

    ngOnDestroy(): void {
        this.onHideSubject.complete();
        this.triggerElement = null;
    }

    show(): void {
        this.toggleVisibility(true);
    }

    hide(): void {
        this.toggleVisibility(false);
    }

    handleBodyInteraction(): void {
        if (this.closeOnInteraction) {
            this.hide();
        }
    }

    @HostListener('mouseleave', ['$event'])
    handleMouseLeave(event: MouseEvent): void {
        const relatedTarget = event.relatedTarget as Node | null;
        if (!relatedTarget || !this.triggerElement?.contains(relatedTarget)) {
            if (this.isVisible) {
                this.hide();
            } else {
                this.finalizeAnimation();
            }
        }
    }

    private toggleVisibility(isVisible: boolean) {
        this.isVisible = isVisible;
        this.finalizeAnimation();
    }

    private finalizeAnimation() {
        if (this.isVisible) {
            this.closeOnInteraction = true;
        } else if (!this.isVisible) {
            this.onHideSubject.next();
        }
    }
}
