<button
    *ngIf="(isTypeaheadOpen$ | async) !== true; else typeaheadView"
    [id]="triggerId"
    class="typeahead-trigger"
    [class.disabled]="isDisabled$ | async"
    type="button"
    (click)="showTypeahead()"
    (focusin)="showTypeahead()"
>
    <div class="select-value">
        <ng-container *ngIf="selectedCompanyId$ | async | appSelectedCompanyItem:companies as selectedCompany">
            <app-icon
                *ngIf="selectedCompany?.logo"
                class="avatar w20 h20"
                [img]="selectedCompany.logo"
            ></app-icon>
            <span class="select-value-text">{{selectedCompany?.name}}</span>
        </ng-container>
    </div>
    <span class="select-arrow-wrapper">
        <span class="select-arrow"></span>
    </span>
</button>

<ng-template #typeaheadView>
    <input
        #filterInput
        type="text"
        id="company-typeahead-filter-input"
        class="w100p"
        [placeholder]="'configuration.user.placeholder.company-typeahead' | translate"
        [matAutocomplete]="auto"
        [formControl]="filterInputControl"
        (blur)="handleFilterInputBlur()"
        (keydown)="handleKeydown($event)"
    >
    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="handleOptionSelected($event)"
        (closed)="handleAutocompleteClosed()"
    >
        <mat-option
            *ngIf="showAdd && filterInputControl.value?.length >= minimumCharacters"
            class="company-add-option"
            [id]="COMPANY_ADD_KEY"
            [value]="filterInputControl.value"
        >
            {{ filterInputControl.value }} ({{ 'configuration.user.add-new-company' | translate }})
        </mat-option>
        <mat-option
            *ngFor="let company of filteredCompanies$ | async"
            [id]="company.id"
            [value]="company.name"
        >
            <span class="flexRow aiC">
                <app-icon
                    *ngIf="company.logo"
                    class="avatar w20 h20"
                    [img]="company.logo"
                ></app-icon>
                <span>{{company.name}}</span>
            </span>
        </mat-option>
    </mat-autocomplete>
</ng-template>
