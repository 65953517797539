import { FormControl } from '@angular/forms';
import _ from 'lodash';
// eslint-disable-next-line @typescript-eslint/no-require-imports
const saferEval = require('safer-eval');

import { Utils } from '../utils/utils';
import { AggregateType, ColumnData, DataSourceTable, DataSourceVariable, GroupType } from './data-source.model';
import { FieldType } from './item.model';
import { PersonMatcher } from '@weavix/models/src/person/person-matcher';
import { Taggable } from '@weavix/models/src/core/core';

interface RangeSliderOptions {
    min: number;
    max: number;
    thresholds: number[];
    id?: string;
    disabled?: boolean;
}

export const defaultWidgetSize = { w: 5, h: 3 };
export const DEFAULT_DASHBOARD_KEY = 'default-dashboard';
export interface Dashboard extends Taggable, PersonMatcher {
    id?: string;
    name: string;
    widgets: DashboardWidgetConfig[];
    enabled: boolean;
    sources?: DashboardSource[];
    variables?: DashboardVariable[];
    settings?: BaseDashboardSettings[];
    autoRefresh?: boolean;
    navParent?: string;
}

export interface BaseDashboardSettings {
    name: string;
    values: DashboardSettingsValue[];
}

export type DashboardSettingsValue = DashboardRangeSettingsValue;

export interface DashboardRangeSettingsValue {
    type: DashboardSettingsValueType;
    lowerValue?: number;
    higherValue?: number;
    disabled?: boolean;
}

export enum DashboardSettingsValueType {
    Range = 'range',
}

export interface DashboardWidgetConfig {
    id: string;
    name: string;
    description?: string;
    type: DashboardWidgetType;
    options: DashboardWidgetOptions;
    source?: string;
    variables?: DashboardVariable[];
    position?: {
        x: number;
        y: number;
    };
    size?: {
        w: number;
        h: number;
    };
    icon?: string;
}

export interface DashboardWidgetOptions {
    nameField?: string;
    settings?: {[key: string]: any};
}

export enum DashboardWidgetType {
    ConfinedSpace = 'ConfinedSpace',
    Map = 'Map',
    Count = 'Count',
    Table = 'Table',
    RichText = 'RichText',
    BarChart = 'BarChart',
    PieChart = 'PieChart',
    LineChart = 'LineChart',
    DonutChart = 'DonutChart',
    ScatterPlotChart = 'ScatterPlotChart',
}

export interface DashboardWidgetSelection {
    type: DashboardWidgetType;
    faIcon: string;
    formName: string;
    formTitle: string;
    accessLevels?: WidgetAccessLevels;
}

export enum WidgetAccessLevel {
    Account = 'account',
    Facility = 'facility',
}

export interface DrillDownConfig {
    dashboardId: string;
    variables?: Array<{
        name: string;
        value?: string;
        column?: string;
    }>;
}

export type WidgetAccessLevels = { [key in WidgetAccessLevel]?: boolean };

export interface CountWidgetSettings {
    aggregation: AggregateType;
    column: string;
    source: string;
    range: RangeSliderOptions;
    currency: string;
    percentage: boolean;
    formatters?: Array<WidgetFormatterRule>;
    drillDown?: DrillDownConfig;
    clickAction?: WidgetClickAction;
}

export interface WidgetFormatterRule {
    conditions?: Array<{
        column?: string;
        aggregate?: AggregateType;
        operator?: WidgetFormatOperator;
        value?: string;
    }>;
    results?: Array<{
        color?: string;
    }>;
}

export type WidgetFormatOperator = '>' | '<' | '>=' | '<=' | '=' | '<>';

export enum CountFormatType {
    Percent = 'percent',
    Currency = 'currency',
    Temperature = 'temperature',
    Duration = 'duration',
}

export enum Temperature {
    Fahrenheit = 'fahrenheit',
    Celsius = 'celsius',
}

export interface DashboardSource {
    id?: string;
    name?: string;
    source?: DataSourceTable;
}

export interface DashboardVariable extends DataSourceVariable {
    control?: FormControl;
    override?: boolean;
    type: FieldType;
    options?: Array<{ key: string; label: string; }>;
    dataSourceId?: string;
    dataSource?: DataSourceTable;
    dataSourceValues?: string;
    dataSourceLabels?: string;
}

export enum WidgetClickAction {
    Table = 'table',
    Dashboard = 'dashboard',
}

export interface AxisChartWidgetSettings {
    xlabel: string;
    ylabel: string;
    title?: string;
    xRange?: {
        min: number;
        max: number;
    };
    yRange?: {
        min: number;
        max: number;
    };
    xLabelFormatter?: (v) => string;
    tooltipFormatter?: () => string;
    yFormatter?: (v) => string;
    xInterval?: number;
    yInterval?: number;
    x?: string;
    y?: string;

    // data source settings
    source?: string;
    aggregate?: AggregateType;
    group?: GroupType;
    groupFn?: string;

    cursor?: string;

    clickAction?: WidgetClickAction;
    drillDown?: DrillDownConfig;
    events: {
        click?: (event) => void;
    };
}

export interface PieChartWidgetSettings {
    source?: string;
    labels?: string;
    quantities?: string;
    x?: string;
    y?: string;
    title?: string;
    aggregate?: AggregateType;
    group?: GroupType;
    groupFn?: string;
    tooltipFormatter?: () => string;
    clickAction?: WidgetClickAction;
    drillDown?: DrillDownConfig;
}

export interface ScatterPlotWidgetSettings {
    source: string;
    xlabel: string;
    ylabel: string;
}

export interface ColumnDefinition extends ColumnData {
    timeAgo?: {
        instant: 'now' | Date;
    };
    group?: GroupType | AggregateType;
    groupFn?: string;
    sort?: 'none' | 'asc' | 'desc';
}


export type TableWidgetColumn = string | ColumnDefinition;

export interface TableWidgetSettings {
    source: string;
    columns: TableWidgetColumn[];
    showSearch: boolean;
    drillDown?: DrillDownConfig;
}

export interface RichTextEditorSettings {
    editorContent: string;
    drillDown?: DrillDownConfig;
    clickAction?: WidgetClickAction;
}

export function makeColumnDefinitionMapper(columnsData: ColumnData[]) {
    return (tableCol: TableWidgetColumn): ColumnDefinition =>
        (typeof tableCol === 'string' ? columnsData.find(c => c.name === tableCol) : tableCol as ColumnDefinition);
}

export function inferType(val: any) {
    if (typeof val === 'string' && !isNaN(new Date(val).getTime())
        && /^[0-9]{2,4}-[0-9]{1,2}-[0-9]{1,2}[ T]?([0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}(\.[0-9]*)?)?Z?$/i.test(val)) {
        return Utils.fromUtcDate(val, 0);
    } else if (!isNaN(Number(val))) return Number(val);
    return val;
}

export function compare(v1: any, v2: any) {
    v1 = inferType(v1);
    v2 = inferType(v2);

    if (v2 instanceof Date && v1 instanceof Date) return v1.getTime() - v2.getTime();
    if (typeof v1 === 'number' && typeof v2 === 'number') return v1 - v2;

    return (v1 instanceof Date ? v1.toISOString() : String(v1)).toLowerCase().localeCompare(
        (v2 instanceof Date ? v2.toISOString() : String(v2)).toLowerCase());
}

export function aggregate(values: any[], type: AggregateType) {
    switch (type) {
        case AggregateType.Value:
            return values[0];
        case AggregateType.Sum:
            return values.reduce((n, v) => n += _.toNumber(v) || 0, 0);
        case AggregateType.Count:
            return values.reduce((n, v) => n += v == null ? 0 : 1, 0);
        case AggregateType.Avg: {
            const sum = values.reduce((n, v) => n += _.toNumber(v) || 0, 0);
            const count = values.reduce((n, v) => n += v == null ? 0 : 1, 0);
            return count === 0 ? null : sum / count;
        }
        case AggregateType.Min:
            return values.reduce((n, v) => n = v == null || n != null && compare(n, v) < 0 ? n : v, values[0]);
        case AggregateType.Max:
            return values.reduce((n, v) => n = v == null || n != null && compare(n, v) > 0 ? n : v, values[0]);
    }
}

export function formatWidget(settings, rows) {
    if (settings?.formatters) {
        for (let i = 0; i < settings.formatters.length; i++) {
            const formatter = settings.formatters[i];
            let match = true;
            (formatter.conditions || []).forEach(c => {
                const values = rows.map(r => r[c.column]);
                const val = aggregate(values, c.aggregate);
                const test = c.value;
                const comp = compare(val, test);
                let valid;
                switch (c.operator) {
                    case '<': valid = comp < 0; break;
                    case '>': valid = comp > 0; break;
                    case '<=': valid = comp <= 0; break;
                    case '>=': valid = comp >= 0; break;
                    case '=': valid = comp === 0; break;
                    case '<>': valid = comp !== 0; break;
                    default: throw new Error(c.operator);
                }
                if (!valid) match = false;
            });
            if (match) {
                return formatter.results;
            }
        }
    }
    return [];
}


function labelFormatter(value) {
    if (value instanceof Date) return Utils.formatDate(value, 'YYYY-MM-DD, h:mm a');
    return value;
}

export function getRow(row, columnTypeMap?) {
    const testRow = Object.keys(row).reduce((r, key) => {
        const aliasKey = key;
        const val = row[key];
        if (val instanceof Date) r[aliasKey] = val;
        else if (typeof val === 'object' && val != null) r[aliasKey] = getRow(val);
        else if (typeof val === 'string' && !isNaN(new Date(val).getTime())
            && /^[0-9]{2,4}-[0-9]{1,2}-[0-9]{1,2}[ T]([0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}(\.[0-9]*)?)Z?$/i.test(val)) {
            r[aliasKey] = Utils.fromUtcDate(val, 0);
        } else if (_.toNumber(r[key])) r[aliasKey] = _.toNumber(val);
        else r[aliasKey] = val;
        return r;
    }, {});
    return testRow;
}

export function groupValue(x, group, row?, fn?) {
    let label = labelFormatter(x);

    if (x != null && group && group !== GroupType.Value) {
        try {
            switch (group) {
                case GroupType.Year:
                    x = (x as Date).toISOString().substring(0, 4);
                    label = label.substring(0, 4);
                    break;
                case GroupType.Date:
                    x = (x as Date).toISOString().substring(0, 10);
                    label = label.substring(0, 10);
                    break;
                case GroupType.Month:
                    x = (x as Date).toISOString().substring(0, 7);
                    label = label.substring(0, 7);
                    break;
                case GroupType.Hour:
                    x = (x as Date).toISOString().substring(0, 13);
                    label = labelFormatter(`${x}:00:00`);
                    break;
                case GroupType.Minute:
                    x = (x as Date).toISOString().substring(0, 16);
                    label = labelFormatter(`${x}:00`);
                    break;
                case GroupType.Function:
                    x = fn(x, row);
                    label = x;
                    break;
            }
        } catch (e) {
            console.error(e);
        }
        if (typeof x === 'string') x = x.toLowerCase();
    }
    return { label, x };
}

export function groupWidget(row, settings, columnTypeMap, fn?) {
    fn = fn ? fn : settings.group === GroupType.Function && settings.groupFn ? evaluate(settings.groupFn) : null;
    const group = groupValue(row[settings.x || settings.labels], settings.group, row, fn);
    const x = group.x;
    const label = group.label;
    let y = row[settings.y || settings.quantities];
    y = _.toNumber(y) ? _.toNumber(y) : y;
    return { label, x, y, rows: [row] };
}

export function evaluate(fn: string, tables: string[] = ['row']) {
    const fun = saferEval(`function(x, ${tables.join(', ')}){ try { return ${fn}; } catch (e) { return null; } }`);
    return fun;
}
export default interface WidgetEditor {
    beforeSave?: () => Promise<void>;
    beforeCancel?: () => Promise<void>;
    beforeDelete?: () => Promise<void>;
}
