import { TelemetryEvent } from '@weavix/models/src/telemetry';
import { Batcher } from '@weavix/utils/src/batcher';
import { myUserId } from 'models-mobx/my-profile-store/my-profile-store';
import { PubSubService } from './pub-sub.service';
import { Topic } from '@weavix/models/src/topic/topic';
import { Injectable } from '@angular/core';

export interface IdentifyTraits {
    email: string;
    name: string;
    accountId: string;
    userId: string;
}

@Injectable({
    providedIn: 'root',
})
export class TelemetryService {
    constructor(
        private pubSubService: PubSubService,
    ) {}

    private identity: IdentifyTraits;

    private batcher = new Batcher<{ name: TelemetryEvent, data: any }>(async req => {
        const userId = myUserId();
        const topic = userId ? Topic.UserTelemetry : Topic.SelfTelemetry;
        try {
            await this.pubSubService.publish(topic, [userId ?? this.pubSubService.clientId], req);
        } catch (e) {
            console.warn(`Failed to publish telemetry ${e?.message}`);
        }
        return req;
    }, 60_000);

    async setTraits(traits: IdentifyTraits) {
        this.identity = traits;
    }

    async sendTelemetry(name: TelemetryEvent, data: any) {
        this.batcher.add({ name, data: {...data, ...this.identity} });
    }

}
