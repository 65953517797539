import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { Account } from '../models/account.model';
import { ResetPinRequest, Person } from '../models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { LoggedInUser, UpdateUser, UserBadge, UserProfile, UserQrLogin } from '../models/user.model';
import { PermissionChecker } from '../permissions/permission-checker';
import { AlertService } from './alert.service';
import { FacilityService } from './facility.service';
import { HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';
import { PermissionAction, PermissionApiResponse } from '@weavix/models/src/permission/permissions.model';
import { TranslationService } from './translation.service';
import { SyncNgService } from './sync/sync-ng-service';
import { environment } from 'environments/environment';
import { myUser } from 'models-mobx/users-store/users-store';
import { PushPayload } from '@weavix/models/src/push/push';
import { PersonFacilityPredicate, PersonLike } from '@weavix/models/src/person/person-matcher';
import { UploadResponse } from '@weavix/models/src/upload/upload';
import { i18nLocale } from '@weavix/models/src/translate/translate';

const THIRTY_MINUTES = 1800000;

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    constructor(
        private httpService: HttpService,
        private alertsService: AlertService,
        private pubSubService: PubSubService,
        private facilityService: FacilityService,
        private translationService: TranslationService,
        private syncService: SyncNgService,
    ) {}

    userProfile: UserProfile;
    private permissions: PermissionApiResponse;
    private permissionChecker: PermissionChecker;
    private permissionCheckers: { [accountId: string]: PermissionChecker } = {};
    private accounts: Account[];
    private nextUserBadgeUpdateTicks: number;
    profileUpdateSubject = new Subject<UserProfile>();
    permissionSub: Subscription[] = [];
    localeSub: Subscription;
    permissionChange$: Subject<Date> = new Subject<Date>();

    updateUserProfileBadges(badge: UserBadge) {
        this.userProfile.badges = badge;
    }

    async refreshMyBadge(component: any): Promise<UserBadge> {
        if (!this.userProfile) return;
        if (
            this.nextUserBadgeUpdateTicks !== undefined
            && this.nextUserBadgeUpdateTicks > Date.now()
            && this.userProfile.badges
        ) return this.userProfile.badges;

        this.nextUserBadgeUpdateTicks = Date.now() + THIRTY_MINUTES;
        this.userProfile.badges = await this.httpService.get<UserBadge>(component, '/core/me/badge-refresh');
        return this.userProfile.badges;
    }

    private promptRefreshForLocaleChange(locale: i18nLocale) {
        this.alertsService.sendPrompt({
            messageKey: 'configuration.user.locale-updated',
            buttonProps: { label: 'generics.refresh', icon: { faIcon: 'fas fa-sync' } },
        }).subscribe(resp => {
            if (resp) {
                console.log('reloading due to locale update');
                this.reloadWindow();
            }
        });
    }

    reloadWindow() {
        document.location.reload();
    }

    async getUserProfile(component, force: boolean = false): Promise<UserProfile> {
        if (this.userProfile && !force) return this.userProfile;
        try {
            await this.pubSubService.loggedIn();
            if (environment.radioApp) await this.syncService.onLoggedIn();

            const profile = await this.httpService.get<LoggedInUser>(component, '/core/me/user');
            this.accounts = await this.httpService.get(component, '/core/me/accounts');
            this.permissions = await this.httpService.get(component, '/core/me/account/permissions-v2');
            this.permissionChecker = new PermissionChecker(this.permissions);

            if (profile.locale) this.translationService.setLanguage(profile.locale);
            if (this.localeSub) this.localeSub.unsubscribe();
            this.localeSub = this.pubSubService.subscribe<i18nLocale>(null, Topic.UserLocaleChange, [profile.id]).subscribe(payload => {
                if (profile.locale !== payload.payload) {
                    this.promptRefreshForLocaleChange(payload.payload);
                }
            });
            if (this.permissionSub.length > 0) await Promise.all(this.permissionSub.map(x => x.unsubscribe()));
            if (environment.radioApp) {
                await Promise.all(this.accounts.map(async account => {
                    await this.initAccountPermissionChecker(component, account.id, profile.id);
                }));
                console.log('this.permissions :>> ', this.permissions);
            }

            this.userProfile = profile;
            return this.userProfile;
        } catch (e) {
            this.alertsService.sendError({ error: e, messageKey: 'ERRORS.PROFILE.GET' });
            this.alertsService.setAppLoading(false);
            console.error(e);

            return {
                id: null,
                avatarFile: null,
                firstName: null,
                lastName: null,
            };
        }
    }

    onLogout(): void {
        if (this.permissionSub.length > 0) {
            this.permissionSub.forEach(x => x.unsubscribe());
            this.permissionSub = [];
        }
        if (this.localeSub) {
            this.localeSub.unsubscribe();
            this.localeSub = undefined;
        }
    }

    isGlobalAdmin(): boolean {
        return this.permissionChecker.isGlobalAdmin();
    }

    async initAccountPermissionChecker(component, accountId: string, personId: string, update: boolean = false): Promise<void> {
        const perms = await this.httpService.get<PermissionApiResponse>(component, `/a/${accountId}/core/me/account/permissions-v2`);
        this.permissionCheckers[accountId] = new PermissionChecker(perms);
        if (update) this.permissionChange$.next(new Date());
        this.permissionSub.push(this.pubSubService.subscribe<Person>(component, Topic.AccountPersonPermissionsUpdated, [accountId, personId], false)
                    .subscribe(() => {
                        this.initAccountPermissionChecker(component, accountId, personId, true);
                    }));
    }

    async subscribeNotifications(component) {
        const profile = await this.getUserProfile(component);
        return await this.pubSubService.subscribe<PushPayload>(component, Topic.UserNotification, [profile.id]);
    }

    async subscribeBadgeUpdates(component) {
        const profile = await this.getUserProfile(component);
        return await this.pubSubService.subscribe<UserBadge>(component, Topic.UserBadgeUpdate, [profile.id]);
    }

    hasUserPermissionOnAnyAccount(action: PermissionAction) {
        return myUser().accountIds.some(accountId => this.hasPermissionInAnyAccountFacility(action, accountId));
    }

    hasPermissionInAnyAccountFacility(action: PermissionAction, accountId?: string) {
        if (!accountId) return Object.values(this.permissionCheckers).some(x => x.hasAnyPermission(action));
        if (!this.permissionCheckers[accountId]) return false;
        return this.permissionCheckers[accountId].hasAnyPermission(action);
    }

    hasPersonPermission(action: PermissionAction, accountId: string, person: PersonLike, isPersonOnFacility?: PersonFacilityPredicate, facilityId?: string) {
        return person && this.permissionCheckers[accountId]?.hasPersonPermission(action, person, isPersonOnFacility, facilityId);
    }

    hasFacilitiesPermission(action: PermissionAction, facilityIds?: string | string[], folderId?: string, allFacilities = false) {
        if (!action) return true;

        const facilityId = Array.isArray(facilityIds) ? null : facilityIds;
        if (this.hasPermission(action, facilityId, folderId)) return true;
        if (!Array.isArray(facilityIds)) return false;

        if (!facilityIds?.length) {
            return allFacilities ? false : this.hasPermissionInAnyFacility(action);
        }
        return facilityIds[allFacilities ? 'every' : 'some'](v => this.hasPermission(action, v, folderId));
    }

    hasAddPermission(action: PermissionAction, facilityId?: string, multiFacility?: boolean, folderId?: string) {
        if (!action) return true;

        if (this.hasPermission(action, facilityId, folderId)) return true;
        if (!multiFacility) return false;
        return this.hasPermissionInAnyFacility(action);
    }

    hasPermission(action: PermissionAction, facilityId?: string, folderId?: string) {
        facilityId = facilityId ?? this.facilityService.getCurrentFacilityId();
        if (!this.permissions) return true;
        return this.permissionChecker.hasPermission(action, facilityId, folderId);
    }

    hasPermissionInAnyFacility(action: PermissionAction) {
        if (!this.permissions) return true;
        return this.permissionChecker.hasPermissionInAnyFacility(action);
    }

    hasAnyPermission(action: PermissionAction, facilityId?: string, folderId?: string) {
        facilityId = facilityId ?? this.facilityService.getCurrentFacilityId();
        if (!this.permissions) return true;
        return this.permissionChecker.hasAnyPermission(action, facilityId, folderId);
    }

    hasAccountOrAnyFolderPermission(action: PermissionAction) {
        if (!this.permissions) return true;
        return this.permissionChecker.hasFacilityOrAnyFolderPermission(action, null);
    }

    hasAnyPermissionInAnyFacility(action: PermissionAction) {
        if (!this.permissions) return true;
        return this.permissionChecker.hasAnyPermission(action);
    }

    hasAccountPermission(action: PermissionAction) {
        return this.permissionChecker.hasAccountPermission(action);
    }

    hasAccountPermissionInAnyAccount(action: PermissionAction) {
        if (!Object.values(this.permissionCheckers).length) return false;
        return Object.values(this.permissionCheckers).some(x => x.hasAccountPermission(action));
    }

    addFolder(id: string, parentId: string) {
        if (!this.permissions) return;
        this.permissionChecker.addFolder(id, parentId);
    }

    isMaster() {
        return this.accounts?.some(x => x.master);
    }

    async updateUserProfile(component, id: string, update: UpdateUser) {
        try {
            const updatedProfile = await this.httpService.put<UserProfile>(component, `/core/users/${id}`, update);
            this.profileUpdateSubject.next(updatedProfile);
            this.userProfile = updatedProfile;
            return updatedProfile;
        } catch (e) {
            this.alertsService.sendError({ error: e, messageKey: 'ERRORS.PROFILE.UPDATE' });
            this.alertsService.setAppLoading(false);

            throw (e);
        }
    }

    async uploadAvatar(component, fd: FormData): Promise<UploadResponse> {
        try {
            fd.append('mirrored', 'true');
            return await this.httpService.upload<UploadResponse>(component, '/core/uploads/avatar', fd);
        } catch (e) {
            if (e.error?.details?.reason === 'file_too_large') {
                this.alertsService.sendError({ error: e, messageKey: 'ERRORS.FILE.TOO-LARGE' });
            } else if (e.error?.details?.reason === 'no_face') {
                this.alertsService.sendError({ error: e, messageKey: 'ERRORS.FILE.NO-FACE' });
            } else {
                this.alertsService.sendError({ error: e, messageKey: 'ERRORS.UPLOAD' });
            }
            this.alertsService.setAppLoading(false);

            throw (e);
        }
    }

    async getQrLogin(component, id: string): Promise<UserQrLogin> {
        try {
            return await this.httpService.get(component, `/core/users/${id}/qr-login`);
        } catch (e) {
            console.error(e);
        }
    }

    async generateNewQrLogin(component, id: string): Promise<UserQrLogin> {
        try {
            return await this.httpService.put(component, `/core/users/${id}/generate-new-qr-login`, {});
        } catch (e) {
            console.error(e);
        }
    }

    async resetQrPin(component, id: string, pin?: string): Promise<UserQrLogin> {
        try {
            return await this.httpService.put(component, `/core/users/${id}/reset-qr-pin`, { pin });
        } catch (e) {
            console.error(e);
        }
    }

    async adminResetPin(component, request: ResetPinRequest) {
        return this.httpService.post<any>(component, '/account/admin-reset-pin', request);
    }
}
