import { Component, Input } from '@angular/core';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { AvatarPersonComponent, Indicators } from '../avatar-person/avatar-person.component';
import { CommonModule } from '@angular/common';

@AutoUnsubscribe()
@Component({
    selector: 'app-avatar-single-cluster',
    templateUrl: './avatar-single-cluster.component.html',
    styleUrls: ['./avatar-single-cluster.component.scss'],
    standalone: true,
    imports: [
        CommonModule,

        AvatarPersonComponent,
    ],
})
export class AvatarSingleClusterComponent {
    @Input() peopleIds: string[];
    @Input() size: number;

    readonly Indicators = Indicators;

    constructor() {}
}
