<div>
    <div class="pRel" [class]="teamsApp ? 'icon-teams' : 'icon'" (click)="notificationButtonAction($event)" (auxclick)="notificationButtonAction()"
    [matTooltip]="consoleApp ? null : ('crews.channels.header.notifications' | translate)">
        <app-icon [faIcon]="consoleApp ? 'icon-walt' : teamsApp ? 'fal fa-bell' : 'fas fa-bell'" ></app-icon>
        <div *ngIf="notificationCount > 0" class="badge">
            {{ notificationCount }}
        </div>
    </div>
    <div class="menu flexColumn jcS" id="settingsMenu" *ngIf="showNotifications"
    [class.teams]="teamsApp"
    style="overflow-x: hidden;scrollbar-width: thin;"
    appClickOutside
    (clickOutside)="toggleNotificationsDisplay()"
    [style.right]="sidebarOpen ? '355px' : '0'">
        <ng-container *ngIf="teamsApp d-flex">
            <div class="menu-header">{{ 'crews.channels.header.notifications' | translate }}</div>
            <app-icon [faIcon]="teamsApp ? 'fal fa-times' : 'fas fa-times'" class="menu-header-close" (click)="notificationButtonAction($event)" (auxclick)="showNotifications = !showNotifications"></app-icon>
        </ng-container>
        <div class="menu-scroll flexColumn jcS fg1 gap10">
            <div *ngIf="teamsApp && !notifications?.length" class="menu-empty flexColumn fg1 jcC aiC ng-star-inserted gap10">
                <app-icon [faIcon]="'fas fa-envelope-open'" class="menu-empty-icon"></app-icon>
                <div class="menu-empty-text">{{ 'notifications.empty' | translate }}</div>
            </div>
            <div class="menu-section" *ngFor="let n of notifications">
                <app-notification-card
                    class="notification-card"
                    [notification]="n"
                    (updateUnreadCount)="updateNotificationIcon()"
                    (removeNotification)="removeNotification(n.id)"
                    (reloadChannels)="reloadChannels.emit()"
                ></app-notification-card>
            </div>
        </div>
    </div>
</div>
