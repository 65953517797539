<app-alert
    [type]="totalErrors > 0 ? 'error' : 'ok'"
    [icon]="{ faIcon: totalErrors > 0 ? 'fa-light fa-circle-exclamation' : 'fa-light fa-circle-check' }"
    class="flexRow aiC"
>
    <div class="flexColumn jcSA">
        <span *ngIf="totalErrors | appErrorOrOk:errorTitle:okTitle as title" class="banner-text-title">{{title}}</span>
        <span *ngIf="totalErrors | appErrorOrOk:errorSubtitle:okSubtitle as subtitle" class="banner-text-subtitle">{{subtitle}}</span>
    </div>
    <div *ngIf="totalErrors > 0" class="banner-arrows">
        <span class="banner-arrows-text">{{ 'generics.i-of-n' | translate:{ i: currentError, n: totalErrors } }}</span>
        <button (click)="previousError.emit()" class="banner-arrows-button">
            <app-icon faIcon="fa-light fa-chevron-left" class="banner-arrows-button-icon"></app-icon>
        </button>
        <button (click)="nextError.emit()" class="banner-arrows-button">
            <app-icon faIcon="fa-light fa-chevron-right" class="banner-arrows-button-icon"></app-icon>
        </button>
    </div>
</app-alert>
