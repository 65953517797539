import { Context, createStore, ExtendedModel, model, override } from '@weavix/mobx';
import { Craft } from '@weavix/mobx/src/models/craft/craft';

const base = createStore<Craft>('crafts', Craft);

export const craftsContext = base.context as Context<CraftsStore>;
export const craftRef = base.ref;

const getName = name => name.toLowerCase();

@model('CraftsStore')
export class CraftsStore extends ExtendedModel(base.Store, {
}) {
    names = new Map<string, string[]>();

    getNames(name: string) {
        return this.names.get(getName(name));
    }

    @override
    loadValues(values: any[]) {
        super.loadValues(values);
        this.names.clear();
        values.forEach(value => {
            const name = getName(value.name);
            if (this.names.has(name)) this.names.get(name).push(value.id);
            else this.names.set(name, [value.id]);
        });
    }

    @override
    updateValue(value: any) {
        super.updateValue(value);
        const name = getName(value.name);
        if (this.names.has(name)) this.names.get(name).push(value.id);
        else this.names.set(name, [value.id]);
    }

    @override
    deleteValue(value: any) {
        super.deleteValue(value);
        const name = getName(value.name);
        if (this.names.has(name)) this.names.set(name, this.names.get(name).filter(x => x !== value.id));
    }
}
