import { Pipe, PipeTransform } from '@angular/core';
import { Chip } from './chip-list.component';

@Pipe({
    name: 'appChipIsSelected',
    standalone: true,
})
export class ChipIsSelectedPipe implements PipeTransform {
    transform(chip: Chip, selectedItems: Chip[]): boolean {
        return selectedItems.some(s => s.id === chip.id);
    }
}
