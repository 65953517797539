import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective } from 'weavix-shared/directives/click-outside.directive';
import { OpenDirection } from 'weavix-shared/models/core.model';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

export interface ButtonGroupOption {
    display: string;
    onClick: () => void;
    icon?: string;
    id?: string;
}


@AutoUnsubscribe()
@Component({
    selector: 'app-button-group',
    templateUrl: './button-group.component.html',
    styleUrls: ['./button-group.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatTooltipModule,
        TranslateModule,

        ClickOutsideDirective,
    ],
})
export class ButtonGroupComponent {
    @Input() hasPrimaryAction: boolean = true; // if you only want options to fire events, set to true
    @Input() buttonText: string = '';
    @Input() buttonTextKey: string = null;
    @Input() tooltipText: string = '';
    @Input() tooltipTextKey: string = null;
    @Input() icon: string = null;
    @Input() class: string[] = [''];
    @Input() options: ButtonGroupOption[] = [];
    @Input() disabled: boolean = false;
    @Input() showOptions: boolean = false;
    @Input() openDirection: OpenDirection = 'top-left';
    @Input() translate: boolean = true;

    @Output() primaryClick: EventEmitter<void> = new EventEmitter();

    optionListOpened: boolean = false;
    ignoreClickOutside: boolean = false;

    constructor() { }

    get shouldShowOptions() {
        return this.options && this.options.length > 0 && this.showOptions;
    }

    showOptionList(show?: boolean, ignoreClickOutside: boolean = false) {
        if (this.ignoreClickOutside) {
            this.ignoreClickOutside = false;
            return;
        }
        this.ignoreClickOutside = ignoreClickOutside;
        if (this.disabled) return;
        this.optionListOpened = show != null ? show : !this.optionListOpened;
    }

    handleClick() {
        this.optionListOpened = false;
        this.primaryClick.emit();
    }

    async handleOptionClick(option) {
        this.optionListOpened = false;
        await option.onClick();
    }
}
