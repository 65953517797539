import { Injectable, inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ModalService } from '../services/modal.service';
import { ComponentCanDeactivate } from './deactivate.guard';

@Injectable({
    providedIn: 'root',
})
export class PendingChangesGuard {
    constructor(
        private modalService: ModalService,
    ) {}
    async canDeactivate(component: ComponentCanDeactivate) {
        const canDeactivate = !component.canDeactivate || component.canDeactivate();
        if (canDeactivate && this.modalService.canLeave()) {
            return true;
        } else {
            return await this.modalService.confirmLeave();
        }
    }
}

export const pendingChangesGuardFn: CanDeactivateFn<ComponentCanDeactivate> = (component: ComponentCanDeactivate) => inject(PendingChangesGuard).canDeactivate(component);
