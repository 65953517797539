import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import { LogoSpinnerComponent } from 'components/logo-spinner/logo-spinner.component';
import { BehaviorSubject } from 'rxjs';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

const DEFAULT_SPINNER_DIAMETER = 100;

@AutoUnsubscribe()
@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        LogoSpinnerComponent,
    ],
})
export class LoadingComponent implements AfterViewInit, OnChanges {
    @Input() loading = false;
    @Input() numBars = 0;
    @Input() diameter: number;
    @Input() inline: boolean;
    @Input() theme: 'dark' | 'light' | 'transparent' = 'light';
    @Input() backgroundColor: string;

    @ViewChild('loadDiv') loadDiv?: ElementRef;

    spinnerDiameter$ = new BehaviorSubject<number>(DEFAULT_SPINNER_DIAMETER);
    bars$ = new BehaviorSubject<number[]>([]);

    ngOnChanges() {
        this.bars$.next(Array(this.numBars).fill(0).map((_x, i) => i));
        this.spinnerDiameter$.next(this.diameter || DEFAULT_SPINNER_DIAMETER);
    }

    ngAfterViewInit() {
        this.calculateDiameter();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.calculateDiameter();
    }

    private calculateDiameter() {
        if (!this.inline || this.diameter) return;

        // _.has doesn't work inside nativeElement for some reason
        if (!this.loadDiv) return;
        if (!this.loadDiv.nativeElement) return;

        if (this.loadDiv.nativeElement.offsetHeight) {
            this.spinnerDiameter$.next(Math.min(this.loadDiv.nativeElement.offsetHeight, this.loadDiv.nativeElement.offsetWidth));
        }
    }
}
