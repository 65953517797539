import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';

@Component({
    selector: 'app-filter-header',
    templateUrl: './filter-header.component.html',
    styleUrls: ['./filter-header.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        IconComponent,
    ],
})
export class FilterHeaderComponent {
    @Input() titleKey: string;
    @Input() includeBackClick = false;
    @Output() backClick = new EventEmitter<void>();
}
