import { OverlayContainer } from '@angular/cdk/overlay';
import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, SimpleChanges } from '@angular/core';
import intlTelInput, { Iti, SomeOptions } from 'intl-tel-input';

// This file comes from the `intl-tel-input` repository.
// It needs to be updated when we update the library version.
const defaultUtilScript = '/assets/js/intl-tel-input-utils.js';

// This type isn't exported by `intl-tel-input`.
export type SelectedCountryData = ReturnType<Iti['getSelectedCountryData']>;

/**
 * npm ng2-tel-input is no longer supported.
 * Fortunately it's a simple library, so we've reimplemented it here.
 *
 * See `intlTelInput-overwrites.scss` for the styles we've changed.
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[ng2TelInput]',
    standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class Ng2TelInput implements OnInit, OnChanges {
    @Input() ng2TelInputOptions: SomeOptions = {};
    @Input() ng2TelInputSelectedCountryTabIndex = 0;
    @Output() hasError = new EventEmitter<boolean>();
    @Output() ng2TelOutput = new EventEmitter<string>();
    @Output() countryChange = new EventEmitter<SelectedCountryData>();
    @Output() intlTelInputObject = new EventEmitter<Iti>();

    ngTelInput: Iti;

    constructor(
        private readonly el: ElementRef<HTMLInputElement>,
        @Inject(PLATFORM_ID) private readonly platformId: string,
        private readonly overlay: OverlayContainer,
    ) {}

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.ng2TelInputOptions = {
                countrySearch: false,
                initialCountry: 'us',
                countryOrder: ['us', 'gb'],
                ...this.ng2TelInputOptions,
                loadUtilsOnInit: this.getUtilsScript(this.ng2TelInputOptions),
                // Use Angular CDK Overlay to position the dropdown, so it doesn't get cut off by elements with overflow: hidden.
                dropdownContainer: this.overlay.getContainerElement(),
            };
            this.ngTelInput = intlTelInput(this.el.nativeElement, {
                ...this.ng2TelInputOptions,
            });

            this.el.nativeElement.addEventListener('countrychange', () => {
                this.countryChange.emit(this.ngTelInput.getSelectedCountryData());
            });
            this.changeSelectedCountryButtonTabIndex(this.ng2TelInputSelectedCountryTabIndex);

            this.intlTelInputObject.emit(this.ngTelInput);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ng2TelInputSelectedCountryTabIndex']) {
            this.changeSelectedCountryButtonTabIndex(this.ng2TelInputSelectedCountryTabIndex);
        }
    }

    @HostListener('blur') onBlur() {
        const isInputValid = this.isInputValid();
        if (isInputValid) {
            const telOutput = this.ngTelInput.getNumber();
            this.hasError.emit(isInputValid);
            this.ng2TelOutput.emit(telOutput);
        } else {
            this.hasError.emit(isInputValid);
        }
    }

    isInputValid(): boolean {
        return this.ngTelInput.isValidNumber();
    }

    setCountry(country: string) {
        this.ngTelInput.setCountry(country);
    }

    getUtilsScript(options: SomeOptions) {
        return options.loadUtilsOnInit || defaultUtilScript;
    }

    private changeSelectedCountryButtonTabIndex(tabIndex: number) {
        // intl-tel-input hard-codes its country button's tabindex to 0, so we need to override it.
        const selectedCountryButton = (this.ngTelInput as unknown as { selectedCountry: HTMLButtonElement })?.selectedCountry;
        if (!selectedCountryButton) return;

        selectedCountryButton.tabIndex = tabIndex;
    }
}
