import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

/**
 * Extends OverlayContainer to provide a custom overlay container.
 */
@Injectable({ providedIn: 'root' })
export class CustomOverlayContainer extends OverlayContainer {

    // The custom container element.
    readonly customContainer = this.createCustomContainer();

    /**
     * Creates a new HTMLDivElement that serves as the custom overlay container.
     * @returns {HTMLDivElement} The newly created div element with custom class.
     */
    private createCustomContainer(): HTMLDivElement {
        const container = document.createElement('div');
        container.classList.add('custom-overlay-container-class');
        return container;
    }

    /**
     * Appends the custom container to a given HTML element if it's not already appended.
     * Updates the internal container element reference to the custom container.
     * @param {HTMLElement} element The element to append the custom container to.
     */
    public createCustomOverlayContainer(element: HTMLElement): void {
        if (!element.contains(this.customContainer)) 
            element.appendChild(this.customContainer);
        this._containerElement = this.customContainer;
    }

    /**
     * Clears the reference to the custom container, effectively removing it so the overlay will be added to the document body.
     * This method should be called after the custom container is no longer needed to avoid issues on dropdowns, tooltips, etc.
     */
    public clearCustomOverlayContainer(): void {
        this._containerElement = null;
    }
}