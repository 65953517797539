export interface DvrControlsPlayingState {
    timestamp: number;
    isPlaying: boolean;
}

export interface DvrControlsPlaybackState {
    timestamp?: number;
    inPlaybackMode?: boolean;
    playback?: boolean;
}

export interface DateRange {
    from: Date;
    to: Date;
}

export interface TimeRange {
    from: number;
    to: number;
}

export interface DvrControlsState extends DvrControlsPlaybackState {
    togglePlaybackMode?: boolean;
    range: DateRange;
    firstLoad?: boolean;
}

export enum DvrType {
    Custom = 'custom',
    Day = 'day',
    Week = 'week',
}
