<mat-chip
    [removable]="removable"
    [selectable]="selectable"
    [disabled]="disabled"
    (removed)="handleRemove()"
    [class.invalid]="invalid"
    [class.not-removable]="!removable"
    class="mat-chip-with-trailing-icon"
>
    <ng-container *ngIf="doTranslate">{{ chip | appChipDisplay:displayKey | translate}}</ng-container>
    <ng-container *ngIf="!doTranslate">{{ chip | appChipDisplay:displayKey }}</ng-container>
    <!-- We're not using [matChipRemove] because it prevents the click event from propagating. -->
    <app-icon
        *ngIf="removable"
        [faIcon]="'fas fa-times'"
        (click)="handleRemove()"
        class="mat-chip-remove mat-chip-trailing-icon"
    ></app-icon>
</mat-chip>