<div appClickOutside (clickOutside)="showColors(false)" class="color-container" [attr.disabled]="disabled ? true : undefined">
    <div [matTooltip]="disabledText" matTooltipPositionAtOrigin="true">
        <div class="color-container-color dropdown" (click)="showColors()" [ngStyle]="{'background-color': selectedColor}"></div>
        <div class="color-container-list" *ngIf="showColorList"
            [ngClass]="openDirection" [ngStyle]="{'grid-template-columns': 'repeat(' + columns + ', 1fr)'}"
        >
            <div
                *ngFor="let k of colorOptions"
                (click)="pickColor(k)"
                class="color-container-color option"
                [ngStyle]="{'background-color': k}"
            ></div>
        </div>
    </div>
</div>