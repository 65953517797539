import { NewRelicService } from 'weavix-shared/services/new-relic.service';

export function initializeNewRelic(): void {
    NewRelicService.initializeNewRelic();

    const consoleError = console.error;
    
    console.error = (...args) => {
        const exc = args.find(x => x instanceof Error);
        if (exc) NewRelicService.sendError(exc);
        consoleError.apply(console, args);
    };
}