import { DataFilterOptions } from '../data-filter/data-filter.model';

export enum CardType {
    AddParticipant = 'addParticipant',
    ChannelPerson = 'channelPerson',
    ChannelInfoPerson = 'channelInfoPerson',
    Facility = 'facility',
    Participant = 'participant',
    People = 'people',
    Account = 'account',
    Company = 'company',
    Item = 'item',
    EmailInvite = 'emailInvite',
    EmailInviteSmall = 'emailInviteSmall',
    IconLabel = 'iconLabel',
}

export interface CardSelectOptions {
    cardType: CardType;
    lazyLoadCount?: number;
    selectionOptions?: {
        noSelect?: boolean;
        multiSelect?: boolean;
        maxSelections?: number;
    };
    filterOptions?: DataFilterOptions;
    theme: CardSelectTheme;
}

export interface Card {
    disabled?: boolean;
    selected: boolean;
    data: any;
}

export interface SelectionEvent {
    selected: any;
    deselected: any;
}

export interface CardSelectTheme {
    light?: boolean;
    singleStack?: boolean;
    cardWidth?: number;
    cardHeight?: number;
    loaderCount?: number;
    noCardBackground?: boolean;
}
