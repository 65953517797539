import { NavigationStart, Router } from '@angular/router';

import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppIcon } from 'weavix-shared/models/icon.model';
import { sleep } from '../utils/sleep';
import { TranslationService } from './translation.service';

export enum SnackBarType {
    Success = 'success',
    Error = 'error',
    Prompt = 'prompt',
    Info = 'info',
}

export interface SnackBar {
    message: string;
    type: SnackBarType;
    timeout: number;
    response?: Subject<boolean | undefined>;
    cssClass?: string;
    icon?: AppIcon;
    buttonProps?: SnackBarButtonProps;
    containerClass?: string;
    parentContainer?: ElementRef;
}

interface SnackBarButtonProps {
    label: string;
    icon: AppIcon;
}

export interface ToastProps {
    /** Message key to be translated */
    messageKey: string;
    /** Message params to be passed to the translation service */
    messageParams?: unknown;
    /** Alert css and icon class */
    type: SnackBarType;
    /** Optional timeout for the toast @default 3000*/
    timeout?: number;
    /** Optional parent container for the toast */
    parentContainer?: ElementRef;
    /** Custom button props, no button will show if undefined */
    buttonProps?: SnackBarButtonProps;
    /** Error object to be logged */
    error?: Error;
}

export enum ServiceError {
    Get = 'GET',
    Add = 'ADD',
    Update = 'UPDATE',
    Delete = 'DELETE',
    Import = 'IMPORT',
}

export function getErrorText(e) {
    if (e.details) {
        if (e.details.reason) return e.details.reason;
        if (e.details.field) return e.details.field;
        if (e.message) return e.message;
    } else {
        return e.message || e;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    static appLoadingSubject = new Subject<boolean>();

    private alertSubject = new Subject<SnackBar>();
    public alert$: Observable<SnackBar> = this.alertSubject;

    skip: boolean;

    constructor(
        private translationService: TranslationService,
        private router: Router,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                AlertService.appLoadingSubject.next(false);
            }
        });
    }

    static setAppLoading(isLoading: boolean): void {
        AlertService.appLoadingSubject.next(isLoading);
    }

    get successIcon(): AppIcon { return { faIcon: 'fa-light fa-circle-check', fontSize: '30px' } as AppIcon; }
    get errorIcon(): AppIcon { return { faIcon: 'fa-light fa-circle-xmark', fontSize: '30px' } as AppIcon; }
    get infoIcon(): AppIcon { return { faIcon: 'fa-light fa-circle-exclamation', fontSize: '30px' } as AppIcon; }
    get promptIcon(): AppIcon { return { faIcon: 'fa-light fa-circle-info', fontSize: '30px' } as AppIcon; }

    private getSnackBarClass(type: SnackBarType): string {
        if (type === SnackBarType.Error) return 'mat-snackbar-error';
        if (type === SnackBarType.Success) return 'mat-snackbar-success';
        if (type === SnackBarType.Info) return 'mat-snackbar-info';
        else return 'mat-snackbar-prompt';
    }

    private getSnackBarIcon(type: SnackBarType): AppIcon {
        if (type === SnackBarType.Error) return this.errorIcon;
        if (type === SnackBarType.Success) return this.successIcon;
        if (type === SnackBarType.Info) return this.infoIcon;
        else return this.promptIcon;
    }

    /** Red toast */
    sendError(props: Omit<ToastProps, 'type'>) {
        return this.sendToast({ ...props, type: SnackBarType.Error });
    }

    /** Green toast */
    sendSuccess(props: Omit<ToastProps, 'type'>) {
        return this.sendToast({ ...props, type: SnackBarType.Success });
    }

    /** Orange toast */
    sendInfo(props: Omit<ToastProps, 'type'>) {
        return this.sendToast({ ...props, type: SnackBarType.Info });
    }

    /** Blue toast, persists until dismissed by user */
    sendPrompt(props: Omit<ToastProps, 'type' | 'timeout'>) {
        return this.sendToast({ ...props, type: SnackBarType.Prompt, timeout: 0 });
    }

    sendServiceError(error: Error, serviceError: ServiceError, itemKey: string, extraKey?: string) {
        const errorKey = `ERRORS.GENERIC.${serviceError}`;
        let translatedItem = this.translationService.getImmediate(itemKey);

        if (extraKey) {
            const translatedExtra = this.translationService.getImmediate(`ERRORS.MISC.${extraKey}`);
            translatedItem += `. ${translatedExtra}`;
        }

        this.sendError({
            error,
            messageKey: errorKey,
            messageParams: { item: translatedItem },
        });
    }

    private sendToast(props: ToastProps) {
        const response = new Subject<boolean>();
        const message = this.translationService.getImmediate(props.messageKey, props.messageParams);
        const snackBar: SnackBar = {
            message,
            type: props.type,
            timeout: props.timeout ?? 3000,
            response,
            cssClass: this.getSnackBarClass(props.type),
            icon: this.getSnackBarIcon(props.type),
            buttonProps: props.buttonProps,
            parentContainer: props.parentContainer,
        };

        if (props.error) {
            const errorText = getErrorText(props.error);
            console.error(errorText, props.error);
        }

        if (!this.skip) this.alertSubject.next(snackBar);
        return response;
    }

    dismiss() {
        this.alertSubject.next(null);
    }

    setAppLoading(isLoading: boolean): void {
        AlertService.appLoadingSubject.next(isLoading);
    }

    async withLoading<T>(promise: Promise<T>): Promise<T> {
        try {
            this.setAppLoading(true);
            return await promise;
        } finally {
            this.setAppLoading(false);
        }
    }

    async skipError() {
        this.skip = true;
        await sleep(1000);
        this.skip = false;
    }
}
