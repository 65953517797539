import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Chip } from './chip-list.component';

/**
 * Global service for all chip lists to communicate with each other.
 */
@Injectable({ providedIn: 'root' })
export class ChipListService {
    private newItemSource$ = new Subject<Chip>();

    /**
     * Gets an observable that emits when the data source of one chip lists has a new item added.
     * Other chip lists might want to refresh since they might share the same data source.
     */
    get newItem$(): Observable<Chip> {
        return this.newItemSource$.asObservable();
    }

    /**
     * Notifies all chip lists that a new item has been added to the data source of one chip list.
     */
    onNewItem(newItem: Chip): void {
        this.newItemSource$.next(newItem);
    }
}
