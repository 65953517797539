import { css } from 'weavix-shared/utils/css';

export interface Avatar {
    height: number;
    width: number;
    img?: string;
    name?: string;
    altText?: string;
    editable?: boolean;
    circular?: boolean;
    placeholder?: {
        icon: string;
        iconColor?: string;
        backgroundColor?: string;
    };
    outlineColor?: string;
    hasBorders?: boolean;
    borderWidth?: number;
    indicatorSize?: number;
    padding?: number;
}

export function buildAvatar(height: number, width?: number, editable: boolean = false, img: string = null, altText?: string, outlineColor?: string): Avatar {
    return {
        height,
        width,
        img,
        altText,
        editable,
        outlineColor,
    };
}

export function defaultAvatar(): Avatar {
    return {
        height: 110,
        width: 110,
        hasBorders: true,
        borderWidth: 2,
        padding: 4,
        placeholder: {
            icon: 'fas fa-user',
            iconColor: css.colors.AVATAR_ICON,
            backgroundColor: css.colors.AVATAR_BACKGROUND,
        },
    };
}
