import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DateRange } from 'weavix-shared/models/dvr.model';
import { CATEGORY_PARENT_MAP, filterCategoryToIcon, filterCategoryToTranslationKey, FilterResultType, NESTED_PARENT_MAP, TableFilterCategory, TableFilterCategoryResults, TableFilterCategoryToIdKey, TableFilterNestedCategoryToIdKey, TableFilterResult, TableHeaderOptions,
    TableHeaderPaginationInfo } from 'weavix-shared/models/table.model';
import { TranslationService } from 'weavix-shared/services/translation.service';


@Injectable({ providedIn: 'root' })
export class TableService {
    public defaultFilters: TableFilterCategoryResults = {};
    public filterCategoryToIdKeyMap: TableFilterCategoryToIdKey = {};

    private loadingSource$ = new BehaviorSubject<boolean>(false);
    private titleSource$ = new Subject<string>();
    private plusIconClickSource$ = new Subject<void>();
    private optionsSource$ = new Subject<TableHeaderOptions>();
    private paginationInfoSource$ = new BehaviorSubject<TableHeaderPaginationInfo>({});

    public searchFilter: { columnNames?: Array<string | ((row) => any)>, value?: string } = {};
    public selectedFilters: { [key in TableFilterCategory]?: { [key: string]: TableFilterResult } } = {};
    public selectedDateFilters: { [key in TableFilterCategory]?: DateRange } = {};
    public filterSidebarMap: { [key: string]: TableFilterResult } = {};
    filterUpdate$ = new Subject<{ resetFilters: boolean }>();

    constructor(
        private translationService: TranslationService,
    ) { }

    get noFiltersApplied(): boolean {
        return !Object.values(this.selectedDateFilters)?.length &&
            Object.values(this.selectedFilters).every(f => !Object.keys(f).length) &&
            !this.searchFilter?.columnNames &&
            !this.searchFilter?.value;
    }

    get loading$(): Observable<boolean> {
        return this.loadingSource$.asObservable();
    }

    get title$(): Observable<string> {
        return this.titleSource$.asObservable();
    }

    get plusIconClick$(): Observable<void> {
        return this.plusIconClickSource$.asObservable();
    }

    get options$(): Observable<TableHeaderOptions> {
        return this.optionsSource$.asObservable();
    }

    get paginationInfo$(): Observable<TableHeaderPaginationInfo> {
        return this.paginationInfoSource$.asObservable();
    }

    setLoading(loading: boolean): void {
        this.loadingSource$.next(loading);
    }

    setTitle(title: string): void {
        this.titleSource$.next(title);
    }

    onPlusIconClick(): void {
        this.plusIconClickSource$.next();
    }

    setOptions(options: TableHeaderOptions): void {
        this.optionsSource$.next(options);
    }

    setPaginationInfo(paginationInfo: TableHeaderPaginationInfo): void {
        this.paginationInfoSource$.next(paginationInfo);
    }

    resetSelectedFilters(): void {
        this.selectedFilters = _.cloneDeep(this.defaultFilters);
    }

    initFilters(defaultFilters?: TableFilterCategoryResults, filterKeyMap?: TableFilterCategoryToIdKey): void {
        this.defaultFilters = defaultFilters ?? {};
        this.filterCategoryToIdKeyMap = filterKeyMap ?? {};
        this.selectedFilters = _.cloneDeep(this.defaultFilters);
        this.clearFilters();
        this.searchFilter = {};
    }

    clearFilters(): void {
        this.filterSidebarMap = {};
    }

    private setSelectedFilters(category: TableFilterCategory) {
        const handleNestedChildren = (res: TableFilterResult, selectedFilterValue?: { [key: string]: TableFilterResult }): boolean => {
            const selectedFilterRef = selectedFilterValue[res.category ?? res.key];
            let isSelected = false;
            if (res.children) {
                Object.values(res.children).forEach(child => {
                    const resp = handleNestedChildren(child, { [res.category ?? res.key]: selectedFilterRef });
                    if (resp) isSelected = true;
                });
            } else isSelected = res.selected;
            if (isSelected) {
                res.selected = true;
                selectedFilterValue[res.category ?? res.key] = res;
            } else if (selectedFilterValue?.[res.category ?? res.key]) delete selectedFilterValue[res.category ?? res.key];
            return isSelected;
        };

        if (CATEGORY_PARENT_MAP[category]) {

            const parentCategory = CATEGORY_PARENT_MAP[category];

            if (this.filterSidebarMap[parentCategory].children?.[category].selected) {
                this.selectedFilters[category][this.filterSidebarMap[parentCategory].children?.[category].key] = this.filterSidebarMap[parentCategory].children?.[category];
            } else if (this.selectedFilters[category][this.filterSidebarMap[parentCategory].children?.[category].key]) {
                delete this.selectedFilters[category][this.filterSidebarMap[parentCategory].children?.[category].key];
            }
        } else if (NESTED_PARENT_MAP[category]) {
            const parentCategory = NESTED_PARENT_MAP[category];
            const parent = this.filterSidebarMap[parentCategory];
            handleNestedChildren(parent, this.selectedFilters[parentCategory]);
        } else {
            Object.values(this.filterSidebarMap[category].children).forEach(child => {
                // ignore child custom filters
                if (Object.values(TableFilterCategory).includes(child.key as TableFilterCategory)) return;
                if (child.selected) this.selectedFilters[category][child.key] = child;
                else if (this.selectedFilters[category][child.key]) delete this.selectedFilters[category][child.key];
            });
        }

        this.filterUpdate$.next({ resetFilters: false });
    }

    convertItemToFilterResult = (
        i: any,
        c: TableFilterCategory,
        type: FilterResultType,
        appendixCountFn?: (key: string | null, category: TableFilterCategory) => string,
        appendixIcon?: string,
        selected = false,
    ): TableFilterResult => ({
        name: i?.name ?? (filterCategoryToTranslationKey?.[c] ? this.translationService.getImmediate(filterCategoryToTranslationKey[c]) : c),
        icon: i ? null : filterCategoryToIcon?.[c],
        key: i?.id ?? c,
        category: c,
        type,
        data: i,
        hidden: false,
        selected,
        setSelected: () => this.setSelectedFilters(c),
        appendixText: () => appendixCountFn(i?.id, c),
        appendixIcon: { faIcon: appendixIcon ?? 'fas fa-user-friends' },
    });

    populateSidebarFilter(
        entity: any,
        category: TableFilterCategory,
        type: FilterResultType,
        appendixCountFn?: (key: string | null, category: TableFilterCategory) => string,
        appendixIcon?: string,
        multiselect = false,
        skipSortChildren = false,
        selected = false,
    ): void {
        const parentCategory = CATEGORY_PARENT_MAP[category] ?? category;

        if (!this.filterSidebarMap?.[parentCategory]) {
            this.filterSidebarMap[parentCategory] = {
                name: filterCategoryToTranslationKey?.[parentCategory] ? this.translationService.getImmediate(filterCategoryToTranslationKey[parentCategory]) : parentCategory,
                icon: filterCategoryToIcon?.[parentCategory],
                key: parentCategory,
                category: parentCategory,
                type,
                hidden: false,
                children: null,
                multiselect,
                skipSortChildren,
                selected,
            };

            this.filterSidebarMap[parentCategory] = Object.assign({}, this.filterSidebarMap[parentCategory], {
                children: {
                    [entity?.id ?? category]: this.convertItemToFilterResult(entity, category, type, appendixCountFn, appendixIcon, selected),
                },
            });
        } else if (this.filterSidebarMap[parentCategory]?.children && !this.filterSidebarMap[parentCategory].children[entity?.id]) {
            this.filterSidebarMap[parentCategory].children[entity?.id ?? category] = this.convertItemToFilterResult(entity, category, type, appendixCountFn, appendixIcon, selected);
        }
    }

    populateNestedSidebarFilter(
        entity: any,
        parentEntity: any,
        superCategory: TableFilterCategory,
        parentCategory: TableFilterCategory,
        category: TableFilterCategory,
        type: FilterResultType,
        appendixCountFn?: (key: string | null, category: TableFilterCategory) => string,
        appendixIcon?: string,
        parentAppendixCountFn?: (key: string | null, category: TableFilterCategory) => string,
        parentAppendixIcon?: string,
        multiselect = false,
        skipSortChildren = false,
        selected = false,
    ): void {
        if (!this.filterSidebarMap?.[superCategory]) {
            this.filterSidebarMap[superCategory] = {
                name: filterCategoryToTranslationKey?.[superCategory] ? this.translationService.getImmediate(filterCategoryToTranslationKey[superCategory]) : superCategory,
                icon: filterCategoryToIcon?.[superCategory],
                key: superCategory,
                category: superCategory,
                type,
                hidden: false,
                children: {},
                multiselect,
                skipSortChildren,
                selected,
            };
        }
        if (!this.filterSidebarMap[superCategory].children[parentEntity?.id ?? parentCategory]?.children) {
            this.filterSidebarMap[superCategory].children[parentEntity?.id ?? parentCategory] = {
                name: parentEntity?.name ?? (filterCategoryToTranslationKey?.[parentCategory] ? this.translationService.getImmediate(filterCategoryToTranslationKey[parentCategory]) : parentCategory),
                key: parentEntity?.id ?? parentCategory,
                category: parentCategory,
                type,
                hidden: false,
                children: {},
                multiselect,
                skipSortChildren,
                selected,
                appendixText: parentAppendixCountFn ? () => parentAppendixCountFn(parentEntity?.id, parentCategory) : null,
                appendixIcon: { faIcon: parentAppendixIcon ?? 'fas fa-user-friends' },
            };
        }
        if (this.filterSidebarMap[superCategory].children[parentEntity?.id ?? parentCategory]?.children && !this.filterSidebarMap[superCategory].children[parentEntity?.id ?? parentCategory].children[entity?.id]) {
            this.filterSidebarMap[superCategory].children[parentEntity?.id ?? parentCategory].children[entity?.id ?? category] =
                this.convertItemToFilterResult(entity, category, type, appendixCountFn, appendixIcon, selected);
        }
    }

    // Filters of different parents act as AND operations
    // Filters of the same parent act as OR operations
    // We need to map the custom filters under their parent filters to meet these expectations
    getSelectedFilterParentsMap(): { [key in TableFilterCategory]?: { [key: string]: TableFilterResult } } {
        const selectedFilterParentsMap = {};
        Object.keys(this.selectedFilters).forEach((cat: TableFilterCategory) => {
            if (CATEGORY_PARENT_MAP[cat]) {
                const parentCategory = CATEGORY_PARENT_MAP[cat];
                if (!selectedFilterParentsMap[parentCategory]) {
                    selectedFilterParentsMap[parentCategory] = { [cat]: _.cloneDeep(this.selectedFilters[cat]) };
                } else {
                    selectedFilterParentsMap[parentCategory][cat] = _.cloneDeep(this.selectedFilters[cat]);
                }
            } else {
                if (!selectedFilterParentsMap[cat]) {
                    selectedFilterParentsMap[cat] = { [cat]: _.cloneDeep(this.selectedFilters[cat]) };
                } else {
                    selectedFilterParentsMap[cat][cat] = _.cloneDeep(this.selectedFilters[cat]);
                }
            }
        });
        return selectedFilterParentsMap;
    }

    /**
     * This method determines if the given row passes the given filters.
     *
     * @param row The row being filtered.
     * @param importedKeyMap The list of filters that apply to the table being filtered mapped to their database names.
     * @param collection The list of filters that are Yes/No Filters.
     * @returns Boolean value indicating whether the row passes the filters.
     */
    checkAndApplyFiltersToRow(row: any, importedKeyMap?: TableFilterCategoryToIdKey, collection?: { [key: string]: Map<string, any> }, nestedCollection?: TableFilterNestedCategoryToIdKey): boolean {
        const keyMap = importedKeyMap;
        const selectedFilterParentsMap = this.getSelectedFilterParentsMap();
        if (
            this.noFiltersApplied || // no filter
            (
                Object.keys(this.selectedDateFilters).every((cat: TableFilterCategory) => { // all date filters available
                    const categoryToIdKeyMap = keyMap[cat];
                    const recordDate = _.get(row, categoryToIdKeyMap.key);
                    const dateRangeFilter = this.selectedDateFilters[cat];
                    return dateRangeFilter ? dateRangeFilter.from <= recordDate && (new Date(dateRangeFilter.to).setDate(dateRangeFilter.to.getDate() + 1)) > recordDate : true;
                })
                &&
                Object.keys(selectedFilterParentsMap).every((parent: TableFilterCategory) => { // all filter parents available
                    // if any of the child filters of this parent are selected, treat other child filters as false if empty/not set
                    const treatEmptyAsTrue = Object.keys(selectedFilterParentsMap[parent]).every((cat: TableFilterCategory) => {
                        return Object.keys(selectedFilterParentsMap[parent][cat]).length === 0;
                    });
                    return Object.keys(selectedFilterParentsMap[parent]).some((cat: TableFilterCategory) => { // all child filters of the parent available
                        const categoryToIdKeyMap = keyMap[cat];
                        if (NESTED_PARENT_MAP[parent]) {
                            return this.filterNestedCategoryApplied(
                                parent,
                                cat,
                                [].concat(_.get(row, categoryToIdKeyMap.key)),
                                nestedCollection ? nestedCollection : null,
                                treatEmptyAsTrue,
                            );
                        } else {
                            return this.filterCategoryApplied(
                                cat,
                                [].concat(_.get(row, categoryToIdKeyMap.key)),
                                categoryToIdKeyMap.collection ? collection : null,
                                treatEmptyAsTrue,
                            );
                        }
                    });
                })
                && this.filterSearchApplied(row) // search filter
            )
        ) {
            return true;
        }
    }

    private filterNestedCategoryApplied(
        parentCat: TableFilterCategory,
        cat: TableFilterCategory,
        ids: Array<string | { id: string, name: string }> = [],
        nestedCollection?: TableFilterNestedCategoryToIdKey,
        treatEmptyAsTrue = true,
    ): boolean {
        const keyValues: string[] = [];
        const idStrings = ids.filter(obj => obj !== undefined).map(obj => typeof obj === 'string' ? obj : obj.id);

        const getNestedKeys = (res: TableFilterResult, concatString?: string) => {
            if (res.children) {
                Object.keys(res.children).forEach((key: string) => {
                    getNestedKeys(res.children[key], concatString ? `${concatString}|${key}` : key);
                });
            } else if (res.selected) {
                keyValues.push(concatString);
            }
        };

        const parentCategory = NESTED_PARENT_MAP[parentCat];
        const parent = this.selectedFilters[parentCategory]?.[cat];
        if (!parent || !Object.keys(parent).length) return treatEmptyAsTrue;
        getNestedKeys(parent);
        return idStrings.some(x => keyValues.some(y => nestedCollection?.[parentCat]?.[y]?.includes(x)));
    }

    private filterCategoryApplied(cat: TableFilterCategory, ids: Array<string | { id: string, name: string }> = [], collection?: { [key: string]: Map<string, any> }, treatEmptyAsTrue = true): boolean {
        const idStrings = ids.filter(obj => !!obj).map(obj => typeof obj === 'string' ? obj : obj.id);
        if (!treatEmptyAsTrue && !Object.keys(this.selectedFilters[cat]).length) return false;
        return !Object.keys(this.selectedFilters[cat]).length
            || (collection && idStrings.some(x => collection[cat]?.get(x)))
            || idStrings.some(x => this.selectedFilters[cat][x]);
    }


    private filterSearchApplied(row: any): boolean {
        return !this.searchFilter?.columnNames || !this.searchFilter.value
        || this.searchFilter.value?.toLowerCase().trim().split(' ')
            .every(v => this.searchFilter.columnNames.some(c => {
                if (typeof c === 'function')
                    return c.call(this, row)?.toLowerCase()?.includes(v);
                else
                    return _.get(row, c)?.toLowerCase()?.includes(v);
            }));
    }
}
