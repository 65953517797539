import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TabComponent } from 'components/tab/tab.component';
import { environment } from 'environments/environment';
import { AppIcon } from 'weavix-shared/models/icon.model';

export interface TabBarItem<TId extends string = string> {
    id: TId;
    icon?: AppIcon;
    text?: string;
    disabled?: () => string;
    hidden?: boolean;
}

/**
 * @template {string} TId A generic parameter for the tab item id. Defaults to string.
 */
@Component({
    selector: 'app-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['./tab-bar.component.scss', './tab-bar-teams.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        TabComponent,
    ],
})
export class TabBarComponent<TId extends string = string> {
    @Input() tabs: ReadonlyArray<TabBarItem<TId>>;
    @Input() title: string;
    @Input() activeTab: TId;
    @Input() heightPx: number;
    @Input() orientation?: 'vertical' | 'horizontal' = 'horizontal';
    @Input() minWidthPx: number;
    /**
     * Outputs the id of the clicked item.
     * If a generic parameter for TId was not provided, the id will be a string.
     */
    @Output() tabClick = new EventEmitter<TId>();

    teamsApp = environment.teamsApp;

    public onTabClick(id: TId): void {
        this.tabClick.emit(id);
    }
}
