<mat-form-field
    [style]="customStyle"
    *ngIf="dateRangeForm"
    [formGroup]="dateRangeForm"
    class="date-picker-input"
>

    <mat-label>{{translatedLabel}}</mat-label>

    <mat-date-range-input
        [ngClass]="{'disabled': dateRangeForm.disabled}"
        [max]="maxDate"
        [rangePicker]="picker"
        [separator]="separator"
        class="date-range-input-container"
    >
        <input matStartDate readonly formControlName="from" [placeholder]="startDateLabel" (click)="openPicker()" (focus)="openPicker()" appPreventInput>
        <input matEndDate readonly formControlName="to" [placeholder]="endDateLabel" (click)="openPicker()" (focus)="openPicker()" appPreventInput>
    </mat-date-range-input>

    <mat-datepicker-toggle matSuffix [for]="picker" class="date-picker-icon">
        <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
    </mat-datepicker-toggle>
    
    <mat-date-range-picker #picker class="date-picker-input" [calendarHeaderComponent]="customHeader" [dateClass]="dateClassBasedOnProps">
        <mat-date-range-picker-actions>
            <div class="flexColumn w100p">
                <div *ngIf="includeTimeRange" class="time-picker-container">
                    <app-day-time-range-picker [group]="dateRangeForm.controls.shift"></app-day-time-range-picker>
                </div>
                <!--matDateRangePickerApply only fires when new dates are selected and if we just use the click event then we don't get updated date values-->
                <!--Both fire and we debounce in ts file, I'm sure there is a way to do this with a single event but it is what it is for now-->
                <button mat-button
                    (matDateRangePickerApply)="handleSubmission()"
                    (click)="handleSubmission()"
                    class="apply-date-change-button"
                >{{doneLabel}}</button>
            </div>
        </mat-date-range-picker-actions>
    </mat-date-range-picker>
</mat-form-field>
